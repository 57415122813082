import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, Link, useLocation} from 'react-router-dom'
import { Button, Dropdown, Gap, Input} from '../../../components'
import "../../../App.css"
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const MAX_LENGTH = 100;

const InputWhitelistMicrosite = () => {
    const history = useHistory();
    const [SourceIP,setSourceIP] = useState("")
    const [EncryptKey,setEncryptKey] = useState("")
    const [SourceCode,setSourceCode] =useState("")
    // const [StatusAktif,setStatusAktif] = useState("")
    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const [Loading,setLoading] = useState(false)
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
            cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout();
            return false;
        }else{
            setUserID(cookies.CookieUserID)
            setParamKey(cookies.CookieParamKey)
        }
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const simpanData = () => {

        let validasiMessage = "";
        if(SourceIP==""){
            validasiMessage = validasiMessage + "Silahkan isi Source IP terlebih dahulu.\n";
        }
        if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(SourceIP))
        {
            validasiMessage = validasiMessage + "Format IP address tidak valid. \n";
        }
        if(EncryptKey=="" || EncryptKey=="-"){
            validasiMessage = validasiMessage + "Silahkan isi Encrypt Key terlebih dahulu.\n";
        }else if(EncryptKey.length<32){
            validasiMessage = validasiMessage + "Encrypt Key harus lebih dari 32 karakter.\n";
        }
        if(SourceCode==""){
            validasiMessage = validasiMessage + "Silahkan isi Source Code terlebih dahulu.\n";
        }
        
        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{

            
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "Source_IP":SourceIP,
                "EncryptKeyVal":EncryptKey,
                "SourceCode":SourceCode,
                // "IsActive":StatusAktif
            });
    
            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addIPWhiteList';
            // var requestBodyEnc = window.btoa(requestBody);
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
            console.log(""+Signature);
            setLoading(true);
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                 setLoading(false);
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil insert data.");
                    history.push('/list-whitelist-microsite')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout();
                    }else if(data.ErrorMessage=="Error Execute data"){
                        alert("Encrypt Key tidak valid.");
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                alert(AlertMessage.failedConnect)
               console.log("er "+error.message);
            });
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
                <div className="blog-post">
                    {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                    <p className="title"><a href="/list-whitelist-microsite"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Input Ip Whitelist Microsite</p>
                    <hr style={{backgroundColor: 'black'}} />
                    
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Source IP"
                                value={SourceIP} 
                                onChange={event=>{
                                    setSourceIP(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Encrypt Key"
                                value={EncryptKey} 
                                onChange={event=>{
                                    setEncryptKey(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Source Code"
                                value={SourceCode} 
                                onChange={event=>{
                                    setSourceCode(event.target.value)
                                }}
                            />
                        </Card>
                    </CardDeck>
                    
                    {/* <Gap height={20}/>
                    <label>Status Aktif</label><span style={{color:'red'}}> *</span>
                    <div>
                        <input type="radio" value="1" name="status_aktif" 
                            onChange={event=>setStatusAktif(event.target.value)}
                        /> Aktif&nbsp;&nbsp;
                        <input type="radio" value="0" name="status_aktif" 
                            onChange={event=>setStatusAktif(event.target.value)}
                        /> Tidak Aktif
                    </div> */}

                    <Gap height={20}/>
                    
                    <div className="button-action">
                        <Button 
                            spinner={Loading} 
                            onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                    </div>
                </div>

            </div>
        </div>
        
    )
}

export default InputWhitelistMicrosite
