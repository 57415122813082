import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Aktivasi, DataDiri, Login, MainApp, Post,AktivasiSelesai, Voucher, EditVoucher,
    InputPaket,EditPaket, InputTemplateMicrosite, InputTemplateSmsEmail, MasterPaket, 
    TemplateMicrosite, TemplateSmsEmail, EditTemplateSmsEmail, EditTemplateMicrosite, 
    UserLogin,WhitelistMicrosite, InputWhitelistMicrosite, EditWhitelistMicrosite, KoleksiGambar, InputGambar, EditGambar, KategoriGambar, EditKategori, InputKategori  } from '../../pages';
import { useCookies } from 'react-cookie';
import { useSelector,useDispatch } from 'react-redux';
import InputUser from '../../pages/UserLogin/InputUserLogin';
import EditUserLogin from '../../pages/UserLogin/EditUserLogin';



const Routes = () => {
    const [cookies, setCookie] = useCookies(['user']);
    const {form}=useSelector(state=>state.PaketReducer);

    // useEffect(()=>{
    // console.log("logout1 "+cookies.CookieParamKey+" - "+cookies.CookieUserID);
    // console.log("logout0 "+form.ParamKey+" - "+form.UserID);
        
    // },[])
    // console.log("logout0 "+form.ParamKey+" - "+form.UserID);
    // console.log("logout2 "+cookies.CookieParamKey+" - "+cookies.CookieUserID);

    return (
       <Router 
    //    basename={'digital_voucher_test'}
       >
           <Switch>
               <Route  path='/aktivasi'>
                   <Aktivasi/>
               </Route>
               <Route  path='/aktivasi-form'>
                   <DataDiri/>
               </Route>
               <Route  path='/aktivasi-selesai'>
                   <AktivasiSelesai/>
               </Route>
               <Route  path='/voucher'>
                   <Voucher/>
               </Route>
               <Route  path='/edit-voucher'>
                   <EditVoucher/>
               </Route>
               <Route  path='/login'>
                   <Login/>
               </Route>

               {(form.ParamKey==null || form.ParamKey=="" ||
                form.UserID==null || form.UserID=="") && (cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
                cookies.CookieUserID==null || cookies.CookieUserID=="") ?  
                <div>
                    <Route path="/input-WhiteList-microsite">
                        <InputWhitelistMicrosite/>
                    </Route>
                    <Route path="/edit-WhiteList-microsite">
                        <EditWhitelistMicrosite/>
                    </Route>
                    <Route path="/list-WhiteList-microsite">
                        <WhitelistMicrosite/>
                    </Route>
                    <Route path="/input-template-microsite">
                        <InputTemplateMicrosite/>
                    </Route>
                    <Route path="/edit-template-microsite">
                        <EditTemplateMicrosite/>
                    </Route>
                    <Route path="/list-template-microsite">
                        <TemplateMicrosite/>
                    </Route>
                    <Route path="/input-template-sms-email">
                        <InputTemplateSmsEmail/>
                    </Route>
                    <Route path="/edit-template-sms-email">
                        <EditTemplateSmsEmail/>
                    </Route>
                    <Route path="/list-template-sms-email">
                        <TemplateSmsEmail/>
                    </Route>
                    <Route path="/input-paket">
                        <InputPaket/>
                    </Route>
                    <Route path="/edit-paket">
                        <EditPaket/>
                    </Route>
                    <Route path="/list-user-login">
                        <UserLogin/>
                    </Route>
                    <Route path="/input-user-login">
                        <InputUser/>
                    </Route>
                    <Route path="/edit-user-login">
                        <EditUserLogin/>
                    </Route>
                    <Route path="/list-koleksi-gambar">
                        <KoleksiGambar/>
                    </Route>
                    <Route path="/upload-gambar-baru">
                        <InputGambar/>
                    </Route>
                    <Route path="/edit-gambar">
                        <EditGambar/>
                    </Route>
                    <Route path="/list-kategori-gambar">
                        <KategoriGambar/>
                    </Route>
                    <Route path="/input-kategori">
                        <InputKategori/>
                    </Route>
                    <Route path="/edit-kategori-gambar">
                        <EditKategori/>
                    </Route>
                    <Route path="/:post" >
                        <Post/>
                    </Route> 
                    <Route exact path="/">
                        <MasterPaket/>
                    </Route>
                </div>
                : 
                null}
               

               {/* {(form.ParamKey==null || form.ParamKey=="" ||
                form.UserID==null || form.UserID=="") && (cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
                cookies.CookieUserID==null || cookies.CookieUserID=="") ?  
                <div>
                    <Route path="/:post" >
                        <Post/>
                    </Route> 
                </div>
                : 
                null} */}
              
                
               
               <Route  path='/'>
               {(form.ParamKey==null || form.ParamKey=="" ||
                form.UserID==null || form.UserID=="") && (cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
                cookies.CookieUserID==null || cookies.CookieUserID=="") ? <Redirect to="/login" /> : <MainApp/>}

                
                

               </Route>
           </Switch>
       </Router>
    )
}

export default Routes;
