import React from 'react'
import { IconSort } from '../../../assets'

function Sort({onClick}) {
    return (
        <img style={{alignSelf:'center',marginLeft:5}} height={16} src={IconSort}/>
    )
}

export default Sort
