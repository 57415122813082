import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Gap, Header, Input } from '../../../components';
import { AlertMessage, paths } from '../../../utils';
import { getParameterByName } from '../../../utils/functions';
// import './home.scss'
import { useCookies } from 'react-cookie';

var stringToHTML = function (str) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str, 'text/html');
	return doc.body;
};

const AktivasiSelesai = () => {
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['user']);
    const [KodeVoucher,setKodeVoucher] = useState("")

    
    return (
        <div>

            <Header noNavbar/>
        <div className="main-page">
            <div className="content-wrapper-2">
            <div className="blog-post">
                <Gap height={30}></Gap>
                <p className="" style={{textAlign:'center'}}>{cookies.CookieResult}</p>
                <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: cookies.CookieLinkPolis }} />

                <Gap height={30}></Gap>

                <hr 
                    style={{
                        backgroundColor: 'black'
                    }} 
                />
                
            
            </div>
            </div>
        </div>
        </div>
    )
}

export default AktivasiSelesai;
