// import React, { useState } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
// // import { Button, Gap } from '../../atoms';
// import './LeftMenu.scss'
// import {Navigation} from 'react-minimal-side-navigation';
// import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
// import { Navbar, Nav, Container,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
// import { slide as Menu } from 'react-burger-menu'
// import { favicon } from '../../../assets';

// import { setForm } from '../../../redux';
// import { useSelector,useDispatch } from 'react-redux';
// import { useCookies } from 'react-cookie';
// import { Gap } from '../../atoms';

// const LeftMenu = () => {
//     const history = useHistory();
//     const [ActiveItem,SetActiveItem] = useState("")
//     const location = useLocation();
//     console.log("act "+ActiveItem);

//     const [cookies, setCookie,removeCookie] = useCookies(['user']);
//     const {form}=useSelector(state=>state.PaketReducer);
//     const dispatch = useDispatch();
    
//     return (
//         // <div className="">
//         //     <div className="leftMenu">
//         //         <Button title="Paket" onClick={()=>history.push('')}/>
//         //         <Gap height={5}/>
//         //         <Button title="Template Microsite" onClick={()=>history.push('/list-template-microsite')}/>
//         //         <Gap height={5}/>
//         //         <Button title="Template Sms & Email" onClick={()=>history.push('/list-template-sms-email')}/>
//         //     </div>
//         // </div>
//         <>
//         {/* <Navigation
//             // you can use your own router's api to get pathname
//             activeItemId={location.pathname}
//             onSelect={({itemId}) => {
//               // maybe push to the route
//               history.push(itemId)
//               SetActiveItem(itemId)
//             }}
//             items={[
//               {
//                 title: 'Master Paket',
//                 itemId: '/',
//                 elemBefore: () => null,
//                 subNav: [
//                   {
//                     title: 'List Paket',
//                     itemId: '/',
//                   },
//                   {
//                     title: 'Tambah Paket',
//                     itemId: '/input-paket',
//                   },
//                 ],
//               },
//               {
//                 title: 'Master Template Microsite',
//                 itemId: '/list-template-microsite',
//                 subNav: [
//                   {
//                     title: 'List Template Microsite',
//                     itemId: '/list-template-microsite',
//                   },
//                   {
//                     title: 'Tambah Template Microsite',
//                     itemId: '/input-template-microsite',
//                   },
//                 ],
//               },
//               {
//                 title: 'Master Template SMS & Email',
//                 itemId: '/list-template-sms-email',
//                 subNav: [
//                   {
//                     title: 'List Template SMS & Email',
//                     itemId: '/list-template-sms-email',
//                   },
//                   {
//                     title: 'Tambah Template SMS & Email',
//                     itemId: '/input-template-sms-email',
//                   },
//                 ],
//               },
//             ]}
//           /> */}
        
//          <Menu>
//           <div>
//             <img 
//               style={{width:40,height:40,marginRight:10}}
//               src={favicon} alt="logo" /> 
//               <span>
//                     <label>Digital Voucher</label>
//               </span>
//           </div>
//           <Gap height={20}/>
//               <a id="user" className="menu-item" 
//                 style={{backgroundColor:form.PageActive=="User" && '#454858'}} 
//                 href="/list-user-login">List User</a>
//               <a id="whitelist" className="menu-item" 
//                 style={{backgroundColor:form.PageActive=="Whitelist" && '#454858'}} 
//                 href="/list-WhiteList-microsite">IP Whitelist</a>
//               <a id="paket" className="menu-item" 
//                 style={{backgroundColor:form.PageActive=="Paket" && '#454858'}} 
//                 href="/">Paket</a>
//               <a id="microsite" className="menu-item" 
//                 style={{backgroundColor:form.PageActive=="Microsite" && '#454858'}}
//                 href="/list-template-microsite">Template Microsite</a>
//               <a id="smsemail" className="menu-item" 
//                 style={{backgroundColor:form.PageActive=="SMSEMAIL" && '#454858'}}
//                 href="/list-template-sms-email">Template SMS & Email</a>
//               <a id="gambar" className="menu-item"
//                 style={{backgroundColor:form.PageActive=="Gambar" && '#454858'}}
//                 href="/list-koleksi-gambar">Koleksi Gambar</a>
//               <Gap height={20}/>
//               <Button variant="outline-success" onClick={()=>{
//                     removeCookie('CookieUserID', { path: '/' });
//                     removeCookie('CookieParamKey', { path: '/'});
//                     dispatch(setForm("ParamKey",''))
//                     dispatch(setForm("UserID",''))
//                     history.push('/login')
//                 }}>Logout</Button>
//         </Menu>
          

//       </>
//     )
// }

// export default LeftMenu;


import React, { useEffect } from 'react';
// import { useIntl } from 'react-intl';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaUsers, FaNetworkWired, FaBoxes, FaEnvelopeOpenText, FaImages, FaSatelliteDish } from 'react-icons/fa';
import { favicon } from '../../../assets';
import { Link, useHistory } from 'react-router-dom';
// import sidebarBg from './assets/bg1.jpg';
import { setForm } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';
// import { Button } from 'bootstrap';
import { Button, Gap } from '../../atoms';
import { useCookies } from 'react-cookie';
// import { Logout } from '../../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { historyConfig } from '../../../utils/functions';

const LeftMenu = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
//   const intl = useIntl();
const history = useHistory();
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const {form}=useSelector(state=>state.PaketReducer);
    const dispatch = useDispatch()

    

    useEffect(()=>{
      console.log("cookie param : "+cookies.CookieParamKey)
      console.log("cookie userid : "+cookies.CookieUserID)
      console.log("redux param : "+form.ParamKey)
      console.log("redux userid : "+form.UserID)
  },[])

    const logout = ()=>{
      removeCookie('CookieUserID', { path: '/' });
      removeCookie('CookieParamKey', { path: '/'});
      dispatch(setForm("ParamKey",''))
      dispatch(setForm("UserID",''))
      history.push('/login')
    }
  return (
    <ProSidebar
      image={image ? false : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      style={{zIndex:3}}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
           <img 
              style={{width:40,height:40,marginRight:10,borderRadius:4}}
              src={favicon} alt="logo" /> 
          Digital Voucher
        </div>
      </SidebarHeader>
      {((cookies.CookieParamKey!=null && cookies.CookieParamKey!="" &&
                            cookies.CookieUserID!=null && cookies.CookieUserID!="") || (form.ParamKey!=null && form.ParamKey!="" &&
                            form.UserID!=null && form.UserID!=""))
                            && 
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaUsers />}
            // suffix={<span className="badge red">New</span>}
          >

            <a
                 style={{color:form.PageActive=="User" &&'#70e885'}} 
                 href="list-user-login">List User</a>
          </MenuItem>
          <MenuItem
            icon={<FaNetworkWired />}
          >
             <a
                 style={{color:form.PageActive=="Whitelist" &&'#70e885'}} 
                 href="list-WhiteList-microsite"
                 >IP Whitelist</a>
            
          </MenuItem>
          <MenuItem
            icon={<FaBoxes />}
          >
           <a
                 style={{color:form.PageActive=="Paket" &&'#70e885'}} 
                 href=""
                 >Produk</a>
          </MenuItem>
          {/* <MenuItem
            icon={<FaSatelliteDish />}
          >
           <a
                 style={{color:form.PageActive=="Microsite" &&'#70e885'}} 
                 href="/list-template-microsite"
                 >Template Microsite</a>
          </MenuItem> */}
          {/* <MenuItem
            icon={<FaEnvelopeOpenText />}
          >
            <a
                 style={{color:form.PageActive=="SMSEMAIL" &&'#70e885'}} 
                 href="/list-template-sms-email"
                 >Template SMS & Email</a>
          </MenuItem> */}
          <MenuItem
            icon={<FaImages />}
          >
            <a
                 style={{color:form.PageActive=="Gambar" &&'#70e885'}} 
                 href="list-koleksi-gambar"
                // onClick={()=>{
                //   history.push('/list-koleksi-gambar')
                // }}
                 >Koleksi Gambar</a>
          </MenuItem>
          <MenuItem
            icon={<FaImages />}
          >
            <a
                 style={{color:form.PageActive=="KategoriGambar" &&'#70e885'}} 
                 href={"list-kategori-gambar"}
                // onClick={()=>{
                //   history.push('/list-koleksi-gambar')
                // }}
                 >Kategori Gambar</a>
          </MenuItem>
          <MenuItem
            // icon={<FaTachometerAlt />}
          >
            <Button style={{width:100}} variant="outline-success" onClick={()=>{
                    
                    logout();
                }}><FontAwesomeIcon icon={faSignOutAlt}/> Logout</Button>
          </MenuItem>
          
          {/* <MenuItem icon={<FaGem />}> Components</MenuItem> */}
        </Menu>
        <Menu iconShape="circle">
         
        </Menu>
      </SidebarContent>
        }

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
         
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default LeftMenu;