import React from 'react'
import './slug.scss'

function Slug({title,onClick}) {
    return (
            <label className="slug" onClick={onClick}>{title}</label>
    )
}

export default Slug
