import md5 from 'md5';
import React, { useEffect, useState } from 'react'
import { useHistory, Link,useLocation} from 'react-router-dom'
import { Button, Dropdown, Gap, Input } from '../../../components'
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditWhitelistMicrosite = () => {
    const history = useHistory();
    const [IDIPList,setIDIPList] = useState("")
    const [SourceIP,setSourceIP] = useState("")
    const [EncryptKey,setEncryptKey] = useState("")
    const [SourceCode,setSourceCode] =useState("")
    const [StatusAktif,setStatusAktif] = useState("")
    const [SourceIPOld,setSourceIPOld] = useState("")
    const [EncryptKeyOld,setEncryptKeyOld] = useState("")
    const [SourceCodeOld,setSourceCodeOld] =useState("")
    const [StatusAktifOld,setStatusAktifOld] = useState("")

    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const [Loading,setLoading] = useState(false)
    const {form}=useSelector(state=>state.PaketReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const dispatch = useDispatch()

    useEffect(()=>{
        console.log("",form);
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
        cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout();
            return false;
        }else if(form.ParamKey==null || form.ParamKey=="" ||
            form.UserID==null || form.UserID==""){
            history.push('/list-WhiteList-microsite');
            return false;
        }else{
            setUserID(form.UserID)
            setParamKey(form.ParamKey)
            setIDIPList(form.DetailWhitelistMicrosite.IDIPList)
            setSourceIP(form.DetailWhitelistMicrosite.Source_IP)
            setSourceCode(form.DetailWhitelistMicrosite.SourceCode)
            setEncryptKey(form.DetailWhitelistMicrosite.EncryptKeyVal)
            setStatusAktif(form.DetailWhitelistMicrosite.IsActive)
            setSourceIPOld(form.DetailWhitelistMicrosite.Source_IP)
            setSourceCodeOld(form.DetailWhitelistMicrosite.SourceCode)
            setEncryptKeyOld(form.DetailWhitelistMicrosite.EncryptKeyVal)
            setStatusAktifOld(form.DetailWhitelistMicrosite.IsActive)
            
        }
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const simpanData = () => {
        // alert("simpan data "+JSON.stringify(InfoProduk));

        let validasiMessage = "";
        if(SourceIP==""){
            validasiMessage = validasiMessage + "Silahkan isi Source IP terlebih dahulu.\n";
        }
        if(EncryptKey=="" || EncryptKey=="-"){
            validasiMessage = validasiMessage + "Silahkan isi Encrypt Key terlebih dahulu.\n";
        }else if(EncryptKey.length<32){
            validasiMessage = validasiMessage + "Encrypt Key harus lebih dari 31 karakter.\n";
        }
        if(SourceCode==""){
            validasiMessage = validasiMessage + "Silahkan isi Source Code terlebih dahulu.\n";
        }
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{

            var vSourceIP = SourceIP;
            if(SourceIP==SourceIPOld){
                vSourceIP = "-";
            }
            var vEncryptKey = EncryptKey;
            if(EncryptKey==EncryptKeyOld){
                vEncryptKey = "-";
            }
            var vSourceCode = SourceCode;
            if(SourceCode==SourceCodeOld){
                vSourceCode = "-";
            }
            var vStatusAktif = StatusAktif;
            if(StatusAktif==StatusAktifOld){
                vStatusAktif = "-";
            }
           

            var requestBody = JSON.stringify({
            	"UserID": UserID,
            	"ParamKey": ParamKey,
            	"Method":"UPDATE",
                "IDIPList":IDIPList,
                "Source_IP":vSourceIP,
                "EncryptKeyVal":vEncryptKey,
                "SourceCode":vSourceCode,
                "IsActive":vStatusAktif
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addIPWhiteList';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setLoading(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                setLoading(false)
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                // history.push('')
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data.");
                    history.push('/list-whitelist-microsite')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout();
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setLoading(false)
                alert(AlertMessage.failedConnect)
               console.log("er "+error.message);
            });
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
                <div className="blog-post">
                    {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                    <p className="title"><a href="/list-whitelist-microsite"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Whitelist Microsite</p>

                    <hr style={{backgroundColor: 'black'}} />

                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Source IP"
                                value={SourceIP} 
                                onChange={event=>{
                                    setSourceIP(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Encrypt Key"
                                value={EncryptKey} 
                                onChange={event=>{
                                    setEncryptKey(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input required
                                label="Source Code"
                                value={SourceCode} 
                                onChange={event=>{
                                    setSourceCode(event.target.value)
                                }}
                            />
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                    <label>Status Aktif</label><span style={{color:'red'}}> *</span>
                    <div>
                        <input type="radio" value="1" name="status_aktif" checked={StatusAktif=="1"}
                                onChange={event=>setStatusAktif(event.target.value)}
                        /> Aktif &nbsp;&nbsp;
                        <input type="radio" value="0" name="status_aktif" checked={StatusAktif=="0"}
                                onChange={event=>setStatusAktif(event.target.value)}
                        /> Tidak Aktif
                    </div>
                    
                    <Gap height={20}/>
                    
                    <div className="button-action">
                        <Button 
                            spinner={Loading} 
                            onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default EditWhitelistMicrosite
