import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import { InputPaket,EditPaket, InputTemplateMicrosite, InputTemplateSmsEmail, MasterPaket, 
    TemplateMicrosite, TemplateSmsEmail, EditTemplateSmsEmail, EditTemplateMicrosite, Post, Login, 
    UserLogin,WhitelistMicrosite, InputWhitelistMicrosite, EditWhitelistMicrosite, KoleksiGambar, InputGambar, EditGambar } from '..'
import { Footer, Gap, Header, Link } from '../../components'
import LeftMenu from '../../components/molecules/LeftMenu'
import Home from '../Home'
import EditUserLogin from '../UserLogin/EditUserLogin'
import InputUser from '../UserLogin/InputUserLogin'
import './mainApp.scss';
import { useCookies } from 'react-cookie';
import { FaHeart, FaBars } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import KategoriGambar from '../KategoriGambar'
import InputKategori from '../KategoriGambar/InputKategori'
import EditKategori from '../KategoriGambar/EditKategori'
import { historyConfig } from '../../utils/functions'

const MainApp = () => {
    const history = useHistory(historyConfig);

    let match = useRouteMatch();
    const [cookies, setCookie,removeCookie] = useCookies(['user']);

      const [rtl, setRtl] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(true);
    const [toggled, setToggled] = useState(false);

    // const handleCollapsedChange = (checked) => {
    //     setCollapsed(checked);
    // };

    // const handleRtlChange = (checked) => {
    //     setRtl(checked);
    //     setLocale(checked ? 'ar' : 'en');
    // };
    // const handleImageChange = (checked) => {
    //     setImage(checked);
    // };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    const {form}=useSelector(state=>state.PaketReducer);

    useEffect(()=>{
        document.title = "Digital Voucher";
        // if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
        // cookies.CookieUserID==null || cookies.CookieUserID==""){
        //     alert("Session anda telah habis. Silahkan login kembali.");
        //     history.push('/login');
        //     return false;
        // }
     
    },[])

    

    return (
        <div className="main-app-wrapper mainapp"  style={{flexDirection:form.PageActive=="Post" ? 'column':'row'}}>
           
                {/* <Header/> */}
        
            {/* <Gap height={40}/> */}

                {form.PageActive!="Post" &&
                <LeftMenu
                    image={image}
                    collapsed={collapsed}
                    rtl={rtl}
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                />
                }
            <div className="content-wrapper">
                 <div style={{marginLeft:40,marginTop:15}} className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                     <FaBars />
                </div>
                    <Router 
                    // basename={'digital_voucher_test'}
                    >
                        <Switch>
                            <Route exact path="/input-WhiteList-microsite">
                                <InputWhitelistMicrosite/>
                            </Route>
                            <Route exact path="/edit-WhiteList-microsite">
                                <EditWhitelistMicrosite/>
                            </Route>
                            <Route exact path="/list-WhiteList-microsite">
                                <WhitelistMicrosite/>
                            </Route>
                            <Route exact path="/input-template-microsite">
                                <InputTemplateMicrosite/>
                            </Route>
                            <Route exact path="/edit-template-microsite">
                                <EditTemplateMicrosite/>
                            </Route>
                            <Route exact path="/list-template-microsite">
                                <TemplateMicrosite/>
                            </Route>
                            <Route exact path="/input-template-sms-email">
                                <InputTemplateSmsEmail/>
                            </Route>
                            <Route exact path="/edit-template-sms-email">
                                <EditTemplateSmsEmail/>
                            </Route>
                            <Route exact path="/list-template-sms-email">
                                <TemplateSmsEmail/>
                            </Route>
                            <Route exact path="/input-paket">
                                <InputPaket/>
                            </Route>
                            <Route exact path="/edit-paket">
                                <EditPaket/>
                            </Route>
                            <Route exact path="/login">
                                <Login/>
                            </Route>
                            <Route exact path="/list-user-login">
                                <UserLogin/>
                            </Route>
                            <Route exact path="/input-user-login">
                                <InputUser/>
                            </Route>
                            <Route exact path="/edit-user-login">
                                <EditUserLogin/>
                            </Route>
                            <Route exact path="/list-koleksi-gambar">
                                <KoleksiGambar/>
                            </Route>
                            <Route exact path="/upload-gambar-baru">
                                <InputGambar/>
                            </Route>
                            <Route exact path="/edit-gambar">
                                <EditGambar/>
                            </Route>
                            <Route exact path="/list-kategori-gambar">
                                <KategoriGambar/>
                            </Route>
                            <Route exact path="/input-kategori">
                                <InputKategori/>
                            </Route>
                            <Route exact path="/edit-kategori-gambar">
                                <EditKategori/>
                            </Route>
                           
                            <Route path="/:post" >
                                <Post/>
                            </Route>
                            {/* <Route path={`${match.path}/:topicId`}>
                                <Post/>
                            </Route> */}
                            <Route exact path="/">
                                <MasterPaket/>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            
               
               
                
            
            {/* <div className="footer-wrapper"> */}
               {/* <Footer/> */}
            {/* </div> */}
        </div>
    )
}

export default MainApp

// import React, { useState } from 'react';
// import Aside from '../../components/molecules/Aside';
// import LeftMenu from '../../components/molecules/LeftMenu';
// // import Aside from './Aside';
// // import Main from './Main';

// function MainApp({ setLocale }) {
//   const [rtl, setRtl] = useState(false);
//   const [collapsed, setCollapsed] = useState(false);
//   const [image, setImage] = useState(true);
//   const [toggled, setToggled] = useState(false);

//   const handleCollapsedChange = (checked) => {
//     setCollapsed(checked);
//   };

//   const handleRtlChange = (checked) => {
//     setRtl(checked);
//     setLocale(checked ? 'ar' : 'en');
//   };
//   const handleImageChange = (checked) => {
//     setImage(checked);
//   };

//   const handleToggleSidebar = (value) => {
//     setToggled(value);
//   };

//   return (
//     <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`} style={{display:'flex'}}>
//       <Aside
//         image={image}
//         collapsed={collapsed}
//         rtl={rtl}
//         toggled={toggled}
//         handleToggleSidebar={handleToggleSidebar}
//       />
//       {/* <Main
//         image={image}
//         toggled={toggled}
//         collapsed={collapsed}
//         rtl={rtl}
//         handleToggleSidebar={handleToggleSidebar}
//         handleCollapsedChange={handleCollapsedChange}
//         handleRtlChange={handleRtlChange}
//         handleImageChange={handleImageChange}
//       /> */}
//       {/* <LeftMenu/> */}
//       <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
//         <label>bar</label>
//       </div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//       <div>te s asAS</div>
//     </div>
//   );
// }

// export default MainApp;