import React from 'react';
import { Spinner } from 'react-bootstrap';
import './button.scss';

const Button = ({title,spinner,children, ...rest}) => {
    return (
        // <div>
            <button disabled={spinner} className="button" {...rest} >{spinner ? 
            <span>
                <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading ...
            </span>
            :title}{!spinner && children}</button>
        // </div>
    )
}

export default Button;
