import md5 from 'md5';
import React, { useEffect, useState,useRef } from 'react'
import { useHistory, Link,useLocation} from 'react-router-dom'
import { Button, Dropdown, Gap, Input,TextArea, Header } from '../../../components'
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'
import Popup from 'reactjs-popup';
import { Markup } from 'interweave';
import Badge from 'react-bootstrap/Badge'
import { validEmail } from '../../../utils/functions';
import { generateTRXID, getParameterByName } from '../../../utils/functions';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import * as ReactBootStrap from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import SunEditor,{buttonList} from "suneditor-react";
import { Editor } from '@tinymce/tinymce-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../../components/molecules/LabelTH'
import { Tab } from 'bootstrap';
import { getBase64 } from '../../../utils/functions';

const EditVoucher = () => {
    const history = useHistory();
    const [loading,setLoading] = useState(false)
    const [Loading2,setLoading2] = useState(false)
    const [Loading3,setLoading3] = useState(false)
    const [VoucherNo,setVoucherNo] = useState("")
    const [Name,setName] = useState("")
    const [Email,setEmail] =useState("")
    const [NoHandphone,setNoHandphone] = useState("")
    const [TemplateEmail,setTemplateEmail] = useState("")
    const [TemplateSMS,setTemplateSMS] = useState("")
    const [NameOld,setNameOld] = useState("")
    const [EmailOld,setEmailOld] =useState("")
    const [NoHandphoneOld,setNoHandphoneOld] = useState("")
    
    const [ParamKey,setParamKey] = useState("")
    const {form}=useSelector(state=>state.PaketReducer);
    const [Param,setParam] = useState(getParameterByName('param'))
    const [IDVoucher,setIDVoucher] = useState(getParameterByName('voucherid'))

    const [ResultMessage,setResultMessage] = useState("")
    
    const [ListGambar,setListGambar] = useState([])
    const [ListCategoryImage,setListCategoryImage] = useState([])
    const [CategoryImage,setCategoryImage] = useState([])
    

    const [FieldNamaFile,setFieldNamaFile] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(5)
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [LinkImage,setLinkImage] = useState("")
    const [WitdhDiv,setWitdhDiv] = useState(1300)
    
    const [IsLoading2,setIsLoading2] = useState(false)
    
    const [TemplateEmailOld,setTemplateEmailOld] = useState("")
    const [TemplateSMSOld,setTemplateSMSOld] = useState("")
    const [CharLimit,setCharLimit] = useState(160)
    
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };

    const NAME = "{NAME}"
    const VOUCHERNO = "{VOUCHERNO}"
    const PARAM = "{PARAM}"
    const EXPIRED = "{EXPIRED}"
    const URL = "{URL}"
    const LINKAKTIVASI = "{LINKAKTIVASI}"

    //const [Param] = useState("MTYyNDI1MjAxMzQwMDc2Ng==wD0p2whEbyLiXlWhqOpU160xYuuHrB")

    useEffect(()=>{
        if( Param == "" || Param == null || IDVoucher == "" || IDVoucher == null ){
            alert("Url anda tidak valid.");
            return false;
        }else{
            onLoadGambar()
            loadData()
            onLoadGambarCategory()
        }
    },[OrderBy,Order,PageActive])

    const loadData = ()=>{

        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "VoucherNo": IDVoucher,
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_VOUCHER;
        var url = paths.URL_API_EXTERNAL+'viewVoucherDetail';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setResultMessage("Loading ...")

        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                
                console.log("res "+JSON.stringify(data.Result[0]))
                // alert(data.Result[0].ProductName)
                setVoucherNo(IDVoucher)
                setName(data.Result[0].Name)
                setEmail(data.Result[0].Email)
                setNoHandphone(data.Result[0].NoHandphone)
                setNameOld(data.Result[0].Name)
                setEmailOld(data.Result[0].Email)
                setNoHandphoneOld(data.Result[0].NoHandphone)
                setTemplateSMSOld(data.Result[0].TemplateSMS)
                setTemplateEmailOld(data.Result[0].TemplateEmail)
                setTemplateSMS(data.Result[0].TemplateSMS)
                setTemplateEmail(data.Result[0].TemplateEmail)
            }else{
                if(data.ErrorMessage=="Error - ParamKey is not valid"){
                    setResultMessage("Url anda tidak valid.")
                }else{
                    setResultMessage("Data tidak ditemukan.")
                }

            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const onLoadGambar = () => {

        if (FieldNamaFile != "") {
            var NamaFile = "%"+FieldNamaFile+"%";
        } else {
            var NamaFile = "";
        } 

        if (CategoryImage != ""){
            var FilterCategory = CategoryImage
        } else{
            var FilterCategory = ""
        }
        
        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "Filename":NamaFile,
            "IDImage":"",
            "IDImgCategory": FilterCategory,
            "CategoryDesc":""
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_IMAGE;
        var url = paths.URL_API_EXTERNAL+'viewImage';
        // var url = 'http://192.168.108.220/viewImage';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListGambar(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const onLoadGambarCategory = () => {
        
        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Page":1,
            "RowPage":15,
            "OrderBy":"",
            "Order":"DESC",
            "IDImgCategory":"",
            "CategoryDesc":""
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_IMAGE;
        var url = paths.URL_API_EXTERNAL+'viewImageCategory';
        // var url = 'http://192.168.108.220/viewImage';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListCategoryImage(data.Result);
                // setTotalRecord(data.TotalRecords)
                // setTotalPages(data.TotalPages)
                // if(data.TotalRecords<1){
                //     setResultMessage("Data tidak ditemukan")
                // }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const simpanData = () => {

        let validasiMessage = "";

        if(VoucherNo == ""){
            validasiMessage = validasiMessage + "Voucher ID tidak boleh kosong. ";
        }

        if(Name == ""){
            validasiMessage = validasiMessage + "Silahkan isi Nama terlebih dahulu.\n";
        }
        if(Email == ""){
            validasiMessage = validasiMessage + "Silahkan isi Email terlebih dahulu.\n";
        }else if(!validEmail(Email)){
            validasiMessage = validasiMessage + "Pastikan email anda valid.\n";
        }
        if(NoHandphone == ""){
            validasiMessage = validasiMessage + "Silahkan isi No. Handphone terlebih dahulu.\n";
        }else if(NoHandphone.length < 10 || NoHandphone.length > 14){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. ";
        }else if(isNaN(NoHandphone)){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. Tidak mengandung huruf. ";
        }else if(NoHandphone.indexOf("+") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }else if(NoHandphone.indexOf("E") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }
        if(TemplateSMS == ""){
            validasiMessage = validasiMessage + "Silahkan isi Template SMS terlebih dahulu.\n";
        }
        
        if (TemplateSMS.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {NAME} silakan lihat panduan konten\n";
        }
        
        if (TemplateSMS.indexOf("{EXPIRED}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {EXPIRED} silakan lihat panduan konten\n";
        }
        if (TemplateSMS.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }
        
        if(TemplateEmail == "")
        {
            validasiMessage = validasiMessage + "Silahkan isi Template Email terlebih dahulu.\n";
        }
        
        if (TemplateEmail.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {NAME} silakan lihat panduan konten\n";
        }
        
        if (TemplateEmail.indexOf("{EXPIRED}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {EXPIRED} silakan lihat panduan konten\n";
        }
        if (TemplateEmail.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }
        if (TemplateEmail.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {LINKAKTIVASI} silakan lihat panduan konten\n";
        }

        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{

            // var vName = Name;
            // if(Name==NameOld){
            //     vName = "-";
            // }
            // var vEmail = Email;
            // if(Email==EmailOld){
            //     vEmail = "-";
            // }
            // var vNoHandphone = NoHandphone;
            // if(NoHandphone==NoHandphoneOld){
            //     vNoHandphone = "-";
            // }
            // var vTemplateEmail = TemplateEmail;
            // if(TemplateEmail==TemplateEmailOld){
            //     vTemplateEmail = "-";
            // }
            // var vTemplateSMS = TemplateSMS;
            // if(TemplateSMS==TemplateSMSOld){
            //     vTemplateSMS = "-";
            // }

            setLoading(true)
            var requestBody = JSON.stringify({
                "VoucherNo": VoucherNo,
                "Name": Name,
                "Email": Email,
                "NoHandphone": NoHandphone,
                "TemplateEmail": TemplateEmail,
                "TemplateSMS": TemplateSMS
            });
            console.log(requestBody);
            
            var enckey = paths.ENCKEY_UPDATE_VOUCHER;
            var url = paths.URL_API+'updateVoucher';
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);

            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                // history.push('')
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data.");
                    history.push('/voucher?param=' + Param)
                }else{
                    var texterror = "";
                    if(data.ErrorMessage=="Error Execute data"){
                        texterror = "Error, ID voucher tidak valid. Download ulang data excel lalu diisi dan diupload.";
                    }else if(data.ErrorMessage=="Error - Update data"){
                        texterror = "Error, Data sudah pernah dilengkapi sebelumnya";
                    }else if(data.ErrorMessage=="Error, Data Already Process"){
                        texterror = "Error, Data sudah pernah diproses";
                    }else if(data.ErrorMessage=="; NoHandphone must be numeric or Length must be more than 5 characters"){
                        texterror = "Error, No handphone harus lebih dari 5 digit";
                    }else{
                        texterror = data.ErrorMessage;
                    }
                    alert(texterror);
                }
                setLoading(false)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(false)
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const handleSort=(OrderBy)=>{
        setOrderBy(OrderBy);
       if(Order.toUpperCase()=="ASC"){
            setOrder("DESC");
       }else{
           setOrder("ASC");
       }
       // alert(OrderBy)
       // loadData();
    }

    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    const ReventToOriginal=(TemplateEmail, TemplateSMS)=>{
        setTemplateEmail(TemplateEmail)
        setTemplateSMS(TemplateSMS)
    }

    const MobileVersion=()=>{
        setWitdhDiv(350);
    }

    const WebsiteVersion=()=>{
        setWitdhDiv(1300);
    }

    const PilihImage=(Filename)=>{
        if(LinkImage == ""){
            setTemplateEmail(TemplateEmail+'<img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
        }else{
            setTemplateEmail(TemplateEmail+'<a href="{LINKAKTIVASI}"><img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
        }
        
        // alert('Image telah dipilih, silakan tutup popup jika sudah selesai');
        // close()
    }

    const contentStyle = { background: '#ffffff',borderRadius:15,padding:20, width:'100%'};
    const contentStyle2 = { background: '#ffffff',borderRadius:15,padding:20};
    const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
    const arrowStyle = { color: '#000' };

    return (
        <div>
            <Header noNavbar/>
            <div className="main-page">
                <div className="content-wrapper-2">
                    <div className="blog-post">
                        {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                        {/* <p className="title"><a href="/list-template-microsite"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Voucher</p> */}
                        <p className="title"><a href={"/voucher?param="+Param}><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Voucher</p>
                        <hr style={{backgroundColor: 'black'}} />

                        <CardDeck>
                            <Card style={{border:'none'}}>
                                <label>Voucher No</label>
                                <label>{VoucherNo}</label>
                            </Card>
                            <Card style={{border:'none'}}>
                                <Input 
                                    required
                                    label="Name"
                                    value={Name} 
                                    onChange={event=>{
                                        setName(event.target.value)
                                    }}
                                />
                            </Card>
                        </CardDeck>
                        <CardDeck>
                            <Card style={{border:'none'}}>
                                <Input 
                                    required
                                    label="Email"
                                    value={Email} 
                                    onChange={event=>{
                                        setEmail(event.target.value)
                                    }}
                                />
                            </Card>
                            <Card style={{border:'none'}}>
                                <Input 
                                    required
                                    type="number"
                                    label="No Handphone"
                                    value={NoHandphone} 
                                    onChange={event=>{
                                        setNoHandphone(event.target.value)
                                    }}
                                />
                            </Card>
                        </CardDeck>

                        <Gap height={20}/>

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle eventKey="1" style={{backgroundColor:'#1B4F72',height:35,borderRadius:5,color:'#ffffff'}}>Panduan Mengisi Konten</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>

                                <p>Dear, {NAME}</p>
                                Silahkan aktivasi,
                                <table>
                                    <tr>
                                        <td>Voucher No</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>{VOUCHERNO}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Param</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>{PARAM}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Expired</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>{EXPIRED} hari</td>
                                    </tr>
                                    {/* <tr>
                                        <td>URL</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>{URL}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Link Aktivasi</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>{LINKAKTIVASI}</td>
                                    </tr>
                                </table>
                                <br/>
                                {/* dengan klik tombol dibawah ini

                                <div class='se-component se-image-container __se__float-none' contenteditable='false'>
                                    <a href='https://sinarmas.co.id'>
                                        <img src="http://182.23.21.61/api/v1/images"/>
                                    </a>
                                </div> */}

                                <p>Silahkan klik link dibawah ini untuk aktivasi</p>

                                <a href="http://yourdomain.com/mylink.pl">YourDomain.com</a>

                                <br/>
                                <br/>
                                <p>Terima kasih</p>

                                <hr/>

                                <table>
                                    <tr>
                                        <td>{NAME}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan Nama User</td>
                                    </tr>
                                    <tr>
                                        <td>{VOUCHERNO}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan No Voucher</td>
                                    </tr>
                                    {/* <tr>
                                        <td>{PARAM}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan Param Aktivasi</td>
                                    </tr> */}
                                    <tr>
                                        <td>{EXPIRED}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan Expired Voucher (dalam hari)</td>
                                    </tr>
                                    {/* <tr>
                                        <td>{URL}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan URL</td>
                                    </tr> */}
                                    <tr>
                                        <td>{LINKAKTIVASI}</td>
                                        <td>&nbsp;</td>
                                        <td>:</td>
                                        <td>&nbsp;</td>
                                        <td>Akan diisi otomatis dengan Link Aktivasi</td>
                                    </tr>
                                </table>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Gap height={20}/>

                            <label>Template SMS</label>
                            <TextArea
                                value={TemplateSMS}
                                maxLength={160}
                                onChange={event=>setTemplateSMS(event.target.value)}
                            />
                            Sisa Karakter SMS : {CharLimit - TemplateSMS.length}


                        <Gap height={20}/>
                        <label>Template Email</label>
                        <div className="editordiv">
                            <div style={{display:"flex"}}>
                                <div style={{alignContent:"left", paddingRight:10}}>
                                    <Popup
                                        trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Pilih Gambar </button>}
                                        {...{ contentStyle, overlayStyle, arrowStyle}}
                                        modal
                                        nested
                                        lockScroll = {true}
                                        offsetY = {0}
                                    >
                                        {close => (
                                        <div>
                                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                                <div align="left">
                                                    Pilih Gambar
                                                </div>
                                                <div align="right">
                                                    <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div style={{overflow: 'auto', height:550}}>
                                            <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                                <Tab eventKey="home" title="Library">
                                                    <Row xs={12} md={12} lg={8}>
                                                        <Col>
                                                            <Input
                                                                label="Filter Nama File"
                                                                style={{width:300}}
                                                                onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                            />
                                                        </Col>
                                                        <Col>
                                                        <Dropdown 
                                                            spinner={Loading2} 
                                                            style={{width:300}}
                                                            label="Kategori" 
                                                            onChange={(event)=>{
                                                                setCategoryImage(event.target.value)
                                                            }}>
                                                            <option value="">-- Pilih Category --</option>
                                                            {ListCategoryImage.length > 0 && ListCategoryImage.map((item,index)=>{
                                                                return <option value={item.IDImgCategory} selected={item.IDImgCategory==CategoryImage}>{item.CategoryDesc}</option>;
                                                            })}
                                                        </Dropdown>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                    <Row xs={12} md={12} lg={8}>
                                                        <Col>
                                                            <Dropdown label = "Sorting"
                                                                style={{width:300}}
                                                                onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                                    <option value= "Filename" selected={"Filename"==OrderBy}>Nama File</option>
                                                                    <option value= "InputDate" selected={"InputDate"==OrderBy}>Input Date</option>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col>
                                                            <Dropdown label = "Sorting Type"
                                                                style={{width:300}}
                                                                onChange = {(event) => {setOrder(event.target.value)}}>
                                                                    <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                                    <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                    <br/>
                                                    <div>
                                                        <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                                    </div>
                                                
                                                    <Gap height={10}/>
                                                    <label><span style={{color:'red'}}>*</span> Klik pada gambar untuk memilih</label>
                                                    <Gap height={10}/>

                                                    {!loading ? (
                                                    <div> 
                                                    <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                                        <Thead>
                                                        <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                            <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                            <Th className="tabelHeader" onClick={()=>handleSort("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                            <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                            <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                                        </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                        { ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                                return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                                    <Td className='tableWidth250' style={{alignContent:'center'}}> 
                                                                        <Popup
                                                                            trigger={<img src={"data:image/png;base64,"+item.DataBase64Small} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}} />}
                                                                            {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                            modal
                                                                            nested
                                                                        >
                                                                            {close => (
                                                                            <div>
                                                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                                                    <div align="left">
                                                                                        Tampilan Gambar
                                                                                    </div>
                                                                                    <div align="right">
                                                                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <hr/>
                                                                                <div style={{overflow: 'auto', height:550}}>
                                                                                <img src={paths.UrlImages+item.Filename} style={{width:'100%'}}/>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                        </Popup>
                                                                    </Td>
                                                                    <Td>{item.Filename}</Td>
                                                                    <Td>{item.InputDate}</Td>
                                                                    <Td style={{cursor:'pointer'}} align="left">
                                                                        <div className="topping">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="topping"
                                                                                name="topping"
                                                                                value="Paneer"
                                                                                onChange = {(event) => {setLinkImage()}}
                                                                                style={{paddingTop:5}}
                                                                                />
                                                                            <span style={{ marginLeft: 5, paddingTop:-6}}>Tambah Link Microsite</span>
                                                                        </div>
                                                                        <Gap height={15}/>
                                                                        <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                            PilihImage(item.Filename)
                                                                            close();
                                                                        }
                                                                        }>Pilih</Button>
                                                                    </Td>
                                                                </Tr>;
                                                        }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                                    
                                                        </Tbody>
                                                    </Table>
                                                    <Gap height={10}/>
                                                    {TotalRecord > 0 &&
                                                    <div style={{display:'flex'}}>
                                                        <span>Page : &nbsp;</span>
                                                        <Pagination size="sm">{items}</Pagination>
                                                    </div>
                                                    }
                                                    <Gap height={10}/>
                                                    </div>
                                                    ) : (
                                                    <div class="divLoader">
                                                        <ReactBootStrap.Spinner animation="border" />
                                                    </div>)}
                                                </Tab>
                                                </ReactBootStrap.Tabs>
                                            
                                            </div>
                                        </div>
                                        )}
                                    </Popup>
                                </div>
                                <div style={{alignContent:'right'}}>
                                    <Popup
                                        trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Tampilkan Email </button>}
                                        {...{ contentStyle, overlayStyle, arrowStyle }}
                                        modal
                                        nested
                                    >
                                        {close => (
                                        <div>
                                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                                <div align="left">
                                                    Tampilan Email
                                                    <Gap height={10}/>
                                                    <div style={{display:'flex'}}>
                                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>WebsiteVersion()}>Versi Website</button>
                                                        <Gap width={10} />
                                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>MobileVersion()}>Versi Mobile</button>
                                                    </div>
                                                </div>
                                                <div align="right">
                                                    <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div style={{overflow: 'auto', height:550, width:WitdhDiv}}>
                                                <Markup content= {TemplateEmail.replace("{NAME}", Name).replace('{URL}','http://localhost:3000/Asuransi-PA').replace('{PARAM}',Param).replace('{VOUCHERNO}',IDVoucher).replace('{EXPIRED}','30')} />
                                            </div>
                                        </div>
                                        )}
                                    </Popup>
                                    {/* <div style={{paddingRight:20}}></div>
                                    <Popup
                                        trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:10,color:'#ffffff'}}> Send Test Email </button>}
                                        {...{ contentStyle, overlayStyle, arrowStyle }}
                                        modal
                                        nested
                                    >
                                        {close => (
                                        <div>
                                            <button style={{backgroundColor:'#FF3131',color:'#ffffff',borderRadius:5}} onClick={close}>
                                            &times;
                                            </button>
                                            <hr/>
                                            <div>
                                                <Input 
                                                    required
                                                    label="Nama"
                                                    onChange={event=>setFieldNama(event.target.value)}
                                                />
                                                <Input 
                                                required
                                                    label="Alamat Email"
                                                    onChange={event=>setFieldEmail(event.target.value)}
                                                />
                                                <Input 
                                                required
                                                    label="Nomor Handphone"
                                                    type="number"
                                                    onChange={event=>setFieldSMS(event.target.value)}
                                                />
                                                <Input 
                                                    label="Pesan Text"
                                                    onChange={event=>setFieldText(event.target.value)}
                                                />
                                            </div>
                                            <Gap height={10}/>
                                            <div>
                                                <Button spinner={loading} title="Send" onClick={()=>sendEmailSMS("email")}/>
                                            </div>
                                        </div>
                                        )}
                                    </Popup> */}
                                </div>
                            </div>
                            <Editor
                                // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                                // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                                onInit={(evt, editor) => editorRef.current = editor}
                                // initialValue="<p>This is the initial content of the editor.</p>"
                                onSetContent={()=>{
                                    console.log("onSetContent")
                                }}
                                onEditorChange={(newText)=>{
                                    console.log("onEditor")
                                    setTemplateEmail(newText)
                                }}
                                onChange={()=>{
                                    console.log("onChange")
                                }}
                                value={TemplateEmail}
                                init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                // plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                                toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                        <Gap height={10}/>
                        
                        <Gap height={20}/>
                        <Button style={{width: '100%', maxWidth:200, height:40, fontSize:11}} title="Kembali ke Template awal" onClick={()=>ReventToOriginal(TemplateEmailOld, TemplateSMSOld)}/>
                        
                        <Gap height={20}/>
                        <div className="button-action">
                            <Button 
                                spinner={loading} 
                                onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditVoucher
