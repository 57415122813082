import md5 from 'md5';
import React, { useEffect, useState,useRef } from 'react'
import { useHistory, Link,useLocation} from 'react-router-dom'
import { Button, Dropdown, Gap, Input,TextArea } from '../../../components'
// import { Editor } from "react-draft-wysiwyg";
import { Editor } from '@tinymce/tinymce-react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import SunEditor,{buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import * as ReactBootStrap from 'react-bootstrap'
import { getBase64 } from '../../../utils/functions';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Popup from 'reactjs-popup';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../../components/molecules/LabelTH'
import { Tab } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditTemplateMicrosite = () => {
    const history = useHistory();
    const [loading,setLoading] = useState(false)
    const [Loading2,setLoading2] = useState(false)
    const [Loading3,setLoading3] = useState(false)
    const [NamaTemplate,setNamaTemplate] = useState("")
    const [NamaSource,setNamaSource] = useState("")
    const [NamaPaket,setNamaPaket] =useState("")
    const [InfoProduk,setInfoProduk] = useState("")
    const [SlugNamaTemplate,setSlugNamaTemplate] = useState("")
    const [SlugNamaTemplateOld,setSlugNamaTemplateOld] = useState("")
    const [InfoProdukContent,setInfoProdukContent] = useState("")
    const [InfoProdukContentOld,setInfoProdukContentOld] = useState("")
    const [DefaultContent,setDefaultContent] = useState("")
    const [NamaSourceOld,setNamaSourceOld] = useState("")
    const [NamaPaketOld,setNamaPaketOld] =useState("")
    const [JudulInfoProduk,setJudulInfoProduk] = useState("")
    const [UserID,setUserID] = useState("")
    const [IDWeb,setIDWeb] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const [StatusAktif,setStatusAktif] = useState("")
    // const location = useLocation();
    const [ListSource,setListSource] = useState([])
    const [ListPaket,setListPaket] = useState([])
    const {form}=useSelector(state=>state.PaketReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [MetaTitle,setMetaTitle] = useState("")
    const [MetaDesc,setMetaDesc] = useState("")
    const [MetaKeyword,setMetaKeyword] = useState("")
    const [Image,setImage] = useState(null)
    const [PageNumber,setPageNumber] = useState(1)
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(5)
    const [ResultMessage,setResultMessage] = useState("")
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [SearchGambar,setSearchGambar] = useState("")
    const [FileName,setFileName] = useState("")
    const [ListGambar,setListGambar] = useState([])
    const [FileNameUnggah,setFileNameUnggah] = useState("")
    const [GambarUnggah,setGambarUnggah] = useState(null)
    const [IsLoading2,setIsLoading2] = useState(false)
    const [FieldNamaFile,setFieldNamaFile] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [LinkImage,setLinkImage] = useState("")
    const [UrlLink,setUrlLink] = useState("")
    const [TextDisplay,setTextDisplay] = useState("")
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };
    const dispatch = useDispatch();

    useEffect(()=>{
        console.log("",form);
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
        cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else if(form.ParamKey==null || form.ParamKey=="" ||
            form.UserID==null || form.UserID==""){
            history.push('/list-template-microsite');
            return false;
        }else{
            setUserID(form.UserID)
            setParamKey(form.ParamKey)
            setNamaPaket(form.DetailMicrosite.ProductName)
            setNamaPaketOld(form.DetailMicrosite.ProductName)
            setNamaTemplate(form.DetailMicrosite.Title)
            setNamaSource(form.DetailMicrosite.SourceCode)
            setNamaSourceOld(form.DetailMicrosite.SourceCode)
            setJudulInfoProduk(SlugToTitle(form.DetailMicrosite.Title))
            setInfoProdukContent(form.DetailMicrosite.TemplateWeb)
            setInfoProdukContentOld(form.DetailMicrosite.TemplateWeb)
            setDefaultContent(form.DetailMicrosite.TemplateWeb)
            handleSlug(form.DetailMicrosite.Title)
            setSlugNamaTemplateOld(form.DetailMicrosite.URL)
            setIDWeb(form.DetailMicrosite.IDWeb)
            // onLoadSource(form.DetailMicrosite.SourceCode)
            setStatusAktif(form.DetailMicrosite.IsActive)
            // alert(form.DetailMicrosite.URL)
            
            onLoad()
            onLoadSource()
            onLoadGambar()
        }
    },[OrderBy,Order,PageActive])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const onLoad=()=>{
        setLoading2(true)
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Category":"SourceCode",
	        "Page":1,
	        "RowPage":15,
	        "OrderBy":"",
	        "Order":"DESC",
	        "ProductName":"",
	        "SourceCode":""
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'sourceCodeProduct';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);

        console.log(""+Signature);
    
        fetch( url, {
            method: "POST",
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            setLoading2(false)
            if(data.ErrorCode=="0"){
                setListSource(data.Result);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
        })
        .catch((error) => {
            setLoading2(false)
           console.log("er "+error.message);
           alert(AlertMessage.failedConnect);
        });
    }

    const onLoadSource=(NamaSourceOld)=>{
        setLoading3(true)
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method": "SELECT",
            "Page": 1,
            "RowPage": 15,
            "OrderBy": "",
            "Order": "DESC",
            "ProductName": "",
            "SourceCode": NamaSource,
            "URL_API01":"",
            "URL_API02": "",
            "IsActive": "1"
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'product';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
    
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
            if(data.ErrorCode=="0"){
                setListPaket(data.Result);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading3(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading3(false)
           alert(AlertMessage.failedConnect);
        });
    }

    const onLoadGambar = () => {

        if (FieldNamaFile != "") {
            var NamaFile = "%"+FieldNamaFile+"%";
        } else {
            var NamaFile = "";
        } 

        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "1";
        }
        
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method":"SELECT",
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "Filename":NamaFile,
            // "DataBase64":"-",
            // "IDImage":"-",
            "IsActive": FilterStatus
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImages';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListGambar(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const simpanData = () => {
        // alert("simpan data "+JSON.stringify(InfoProduk));

        let validasiMessage = "";
        if(NamaTemplate==""){
            validasiMessage = validasiMessage + "Silahkan isi nama template terlebih dahulu.\n";
        }
        if(NamaTemplate.length > 100){
            validasiMessage = validasiMessage + "Nama Template tidak boleh lebih dari 100 karakter.\n";
        }
        if(NamaSource==""){
            validasiMessage = validasiMessage + "Silahkan isi nama source terlebih dahulu.\n";
        }
        if(NamaPaket==""){
            validasiMessage = validasiMessage + "Silahkan isi nama paket terlebih dahulu.\n";
        }
        if(InfoProdukContent==""){
            validasiMessage = validasiMessage + "Silahkan isi info produk terlebih dahulu.\n";
        }
        if(StatusAktif==""){
            validasiMessage = validasiMessage + "Silahkan pilih status aktif terlebih dahulu.\n";
        }
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            setLoading(true)
            var requestBody = JSON.stringify({
            	"UserID": UserID,
            	"ParamKey": ParamKey,
            	"Method":"UPDATE",
            	"ProductNameOld":NamaPaketOld,
            	"ProductName":NamaPaket,
            	"SourceCodeOld":NamaSourceOld,
            	"SourceCode":NamaSource,
                "Title":NamaTemplate,
                "URL": window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate,
                "TemplateWeb":InfoProdukContent,
            	"IsActive":StatusAktif,
                "IDWeb":IDWeb
            });
            // alert(form.IDWeb)
            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'templateWeb';
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                // history.push('')
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data.");
                    history.push('/list-template-microsite')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
                setLoading(false)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(false)
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const unggahGambar = () => {
        //  alert("simpan data "+window.location.host);
        //  return false;

        let validasiMessage = "";
        if(GambarUnggah==null){
            validasiMessage = validasiMessage + "Silahkan upload gambar terlebih dahulu.\n";
        }
        if(FileNameUnggah==""){
            validasiMessage = validasiMessage + "Silahkan isi nama file terlebih dahulu.\n";
        }else if(FileNameUnggah.length > 100){
            validasiMessage = validasiMessage + "Nama file maksimal 100 karakter.\n";
        }
        
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "Filename":FileNameUnggah,
                "DataBase64":GambarUnggah,
                // "IsActive":StatusAktif
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImages';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil unggah gambar.");
                    onLoadGambar();
                    setGambarUnggah(null)
                    setFileNameUnggah("")
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const handleSlug = (value) =>{
        var slug = value.replace(/ /gi, "-");
        setSlugNamaTemplate(slug.toLowerCase())
    }

    const SlugToTitle = (value) =>{
        var slug = value.replace(/-/gi, " ");
        return (slug.toLowerCase())
    }

    console.log(SlugNamaTemplate+" == "+ SlugNamaTemplateOld)

    const handleSort=(OrderBy)=>{
        setOrderBy(OrderBy);
       if(Order.toUpperCase()=="ASC"){
            setOrder("DESC");
       }else{
           setOrder("ASC");
       }
       // alert(OrderBy)
       // loadData();
    }

    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    const PilihImage=(Filename)=>{
        if(LinkImage == ""){
            setInfoProdukContent(InfoProdukContent+'<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
        }else{
            setInfoProdukContent(InfoProdukContent+'<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
        }
        
        // alert('Image telah dipilih, silakan tutup popup jika sudah selesai');
        // close()
    }

    const insertLink=(Link,Display)=>{
        if(Link != ""){
            if(Display==""){
                Display = Link;
            }
            setFileName('<a href="'+Link+'" alt="'+Link+'" class="on">'+Display+'</a>')
        }
        
    }

    const contentStyle = { background: '#ffffff',borderRadius:15,padding:20, width:'100%'};
    const contentStyle2 = { background: '#ffffff',borderRadius:15,padding:20};
    const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
    const arrowStyle = { color: '#000' };

    return (
        <div className="main-page">
          
            <div className="content-wrapper-2">
                <div className="blog-post">
                    {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                    <p className="title"><a href="/list-template-microsite"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Template Microsite</p>

                    <hr style={{backgroundColor: 'black'}} />

                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Dropdown spinner={Loading2} required label="Nama Source" onChange={(event)=>{
                                setNamaSource(event.target.value)
                                setNamaPaket("")
                                onLoadSource(event.target.value)
                            }}>
                                <option value="">-- Pilih --</option>
                                {ListSource.length > 0 && ListSource.map((item,index)=>{
                                    return <option value={item.SourceCode} selected={item.SourceCode==NamaSource}>{item.SourceCode}</option>;
                                })}
                            </Dropdown>
                        </Card>
                        <Card style={{border:'none'}}>
                            <Dropdown spinner={Loading3} required label="Nama Paket" 
                                value={NamaPaket} 
                                onChange={(event)=>{ setNamaPaket(event.target.value)}}
                            >
                                <option value="">-- Pilih --</option>
                                {ListPaket.length > 0 && ListPaket.map((items,index)=>{
                                    return <option value={items.ProductName} selected={items.ProductName==NamaPaket}>{items.ProductName}</option>;
                                })}
                            </Dropdown>
                        </Card>
                    </CardDeck>
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                required
                                label="Nama Template"
                                value={NamaTemplate} 
                                onChange={event=>{
                                    setNamaTemplate(event.target.value)
                                    handleSlug(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none',paddingTop:30}}>
                            {SlugNamaTemplate!="" &&  
                                <div>
                                    <Gap height={5}/>
                                    <label>URL: </label>
                                    {/* <Slug title={window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate} onClick={()=>{
                                        setCookie('CookiePostTitle', Email, { path: '/' });
                                        setCookie('CookiePostContent', Password, { path: '/' });
                                    }}/><br/> */}
                                    {window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate == SlugNamaTemplateOld && InfoProdukContent == InfoProdukContentOld? 
                                    <Link 
                                        target='_blank' 
                                        to={{
                                        pathname:`/${SlugNamaTemplate}`,
                                        state:{
                                            title:NamaTemplate,
                                            postContent:InfoProdukContent
                                            // postContent:draftToHtml(convertToRaw(InfoProduk.getCurrentContent()))
                                        }
                                    }}
                                    // onClick={()=>{
                                    //     try{
                                    //         setCookie('CookiePostTitle', NamaTemplate, { path: '/' });
                                    //         setCookie('CookiePostContent', InfoProdukContent, { path: '/' });

                                    //         console.log(cookies.CookiePostTitle)
                                    //         console.log(cookies.CookiePostContent)
                                    //     }catch(e){
                                    //         console.log("err",e.message)
                                    //     }
                                        
                                    //     window.open(window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate)
                                    // }
                                    // }
                                    >
                                    {window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate}
                                    </Link>
                                    :
                                    <label style={{color:'#a5a3a3',textDecoration:'underline',cursor: 'no-drop'}}>
                                    {window.location.protocol+"//"+window.location.host+"/"+SlugNamaTemplate}
                                    </label>
                                    }
                                    <Gap height={5}/>
                                </div>
                            }
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                    <label>Status Aktif</label><span style={{color:'red'}}> *</span>
                    <div>
                        <input type="radio" value="1" name="status_aktif" checked={StatusAktif=="1"}
                                onChange={event=>setStatusAktif(event.target.value)}
                        /> Aktif
                         &nbsp;
                         &nbsp;
                        <input type="radio" value="0" name="status_aktif" checked={StatusAktif=="0"}
                                onChange={event=>setStatusAktif(event.target.value)}
                        /> Tidak Aktif
                    </div>
                    
                    <Gap height={20}/>

                    <label>Info Produk</label>
                    <div className="editordiv">
                        <Popup
                            trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:5,color:'#ffffff'}}> Pilih Gambar </button>}
                            {...{ contentStyle, overlayStyle, arrowStyle}}
                            modal
                            nested
                            lockScroll = {true}
                            offsetY = {0}
                        >
                            {close => (
                            <div>
                                <div align="right">
                                    <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                </div>
                                <hr/>
                                <div style={{overflow: 'auto', height:550}}>
                                <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                    <Tab eventKey="home" title="Library">
                                        <Row xs={12} md={12} lg={8}>
                                            <Col>
                                                <Input
                                                    label="Filter Nama File"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                />
                                            </Col>
                                            <Col>
                                                <Dropdown label = "Filter Status Aktif"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setFieldFilterStatus(event.target.value)}}>
                                                        <option value= "1" selected>Aktif</option>
                                                        <option value= "0">Tidak Aktif</option>
                                                </Dropdown>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        <Row xs={12} md={12} lg={8}>
                                            <Col>
                                                <Dropdown label = "Sorting"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                        <option value= "Filename" selected={"Filename"==OrderBy}>Nama File</option>
                                                        <option value= "InputDate" selected={"InputDate"==OrderBy}>Input Date</option>
                                                </Dropdown>
                                            </Col>
                                            <Col>
                                                <Dropdown label = "Sorting Type"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setOrder(event.target.value)}}>
                                                        <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                        <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                </Dropdown>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <div>
                                            <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                        </div>
                                    
                                    <Gap height={10}/>
                                        <label><span style={{color:'red'}}>*</span> Klik pada gambar untuk memilih</label>
                                        <Gap height={10}/>

                                        {!loading ? (
                                        <div> 
                                        <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                            <Thead>
                                            <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                <Th className="tabelHeader" onClick={()=>handleSort("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                            </Tr>
                                            </Thead>
                                            <Tbody>
                                            { ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                    return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                        <Td> 
                                                            <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                                <img src={"data:image/png;base64,"+item.DataBase64} style={{width:'100%', maxWidth:200}}/>
                                                            </div>
                                                        </Td>
                                                        <Td>{item.Filename}</Td>
                                                        <Td>{item.InputDate}</Td>
                                                        <Td style={{cursor:'pointer'}} align="left">
                                                            <Input
                                                                placeholder = "Link image"
                                                                style={{width:200}}
                                                                onChange = {(event) => {setLinkImage(event.target.value)}}
                                                            />
                                                            <Gap height={15}/>
                                                            <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                PilihImage(item.Filename)
                                                                close();
                                                            }
                                                            }>Pilih</Button>
                                                        </Td>
                                                    </Tr>;
                                            }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                        
                                            </Tbody>
                                        </Table>
                                        <Gap height={10}/>
                                        {TotalRecord > 0 &&
                                        <div style={{display:'flex'}}>
                                            <span>Page : &nbsp;</span>
                                            <Pagination size="sm">{items}</Pagination>
                                        </div>
                                        }
                                        <Gap height={10}/>
                                        </div>
                                        ) : (
                                        <div class="divLoader">
                                            <ReactBootStrap.Spinner animation="border" />
                                        </div>)}
                                    </Tab>
                                    <Tab eventKey="unggah" title="Unggah">
                                        <Container>
                                            <Gap height={10}/>
                                            {GambarUnggah != null &&
                                            <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                <img src={"data:image/png;base64,"+GambarUnggah} style={{width:'100%'}}/>
                                            </div>
                                            }
                                            <Input 
                                                label="Unggah Gambar"
                                                type="file"
                                                accept=".jpeg, .png, .jpg"
                                                onChange={event=>{
                                                    if (event.target.files && event.target.files[0]) {
                                                        let img = event.target.files[0];
                                                        // console.log("img ",img)
                                                        if(img.size <= 200000){ //200kb
                                                            var filename = (img.name).replace(/ /gi, "-");
                                                            // console.log("",(img.name).replace(/ /gi, "-"))
                                                            getBase64(img, (result) => {
                                                                console.log("",result);
                                                                setFileNameUnggah(filename)
                                                                setGambarUnggah(result)
                                                            });
                                                        }else{
                                                            setGambarUnggah(null)
                                                            setFileNameUnggah("")
                                                            alert("Maaf, Ukuran gambar terlalu besar. Maksimal 200 kb");
                                                        }
                                                    }
                                                }}
                                            />
                                            <Gap height={20}/>
                                            <div className="button-action">
                                                <Button spinner={IsLoading2} title="Save" onClick={()=>{
                                                    unggahGambar()
                                                    close();
                                                }}/>
                                            </div>
                                        </Container>
                                    </Tab>
                                    </ReactBootStrap.Tabs>
                                
                                </div>
                            </div>
                            )}
                        </Popup>
                        {/* <Popup
                                trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:5,color:'#ffffff'}}> Insert Link </button>}
                                {...{ contentStyle2, overlayStyle, arrowStyle}}
                                modal
                                nested
                                lockScroll = {true}
                                offsetY = {0}
                            >
                                {close => (
                                <div>
                                    <div 
                                    // style={{display:'flex',justifyContent:'space-between',paddingLeft:25}}
                                        align="right"
                                    >
                                        
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                    </div>
                                    <hr/>
                                    <label style={{fontSize:20,fontWeight:600,paddingLeft:20}}>Insert Link</label>
                                    <div style={{overflow: 'auto', height:300,padding:20}}>
                                        <Input
                                            // style={{maxWidth:300}}
                                            label="URL to link"
                                            value={UrlLink}
                                            onChange={event=>{
                                                setUrlLink(event.target.value)
                                            }}
                                        />
                                        <Gap height={10}/>
                                        <Input
                                            //  style={{maxWidth:300}}
                                            label="Text to display"
                                            value={TextDisplay}
                                            onChange={event=>{
                                                setTextDisplay(event.target.value)
                                            }}
                                        />
                                        <Gap height={20}/>
                                        <div align="right">
                                            <Button style={{maxWidth:150,height:35,alignSelf:'right'}}
                                                onClick={()=>{
                                                    insertLink(UrlLink,TextDisplay)
                                                    close();
                                                }}
                                            >Submit</Button>
                                        </div>
                                    
                                    </div>
                                </div>
                                )}
                            </Popup> */}
                        {/* <Editor
                            editorState={InfoProduk}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName editor"
                            onEditorStateChange={event=>setInfoProduk(event)}
                        /> */}
                        {/* <SunEditor 
                             height={300}
                             setOptions={{
                                height: 200,
                                // buttonList: [['font', 'align'], ['image']]//buttonList.formatting // Or Array of button list, eg. [['font', 'align'], ['image']]
                                // Other option
                                buttonList : [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                    ['video', 'fullScreen', 'showBlocks', 'preview', 'print']
                                ],
                                
                            }}
                            // getSunEditorInstance={getSunEditorInstance}
                            // defaultValue={InfoProdukContent}
                            appendContents = {FileName}
                            setContents={DefaultContent}
                            // autoFocus={true}
                            onChange={(content)=>{
                                var konten = content.replace(new RegExp(window.location.href+"/{URL}", "gi"), "{URL}")
                                konten = konten.replace(new RegExp("/{URL}", "gi"), "{URL}")
                                console.log(konten)
                                setInfoProdukContent(konten);
                            }}
                        /> */}
                         <Editor
                            // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                            // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                            onInit={(evt, editor) => editorRef.current = editor}
                            // initialValue="<p>This is the initial content of the editor.</p>"
                            onSetContent={()=>{
                                console.log("onSetContent")
                            }}
                            onEditorChange={(newText)=>{
                                console.log("onEditor")
                                setInfoProdukContent(newText)
                            }}
                            onChange={()=>{
                                console.log("onChange")
                            }}
                            value={InfoProdukContent}
                            init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>

                    <Gap height={20}/>
                    
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                label="Meta Title"
                                value={MetaTitle} 
                                onChange={event=>{
                                    setMetaTitle(event.target.value)
                                }}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input 
                                label="Meta Keyword"
                                value={MetaKeyword} 
                                onChange={event=>{
                                    setMetaKeyword(event.target.value)
                                }}
                            />
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                    <label>Meta Description</label>
                    <TextArea
                        value={MetaDesc}
                        maxLength={160}
                        onChange={event=>setMetaDesc(event.target.value)}
                    />
                    Sisa Karakter SMS : {160 - MetaDesc.length}
                    
                    <Gap height={20}/>
                    <div className="button-action">
                        <Button 
                            spinner={loading} 
                            onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default EditTemplateMicrosite
