import md5 from 'md5';
import React, { Component, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Gap, Input,Dropdown } from '../../../components'
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import * as ReactBootStrap from 'react-bootstrap'
import { getBase64, validURL } from '../../../utils/functions';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditKategori = () => {
    const history = useHistory();
    const [loading,setLoading] = useState(false)
    // const location = useLocation();
    const [IDImgCategory,setIDImgCategory] = useState("")
    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const [StatusAktif,setStatusAktif] = useState("")
    const [CategoryDesc,setCategoryDesc] = useState("")
    const [CategoryDescOld,setCategoryDescOld] = useState("")
    const [FlagExternal,setFlagExternal] = useState("")
    const {form}=useSelector(state=>state.PaketReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const dispatch = useDispatch();


    useEffect(()=>{
        console.log("form",form);
        console.log("formPaket",form);
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
        cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else if(form.ParamKey==null || form.ParamKey=="" ||
            form.UserID==null || form.UserID==""){
            history.push('/list-kategori-gambar');
            return false;
        }else{
            setUserID(form.UserID)
            setParamKey(form.ParamKey)
            // setStatusAktif("1")
            // setCategoryDesc("BUTTON")
            // setIDImgCategory("2")
            // setFlagExternal("0")
            setCategoryDesc(form.DetailKategori.CategoryDesc)
            setCategoryDescOld(form.DetailKategori.CategoryDesc)
            setIDImgCategory(form.DetailKategori.IDImgCategory)
            // setFlagExternal(form.DetailKategori.FlagExternal)
        }
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }
    

    const simpanData = () => {
        // alert("simpan data "+NamaSource);

        let validasiMessage = "";
       
        if(CategoryDesc==""){
            validasiMessage = validasiMessage + "Silahkan isi nama kategori terlebih dahulu.\n";
        }else if(CategoryDesc.length > 100){
            validasiMessage = validasiMessage + "Nama kategori maksimal 100 karakter.\n";
        }
        if(IDImgCategory==""){
            validasiMessage = validasiMessage + "Silahkan isi ID kategori file terlebih dahulu.\n";
        }
        // if(FlagExternal==""){
        //     validasiMessage = validasiMessage + "Silahkan isi flag eksternal terlebih dahulu.\n";
        // }
       
       

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            // alert("submit");

            var vCategoryDesc = CategoryDesc;
            if(CategoryDesc==CategoryDescOld){
                vCategoryDesc = "-";
            }
            setLoading(true)
            var requestBody = JSON.stringify( {
                "UserID": UserID,
            	"ParamKey": ParamKey,
                "Method":"UPDATE",
                "IDImgCategory":IDImgCategory,
                "CategoryDesc":vCategoryDesc,
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImageCategory';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data.");
                    history.push('/list-kategori-gambar')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
                setLoading(false)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(false)
               alert(AlertMessage.failedConnect);
            //    history.push('')
            });
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
            <div className="blog-post">
                {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                <p className="title"><a href="/list-kategori-gambar"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Kategori Gambar</p>

                <hr style={{backgroundColor: 'black'}} />

                    <Gap height={20}/>

                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                style={{padding:10}}
                                required
                                label="Nama Kategori"
                                value={CategoryDesc} 
                                onChange={event=>setCategoryDesc(event.target.value)}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            {/* <Input 
                                style={{padding:10}}
                                required
                                label="Flag Eksternal"
                                value={FlagExternal} 
                                onChange={event=>setFlagExternal(event.target.value)}
                            /> */}
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                   
                
                <div className="button-action">
                    <Button 
                        spinner={loading} 
                        onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                </div>
            </div>
            </div>
        </div>
        
    )
}

export default EditKategori
