import md5 from 'md5';
import React, { Component, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Gap, Input,Dropdown} from '../../../components'
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import { Spinner } from 'react-bootstrap';
import { getBase64, validURL } from '../../../utils/functions';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Resizer from "react-image-file-resizer";
// import useImageSize from '@use-hooks/image-size';

const InputGambar = () => {
    const history = useHistory();
    const [NamaSource,setNamaSource] = useState("")
    const [NamaPaket,setNamaPaket] =useState("")
    const [UrlApiBu,setUrlApiBu] = useState("")
    const [UrlApiBu2,setUrlApiBu2] = useState("")
    const [LamaExpired,setLamaExpired] = useState(0)
    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    // const [StatusAktif,setStatusAktif] = useState("")
    const [ListSource,setListSource] = useState("")
    const [IsLoading,setIsLoading] = useState(false)
    const [IsLoading2,setIsLoading2] = useState(false)
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [Image,setImage] = useState(null)
    const [Thumbnail,setThumbnail] = useState(null)
    const [FileName,setFileName] = useState("")
    const [IDImgCategory,setIDImgCategory] = useState("")
    const [ListKategori,setListKategori] = useState([])
    const dispatch = useDispatch();
    // const [WidthThumbnail, HeightThumbnail] = useImageSize(Thumbnail);
    const WidthThumbnail = ""
    const HeightThumbnail = ""

    useEffect(()=>{
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
            cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else{
            setUserID(cookies.CookieUserID)
            setParamKey(cookies.CookieParamKey)

            loadDataKategoriGambar()
        }
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const simpanData = () => {
        //  alert("simpan data "+window.location.host);
        //  return false;

        let validasiMessage = "";
        if(Image==null){
            validasiMessage = validasiMessage + "Silahkan upload gambar terlebih dahulu.\n";
        }
        if(FileName==""){
            validasiMessage = validasiMessage + "Silahkan isi nama file terlebih dahulu.\n";
        }else if(FileName.length > 100){
            validasiMessage = validasiMessage + "Nama file maksimal 100 karakter.\n";
        }
        if(IDImgCategory==""){
            validasiMessage = validasiMessage + "Silahkan isi kategori terlebih dahulu.\n";
        }
        
        var thumbnail = Thumbnail.replace("data:image/png;base64,", "")

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "Filename": FileName,
                "DataBase64": Image,
                "DataBase64Small": thumbnail,
                "IDImgCategory": IDImgCategory
                // "IsActive":StatusAktif
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImage';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil insert data");
                    history.push('/list-koleksi-gambar')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const loadDataKategoriGambar = ()=>{

        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method":"SELECT",
            "Page": 1,
            "RowPage": 15,
            "OrderBy": "",
            "Order": "DESC",
            // "CategoryDesc":FilterKategori,
            // "FlagExternal":FilterFlagExternal
        });


        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImageCategory';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        // setResultMessage("Loading ...")
        // setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setListKategori(data.Result);
                // setTotalRecord(data.TotalRecords)
                // setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    // setResultMessage("Data tidak ditemukan")
                }
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            // setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
        //    setLoading(false)
        //    alert(AlertMessage.failedConnect)
        //    setResultMessage("")
        });
    }

    function onFileResize(event) {
        // const file = event.target.files[0];
        // Compress.imageFileResizer(
        //     file, // the file from input
        //     480, // width
        //     480, // height
        //     "JPEG", // compress format WEBP, JPEG, PNG
        //     70, // quality
        //     0, // rotation
        //     (uri) => {
        //         console.log(uri);
        //         // You upload logic goes here
        //     },
        //     "base64" // blob or base64 default base64
        // );

        /// --------------------- ori
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            // console.log("img ",img)
            if(img.size <= 200000){ //200kb
                var filename = (img.name).replace(/ /gi, "-");
                // console.log("",(img.name).replace(/ /gi, "-"))
                getBase64(img, (result) => {
                    // console.log("",result);
                    // console.log("insert into mst_image (Filename,DataBase64,InputDate) values('"+filename+"','"+result+"',NOW())");
                    setFileName(filename)
                    setImage(result)
                });
                /// ---------- begin resize -----------
                Resizer.imageFileResizer(
                    img, // the file from input
                    150, // width
                    150, // height
                    "PNG", // compress format WEBP, JPEG, PNG
                    70, // quality
                    0, // rotation
                    (uri) => {
                        // console.log("Thumb : "+uri);
                        setThumbnail(uri)
                        // var Height = Thumbnail.clientHeight;
                        // var width = Thumbnail.clientWidth;
                        console.log("LEBAR : " + WidthThumbnail);
                        console.log("TINGGI : " + HeightThumbnail);

                        // You upload logic goes here
                    },
                    "base64" // blob or base64 default base64
                );
                /// ---------------------
            }else{
                setImage(null)
                setFileName("")
                alert("Maaf, Ukuran gambar terlalu besar. Maksimal 200 kb");
            }
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
            <div className="blog-post">
                {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                <p className="title"><a href="/list-koleksi-gambar"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Upload Gambar</p>

                <hr style={{backgroundColor: 'black'}} />
                
                {Thumbnail != null &&
                    <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                        <label>Thumbnail :</label>
                        <Gap height={10}/>
                        <img src={Thumbnail} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}}/>
                    </div>
                    }

                {Image != null &&
                    <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                        <label>Image :</label>
                        <Gap height={10}/>
                        <img src={"data:image/png;base64,"+Image} style={{width:'100%'}}/>
                    </div>
                    }
                    
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                required
                                label="Image"
                                type="file"
                                // value={Image} 
                                accept=".jpeg, .png, .jpg"
                                onChange={(e)=>onFileResize(e)}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input 
                                style={{padding:10}}
                                required
                                label="Nama File"
                                value={FileName} 
                                onChange={event=>setFileName(event.target.value)}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            
                            <Dropdown 
                                label = "Kategori"
                                required
                                style={{width:300}}
                                onChange = {(event) => {setIDImgCategory(event.target.value)}}>
                                    <option value= "" selected={""==IDImgCategory}>--Pilih--</option>
                                    { ListKategori.length > 0 ? ListKategori.map((item,index)=>{
                                            return <option value={item.IDImgCategory} selected={item.IDImgCategory==IDImgCategory}>{item.CategoryDesc}</option>;
                                    }) : <option value= "">--Tidak ada data--</option>}
                            </Dropdown>
                        </Card>
                    </CardDeck>
                  

                    <Gap height={20}/>

                    <div className="button-action">
                        <Button 
                            spinner={IsLoading2} 
                            onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}


export default InputGambar
