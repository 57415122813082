import React from 'react'
import { useHistory } from 'react-router-dom';
import './header.scss';
import { useCookies } from 'react-cookie';
import { favicon } from '../../../assets';
import { Navbar, Nav, Container,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
import { setForm } from '../../../redux';
import { useSelector,useDispatch } from 'react-redux';


const Header = ({noNavbar}) => {
    noNavbar = true;
    const history =useHistory();
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const {form}=useSelector(state=>state.PaketReducer);
    const dispatch = useDispatch();
    // console.log("logout"+cookies.CookieParamKey+" - "+cookies.CookieUserID);
    // console.log("testes "+form.ParamKey+" - "+form.UserID);
    return (
        <div>
            {noNavbar ? 
            <Navbar style={{backgroundColor:'#e9ecef',paddingLeft:30}} expand="lg">
                <Navbar.Brand 
                // href="/" 
                >
                    <img 
                        style={{width:40,height:40,marginRight:10}}
                        src={favicon} alt="logo" /> 
                    Digital Voucher
                </Navbar.Brand>
                    
            </Navbar>  
            :
            <Navbar style={{backgroundColor:'#e9ecef',paddingLeft:100}} expand="lg">
                <Navbar.Brand href="/">
                    <img 
                        style={{width:40,height:40,marginRight:10}}
                        src={favicon} alt="logo" /> 
                    Digital Voucher
                </Navbar.Brand>
                {((cookies.CookieParamKey!=null && cookies.CookieParamKey!="" &&
                            cookies.CookieUserID!=null && cookies.CookieUserID!="") || (form.ParamKey!=null && form.ParamKey!="" &&
                            form.UserID!=null && form.UserID!=""))
                            &&
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                }
                    {((cookies.CookieParamKey!=null && cookies.CookieParamKey!="" &&
                            cookies.CookieUserID!=null && cookies.CookieUserID!="") || (form.ParamKey!=null && form.ParamKey!="" &&
                            form.UserID!=null && form.UserID!=""))
                            && 
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link href="/list-user-login" active={form.PageActive=="User"}>List User</Nav.Link>
                        <Nav.Link href="/list-WhiteList-microsite" active={form.PageActive=="Whitelist"}>IP Whitelist</Nav.Link>
                        <Nav.Link href="/" active={form.PageActive=="Paket"}>Paket</Nav.Link>
                        <Nav.Link href="/list-template-microsite" active={form.PageActive=="Microsite"}>Template Microsite</Nav.Link>
                        <Nav.Link href="/list-template-sms-email" active={form.PageActive=="SMSEMAIL"}>Template SMS & Email</Nav.Link>
                        <Nav.Link href="/list-koleksi-gambar" active={form.PageActive=="Gambar"}>Koleksi Gambar</Nav.Link>
                    
                        </Nav>
                        <Form inline>
                            {/* {cookies.CookieParamKey!=null && cookies.CookieParamKey!="" &&
                            cookies.CookieUserID!=null && cookies.CookieUserID!=""
                            && */}
                        <Button variant="outline-success" onClick={()=>{
                            removeCookie('CookieUserID', { path: '/' });
                            removeCookie('CookieParamKey', { path: '/'});
                            dispatch(setForm("ParamKey",''))
                            dispatch(setForm("UserID",''))
                            history.push('/login')
                        }}>Logout</Button>
                        {/* } */}
                        </Form>
                    </Navbar.Collapse>
                    }
                    
            </Navbar>    
            }
        </div>
    )
}


export default Header
