import React, { Component, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { LoginBg } from '../../assets';
import { Button, Gap, Input, Link } from '../../components';
import './Login.scss'
import md5 from 'md5';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../redux';
import {AlertMessage, paths} from '../../utils'
import { useCookies } from 'react-cookie';
import { historyConfig, validEmail } from '../../utils/functions';
import { favicon } from '../../assets';

const Login = () => {
    const history = useHistory(historyConfig);
    const [Email,setEmail] = useState("")
    const [Password,setPassword] = useState("")
    const [Loading,setLoading] = useState(false)
    const axios = require('axios');
    const {form}=useSelector(state=>state.LoginReducer);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['user']);

    useEffect(()=>{
       
        if(cookies.CookiePass==null || cookies.CookiePass=="" ||
        cookies.CookieUserID==null || cookies.CookieUserID==""){
            
        }else{
            setEmail(cookies.CookieUserID)
            setPassword(cookies.CookiePass)
            // Login(cookies.CookieUserID,cookies.CookiePass)
        }

    },[])

    // const Test = async () => {
    //     await setEmail("tes@tes.co")
    //     console.log("Email :"+Email)
    // }

    const Login = (EmailValue,PasswordValue) => {
        // alert("simpan data "+NamaSource);

        let validasiMessage = "";
        if(EmailValue==""){
            validasiMessage = validasiMessage + "Silahkan isi email terlebih dahulu.\n";
        }
        // else if(!validEmail(EmailValue)){
        //     validasiMessage = validasiMessage + "Pastikan email anda valid.\n";
        // }
        else if(EmailValue.length > 100){
            validasiMessage = validasiMessage + "Email maksimal 100 karakter.\n";
        }
        if(PasswordValue==""){
            validasiMessage = validasiMessage + "Silahkan isi password terlebih dahulu.\n";
        }
        
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            var requestBody = JSON.stringify({
                "UserID": EmailValue,
                "Password": md5(PasswordValue)
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'login';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setLoading(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                setLoading(false)
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                
                if(data.ErrorCode=="0"){
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    console.log("",date);

                    setCookie('CookieUserID', Email, { path: '/' });
                    setCookie('CookiePass', Password, { path: '/' });
                    setCookie('CookieParamKey', data.ParamKey, { path: '/',expires : new Date(date) });
                    // setCookie('CookieParamKeyExpired', data.DateTime, { expires : new Date('05/17/2021 20:55:49') });
                    dispatch(setForm("ParamKey",data.ParamKey))
                    dispatch(setForm("UserID",Email))
                    console.log("redirect");
                    setTimeout(()=>{
                        history.push('')
                    },300)
                    
                }else{
                    // Login or password not valid
                    alert("Login gagal. User ID atau Password tidak cocok.");
                }
                // history.push('/',{
                //     UserID:data.UserID,
                //     ParamKey:data.ParamKey
                // })
            })
            .catch((error) => {
                setLoading(false)
               console.log("er "+error.message);
            //    alert("er "+error.message);
                alert(AlertMessage.failedConnect)
            });

            // axios.post(url, requestBody)
            //   .then(function (response) {
            //     console.log(JSON.stringify(response.data));
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });

            //   axios({
            //     method: 'post',
            //     url: url,
            //     data: requestBody,
            //     // mode: 'no-cors',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Signature': Signature
            //     }
            //   })
            //   .then(function (response) {
            //     console.log(JSON.stringify(response.data));
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });

           
        }



    }

    return (
        <div className="main-page" style={{justifyContent:"center",height:"100vh",backgroundColor:"rgb(173 171 171)",paddingTop:"6%",paddingBottom:"6%"}}>
            <div className="right">
                <div style={{display:'flex',textAlign:'center',alignItems:'center',alignSelf:'center'}}>
                <img 
                    style={{width:40,height:40,marginRight:10,borderRadius:4}}
                    src={favicon} alt="logo" /> 
                    <label style={{fontWeight: 'bold',fontSize: 20,color: '#4e4c4c'}}>Digital Voucher</label>
                </div>
                <Gap height={18}/>
                <p className="title">Login</p>
                <Input label="User ID" placeholder="User ID"
                    value={Email} 
                    // type="email"
                    onChange={event=>{
                        setEmail(event.target.value)
                        dispatch(setForm("UserID",event.target.value))
                    }}
                />
                <Gap height={18}/>
                <Input label="Password" placeholder="Password"
                    value={Password} 
                    type="password"
                    onChange={event=>{
                        setPassword(event.target.value)
                        dispatch(setForm("Password",event.target.value))
                    }}

                />
                <Gap height={20}/>
                <Button spinner={Loading} title="Login" onClick={()=>Login(Email,Password)}/>
                {/* <Button title="TES" onClick={()=>Test()}/> */}
                <Gap height={100}/>
                {/* <Link title="Belum punya akun, silahkan daftar"  onClick={()=>history.push('/register')}/> */}
            </div>
        </div>
    )
}

export default Login;
