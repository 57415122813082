import md5 from 'md5';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Gap, Header, Input, Dropdown, TextArea } from '../../components';
import { AlertMessage, paths } from '../../utils';
import { generateTRXID, getParameterByName, validEmail } from '../../utils/functions';
// import './home.scss'
import { useCookies } from 'react-cookie';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUpload, faCheckCircle, faTags, faEdit, faFilter, faExternalLinkAlt, faEye, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import * as ReactBootStrap from 'react-bootstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Pagination from 'react-bootstrap/Pagination'
import { getBase64, validURL } from '../../utils/functions';

import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { CSVLink, CSVDownload } from "react-csv";

import xlsx from 'xlsx';
import DataTable from 'react-data-table-component';

import LabelTH from '../../components/molecules/LabelTH'
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../redux';

import Form from 'react-bootstrap/Form'
import Popup from 'reactjs-popup';
import { Markup } from 'interweave';
import Badge from 'react-bootstrap/Badge'

import { Editor } from '@tinymce/tinymce-react';

const NAME = "{NAME}"
const VOUCHERNO = "{VOUCHERNO}"
const PARAM = "{PARAM}"
const EXPIRED = "{EXPIRED}"
const URL = "{URL}"
const LINKAKTIVASI = "{LINKAKTIVASI}"

const Voucher = () => {
    
    const history = useHistory();
    const [ListVoucher,setListVoucher] = useState([])
    const [ListVoucherForCsv,setListVoucherForCsv] = useState([])
    const dispatch = useDispatch();
    const [ListTemplate,setListTemplate] = useState([])
    const [ListTemplateMicrosite,SetListTemplateMicrosite] = useState([])
    const [ListCategoryImage,setListCategoryImage] = useState([])
    

    const [KodeVoucher,setKodeVoucher] = useState("")
    const [Param,setParam] = useState(getParameterByName('param'))
    const [loading,setLoading] = useState("")
    const [Loading2,setLoading2] = useState(false)
    const [LoadingSubmit,setLoadingSubmit] = useState("")
    const [LoadingProsesData,setLoadingProsesData] = useState("")
    const [cookies, setCookie] = useCookies(['user']);
    // const [Param,setParam] = useState(cookies.CookieParamKeyPost)
    const [ResultMessage,setResultMessage] = useState("")
    const [ArrayResultMessage,setArrayResultMessage] = useState([
    //     {
    //     VoucherNo:"r-Ry5AiKOA8-Vk3RdwNNoKeQswtGwVKYCL119yXv-4U=",
    //     Name:"Yuono Edi",
    //     Email:"yedisetyo@gmail.ocm",
    //     NoHandphone:"083866502154",
    //     Expired:"30",
    //     ProductName:"PA02",
    //     SourceCode:"001",
    //     TemplateEmail:"TemplateEmail",
    //     TemplateSMS:"TemplateSMS",
    //     ErrorMessage:"Data sudah pernah disubmit."
    //  },
    //  {
    //     VoucherNo:"N76gI353NlGcsBIRMNMgIdBfE0VfQODYAOShwglZPlE=",
    //     Name:"Martin",
    //     Email:"martintes@tes.co",
    //     NoHandphone:"123456789012",
    //     Expired:"30",
    //     ProductName:"PA02",
    //     SourceCode:"001",
    //     TemplateEmail:"TemplateEmail",
    //     ErrorMessage:"Data sudah pernah disubmit."
    //  }
    ])
    const [ArrayResultMessageProses,setArrayResultMessageProses] = useState([
    //     {
    //     VoucherNo:"r-Ry5AiKOA8-Vk3RdwNNoKeQswtGwVKYCL119yXv-4U=",
    //     Name:"Yuono Edi",
    //     Email:"yedisetyo@gmail.ocm",
    //     NoHandphone:"083866502154",
    //     Expired:"30",
    //     ProductName:"PA02",
    //     SourceCode:"001",
    //     TemplateEmail:"TemplateEmail",
    //     TemplateSMS:"TemplateSMS",
    //     ErrorMessage:"Data sudah pernah disubmit."
    //  },
    //  {
    //     VoucherNo:"N76gI353NlGcsBIRMNMgIdBfE0VfQODYAOShwglZPlE=",
    //     Name:"Martin",
    //     Email:"martintes@tes.co",
    //     NoHandphone:"123456789012",
    //     Expired:"30",
    //     ProductName:"PA02",
    //     SourceCode:"001",
    //     TemplateEmail:"TemplateEmail",
    //     ErrorMessage:"Data sudah pernah disubmit."
    //  }
    ])
    const [DataBerhasilUpload,setDataBerhasilUpload] = useState([
        //     {
        //     VoucherNo:"r-Ry5AiKOA8-Vk3RdwNNoKeQswtGwVKYCL119yXv-4U=",
        //     Name:"Yuono Edi",
        //     Email:"yedisetyo@gmail.ocm",
        //     NoHandphone:"083866502154",
        //     Expired:"30",
        //     ProductName:"PA02",
        //     SourceCode:"001",
        //     TemplateEmail:"TemplateEmail",
        //     TemplateSMS:"TemplateSMS",
        //     ErrorMessage:"Data sudah pernah disubmit."
        //  },
        //  {
        //     VoucherNo:"N76gI353NlGcsBIRMNMgIdBfE0VfQODYAOShwglZPlE=",
        //     Name:"Martin",
        //     Email:"martintes@tes.co",
        //     NoHandphone:"123456789012",
        //     Expired:"30",
        //     ProductName:"PA02",
        //     SourceCode:"001",
        //     TemplateEmail:"TemplateEmail",
        //     ErrorMessage:"Data sudah pernah disubmit."
        //  }
        ])

    

    const [Qty,setQty] = useState(0)
    const [DateTime,setDateTime] = useState("")
    const [Name,setName] = useState("")
    const [Expired,setExpired] = useState(0)
    const [ProductName,setProductName] = useState("")
    const [SourceCode,setSourceCode] = useState("")
    const [TemplateEmail,setTemplateEmail] = useState("")
    const [TemplateSMS,setTemplateSMS] = useState("")
    const [TemplateEmailOld,setTemplateEmailOld] = useState("")
    const [TemplateSMSOld,setTemplateSMSOld] = useState("")
    const [Refresh,setRefresh] = useState(false)

    const [selectedFile, setSelectedFile] = useState("");
    const [FileName,setFileName] = useState("")
    const [Image,setImage] = useState(null)
    
    const [CategoryImage,setCategoryImage] = useState([])
    
    const [PageNumber,setPageNumber] = useState(1)
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(10)
    const [OrderBy,setOrderBy] = useState("Name")
    const [Order,setOrder] = useState("DESC")
    const [PageActiveGambar,setPageActiveGambar] = useState(1)
    const [TotalRecordGambar,setTotalRecordGambar] = useState(1)
    const [TotalPagesGambar,setTotalPagesGambar] = useState(1)
    const [RowPageGambar,setRowPageGambar] = useState(5)
    const [OrderByGambar,setOrderByGambar] = useState("InputDate")
    const [OrderGambar,setOrderGambar] = useState("DESC")
    
    const [FieldVoucherNo,setFieldVoucherNo] = useState("")
    const [FieldName,setFieldName] = useState("")
    const [FieldNameKetik,setFieldNameKetik] = useState("")
    const [FieldEmail,setFieldEmail] = useState("")
    const [FieldEmailKetik,setFieldEmailKetik] = useState("")
    const [FieldNoHandphone,setFieldNoHandphone] = useState("")
    const [FieldNoHandphoneKetik,setFieldNoHandphoneKetik] = useState("")
    const [FieldTextMessage,setFieldTextMessage] = useState("")
    const [FieldStatusKirim,setFieldStatusKirim] = useState("")

    const [PilihTemplate,setPilihTemplate] = useState("")

    const [showPreviewData,setShowPreviewData] = useState(false)
    const [showFilter,setShowFilter] = useState(false)
    const [showTabelMain,setshowTabelMain] = useState(true)
    const [ParamLogin,setParamLogin] = useState("")
    const [IsProcess,setIsProcess] = useState("")
    const [Email,setEmail] = useState("")
    const [NoHandphone,setNoHandphone] = useState("")

    const [ParamKey] = useState("MTYyNDAwNDYwNjAzNTQxMA==uBpbV2l9kDAVPGKy3byFAuFa4srb5B")

    const [activeId, setActiveId] = useState('1');
    const [Downloaded, setDownloaded] = useState('0');
    const [showStatistikUpload, setShowStatistikUpload] = useState(false);
    const [TotalDataUpload, setTotalDataUpload] = useState(0);
    const [JumlahSelesaiUpload, setJumlahSelesaiUpload] = useState(0);
    const [CountDataSukses, setCountDataSukses] = useState(0);
    const [CountDataGagal, setCountDataGagal] = useState(0);
    const [WitdhDiv,setWitdhDiv] = useState(1300)
    

    function toggleActive(id) {
        if (activeId === id) {
          setActiveId(null);
        } else {
          setActiveId(id);
        }
    }

    useEffect(()=>{
        // if(DataBerhasilUpload.length>0){
        //     loadData("reload")
        // }else{
            loadData()
            onLoadGambar()
            onLoadGambarCategory()
        // }
    },[OrderBy,OrderByGambar,Order,OrderGambar,PageActive,PageActiveGambar,DataBerhasilUpload])

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
 
    const [CharLimit,setCharLimit] = useState(160)

    const [FieldNamaFile,setFieldNamaFile] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [ListGambar,setListGambar] = useState([])
    const [LinkImage,setLinkImage] = useState("")
    const editorRef = useRef(null);

    const MobileVersion=()=>{
        setWitdhDiv(350);
    }

    const WebsiteVersion=()=>{
        setWitdhDiv(1300);
    }

    const PilihImage=(Filename)=>{
        if(LinkImage == ""){
            setTemplateEmail(TemplateEmail+'<img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
        }else{
            setTemplateEmail(TemplateEmail+'<a href="{LINKAKTIVASI}"><img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
        }
        
        // alert('Image telah dipilih, silakan tutup popup jika sudah selesai');
        // close()
    }
    
    const onLoadGambar = () => {

        if (FieldNamaFile != "") {
            var NamaFile = "%"+FieldNamaFile+"%";
        } else {
            var NamaFile = "";
        } 

        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "1";
        }

        if (CategoryImage != ""){
            var FilterCategory = CategoryImage
        } else{
            var FilterCategory = ""
        }
        
        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Page": PageActiveGambar,
            "RowPage": RowPageGambar,
            "OrderBy": OrderByGambar,
            "Order": OrderGambar,
            "Filename":NamaFile,
            "IDImage":"",
            "IDImgCategory":FilterCategory,
            "CategoryDesc":""
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_IMAGE;
        var url = paths.URL_API_EXTERNAL+'viewImage';
        // var url = 'http://192.168.108.220/viewImage';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListGambar(data.Result);
                setTotalRecordGambar(data.TotalRecords)
                setTotalPagesGambar(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const onLoadGambarCategory = () => {
        
        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Page":1,
            "RowPage":15,
            "OrderBy":"",
            "Order":"DESC",
            "IDImgCategory":"",
            "CategoryDesc":""
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_IMAGE;
        var url = paths.URL_API_EXTERNAL+'viewImageCategory';
        // var url = 'http://192.168.108.220/viewImage';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListCategoryImage(data.Result);
                // setTotalRecord(data.TotalRecords)
                // setTotalPages(data.TotalPages)
                // if(data.TotalRecords<1){
                //     setResultMessage("Data tidak ditemukan")
                // }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }


    // process CSV data
    const processData = (dataString,e) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
        const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        if (headers && row.length == headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
            let d = row[j];
            if (d.length > 0) {
                if (d[0] == '"')
                d = d.substring(1, d.length - 1);
                if (d[d.length - 1] == '"')
                d = d.substring(d.length - 2, 1);
            }
            if (headers[j]) {
                obj[headers[j]] = d;
            }
            }
    
            // remove the blank rows
            if (Object.values(obj).filter(x => x).length > 0) {
            list.push(obj);
            }
        }
        }
    
        // prepare columns list from headers
        const columns = headers.map(c => ({
        name: c,
        selector: c,
        }));
        setArrayResultMessage([])
        setDataBerhasilUpload([])
        setArrayResultMessageProses([])
        setShowStatistikUpload(false)
        setTotalDataUpload(0)
        setJumlahSelesaiUpload(0)
        setCountDataSukses(0)
        setCountDataGagal(0)

        console.log("list "+JSON.stringify(list))

        var listExcel = renameAtribut(list);
    
        // setData(list);
        if(validateExcelKolom(listExcel)){
            setColumns(columns);
            console.log("header "+JSON.stringify(columns))

            // filterDataExcel(listExcel).map((item1,index1)=>{
            //     ListVoucherForCsv.map((item2,index2)=>{
            //         if(item1.VoucherNo==item2.VoucherNo){
            //             var ListVoucherForCsvTemp = [...ListVoucherForCsv]
            //             ListVoucherForCsvTemp[index2].Name= item1.Name;
            //             ListVoucherForCsvTemp[index2].Email= item1.Email;
            //             ListVoucherForCsvTemp[index2].NoHandphone= item1.NoHandphone;
            //             setListVoucherForCsv(ListVoucherForCsvTemp)
            //         }
            //     })
            // })

            setListVoucherForCsv(addAtribut(filterDataExcel(listExcel)))
            console.log("list voucher new "+JSON.stringify(addAtribut(filterDataExcel(listExcel))))
            // console.log("list voucher new "+JSON.stringify(ListVoucherForCsv))
            setShowPreviewData(true)
            // setshowTabelMain(false)
        }else{
            setShowPreviewData(false)
            // setshowTabelMain(true)
            e.target.value=null;
        }
    }

    const validateExcelKolom = (data)=>{
        try{
            if(data[0].VoucherNo==null){
                alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
                return false;
            }else if(data[0].Name==null){
                alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
                return false;
            }else if(data[0].Email==null){
                alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
                return false;
            }else if(data[0].NoHandphone==null){
                alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
                return false;
            // }else if(data[0].TextMessage==null){
            //     alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
            //     return false;
            }else{
                return true;
            }
        }catch(e){
            alert("Import data gagal. Pastikan format data yang anda masukkan valid.");
            return false;
        }
    }
    
    const filterDataExcel = (data,IdPanel,Sisi)=>{
         
        var filter = {
            // VoucherNo: "",
            Name: "",
            Email: "",
            NoHandphone: "",
            // TextMessage: ""
        };
        
        data= data.filter(
            obj => 
            // obj.VoucherNo != filter.VoucherNo 
            // || 
            obj.Name != filter.Name
            || obj.Email != filter.Email
            || obj.NoHandphone != filter.NoHandphone
            // || obj.TextMessage != filter.TextMessage
            )
        return data;
    }
    
    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = xlsx.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = xlsx.utils.sheet_to_csv(ws, { header: 1 });
        processData(data,e);
        };
        reader.readAsBinaryString(file);
    }

    const loadData = (type)=>{
        console.log("loaddata "+type)


        if (FieldName != "") {
            if(FieldName =="Ketik"){
                var FilterName = "%"+FieldNameKetik+"%";
            }else{
                var FilterName = ""+FieldName+"";
            }
        } else {
            var FilterName = "";
        }

        if (FieldEmail != "") {
            if(FieldEmail =="Ketik"){
                var FilterEmail = "%"+FieldEmailKetik+"%";
            }else{
                var FilterEmail = ""+FieldEmail+"";
            }
        } else {
            var FilterEmail = "";
        } 
        
        if (FieldNoHandphone != "") {
            if(FieldNoHandphone =="Ketik"){
                var FilterNoHandphone = "%"+FieldNoHandphoneKetik+"%";
            }else{
                var FilterNoHandphone = ""+FieldNoHandphone+"";
            }
        } else {
            var FilterNoHandphone = "";
        }


        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Name":FilterName,
            "Email":FilterEmail,
            "NoHandphone":FilterNoHandphone,
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_VOUCHER;
        var url = paths.URL_API_EXTERNAL+'viewVoucher';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setResultMessage("Loading ...")

        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setQty(parseInt(data.Qty))
                setDateTime(data.DateTime)
                setName(data.Name)
                setTotalRecord(data.Qty)
                setTotalPages(data.TotalPages)
                // alert(data.Qty)
                if(data.TotalRecords<1) {
                    setResultMessage("Data tidak ditemukan")
                    setListVoucher([])
                }else{
                    console.log("res "+JSON.stringify(data.Result[0]))
                    // alert(data.Result[0].ProductName)
                    setExpired(data.Result[0].Expired)
                    setProductName(data.Result[0].ProductName)
                    setSourceCode(data.Result[0].SourceCode)
                    setIsProcess(data.IsProcess)
                    setEmail(data.Email)
                    setNoHandphone(data.NoHandphone)
                    if(type!="reload"){
                        setTemplateSMS(data.Result[0].TemplateSMS)
                        setTemplateEmail(data.Result[0].TemplateEmail)
                        // setListVoucherForCsv(data.Result)
                    }
                    setTemplateSMSOld(data.Result[0].TemplateSMS)
                    setTemplateEmailOld(data.Result[0].TemplateEmail)
                    setListVoucher((data.Result))
                    
                }
            }else{
                if(data.ErrorMessage=="Error - ParamKey is not valid"){
                    setResultMessage("Url anda tidak valid.")
                }else{
                    setResultMessage("Data tidak ditemukan.")
                }

            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const upload = () => {
        setTotalDataUpload(0)
        setJumlahSelesaiUpload(0)
        setCountDataSukses(0)
        setCountDataGagal(0)

        // console.log("json send "+JSON.stringify(ListVoucherForCsv))
        var r = window.confirm("Apakah anda yakin data yang diupload sudah benar?");
        if (r == false) {
            console.log("canceled")
            return false;
        }

        var ListUpload = filterDataExcel(ListVoucherForCsv);
        var lengthArray = ListUpload.length;
        setTotalDataUpload(lengthArray)
       

        if(lengthArray<1){
            alert("Data excel masih kosong. Silahkan isi data di excel terlebih dahulu.")
            return false;
        }

        let validasiMessage = "";
     
        if (TemplateSMS.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {NAME} silakan lihat panduan konten\n";
        }
       
        if (TemplateSMS.indexOf("{EXPIRED}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {EXPIRED} silakan lihat panduan konten\n";
        }
        if (TemplateSMS.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }
        if (TemplateSMS.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {LINKAKTIVASI} silakan lihat panduan konten\n";
        }
        if(TemplateEmail == "")
        {
            validasiMessage = validasiMessage + "Silahkan isi Template Email terlebih dahulu.\n";
        }
        
        if (TemplateEmail.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {NAME} silakan lihat panduan konten\n";
        }
        
        if (TemplateEmail.indexOf("{EXPIRED}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {EXPIRED} silakan lihat panduan konten\n";
        }
        if (TemplateEmail.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }
        if (TemplateEmail.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {LINKAKTIVASI} silakan lihat panduan konten\n";
        }

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }

      

        var numSplice = Math.ceil(lengthArray/5);


        var ListVoucherForCsv2 = ListUpload.splice(numSplice);
        var ListVoucherForCsv3 = ListVoucherForCsv2.splice(numSplice);
        var ListVoucherForCsv4 = ListVoucherForCsv3.splice(numSplice);
        var ListVoucherForCsv5 = ListVoucherForCsv4.splice(numSplice);
        console.log("ListVoucherForCsv : "+JSON.stringify(ListUpload))
        console.log("ListVoucherForCsv2 : "+JSON.stringify(ListVoucherForCsv2))
        console.log("ListVoucherForCsv3 : "+JSON.stringify(ListVoucherForCsv3))
        console.log("ListVoucherForCsv4 : "+JSON.stringify(ListVoucherForCsv4))
        console.log("ListVoucherForCsv5 : "+JSON.stringify(ListVoucherForCsv5))

        // return false;

        console.log(lengthArray+" numsplice "+numSplice)

        setShowStatistikUpload(true)
        var varJumlahSelesaiUpload = 0;
        var varCountDataSukses = 0;
        var varCountDataGagal = 0;

        ListUpload.map((item,index)=>{
            console.log("1 hit service "+item.Name)
            var result = simpanData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,TemplateEmail,TemplateSMS)
            console.log("resultsimpandata " +result)
            if(result=="1"){
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataSukses = varCountDataSukses +1;
            }else{
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataGagal = varCountDataGagal +1;
            }
        })
        
        ListVoucherForCsv2.map((item,index)=>{
            var result = simpanData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,TemplateEmail,TemplateSMS)
            console.log("resultsimpandata " +result)
            if(result=="1"){
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataSukses = varCountDataSukses +1;
            }else{
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataGagal = varCountDataGagal +1;
            }
            console.log("2 hit service "+item.Name)
        })
        ListVoucherForCsv3.map((item,index)=>{
            var result = simpanData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,TemplateEmail,TemplateSMS)
            console.log("resultsimpandata " +result)
            if(result=="1"){
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataSukses = varCountDataSukses +1;
            }else{
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataGagal = varCountDataGagal +1;
            }
            console.log("3 hit service "+item.Name)
        })
        ListVoucherForCsv4.map((item,index)=>{
            var result = simpanData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,TemplateEmail,TemplateSMS)
            console.log("resultsimpandata " +result)
            if(result=="1"){
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataSukses = varCountDataSukses +1;
            }else{
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataGagal = varCountDataGagal +1;
            }
            console.log("4 hit service "+item.Name)
        })
        ListVoucherForCsv5.map((item,index)=>{
            var result = simpanData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,TemplateEmail,TemplateSMS)
            console.log("resultsimpandata " +result)
            if(result=="1"){
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataSukses = varCountDataSukses +1;
            }else{
                varJumlahSelesaiUpload = varJumlahSelesaiUpload +1;
                varCountDataGagal = varCountDataGagal +1;
            }
            console.log("5 hit service "+item.Name)
        })

        
        setJumlahSelesaiUpload(varJumlahSelesaiUpload)
        setCountDataSukses(varCountDataSukses)
        setCountDataGagal(varCountDataGagal)

        setTimeout(()=>{
            loadData("reload");
            // setLoadingSubmit(false)
            // if(ArrayResultMessage.length<1){
            //     toggleActive('1')
            // }else{
            //     toggleActive('0')
            // }
        },1200)
        
        // e.target.value=null;
        return;

       
    }

    const simpanData = (vvoucherno,vname,vemail,vhandphone,vtemplateemail,templatesms) => {
        
        var validasiMessage = "";

        if(vvoucherno == ""){
            validasiMessage = validasiMessage + "Voucher ID tidak boleh kosong. ";
        }
        if(vname == ""){
            validasiMessage = validasiMessage + "Silahkan isi Nama terlebih dahulu. ";
        }
        if(vemail == ""){
            validasiMessage = validasiMessage + "Silahkan isi Email terlebih dahulu. ";
        }else if(!validEmail(vemail)){
            validasiMessage = validasiMessage + "Pastikan email anda valid. ";
        }
        if(vhandphone == ""){
            validasiMessage = validasiMessage + "Silahkan isi No. Handphone terlebih dahulu. ";
        }else if(vhandphone.length < 10 || vhandphone.length > 14){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. ";
        }else if(isNaN(vhandphone)){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }else if(vhandphone.indexOf("+") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }else if(vhandphone.indexOf("E") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }

        if(vtemplateemail == ""){
            validasiMessage = validasiMessage + "Silahkan isi template email terlebih dahulu. ";
        }
        if(templatesms == ""){
            validasiMessage = validasiMessage + "Silahkan isi template sms terlebih dahulu. ";
        }
       
        

        if(validasiMessage!=""){
            // alert(validasiMessage);

            // var jsonError = [...ArrayResultMessage,{
            //     VoucherNo: vvoucherno,
            //     Name: vname,
            //     Email: vemail,
            //     NoHandphone: vhandphone,
            //     TemplateEmail:vtemplateemail,
            //     TemplateSMS:templatesms,
            //     ErrorMessage:validasiMessage
            //  }];

            //  console.log("err "+JSON.stringify(jsonError))
            
            // setArrayResultMessage(jsonError)
            ArrayResultMessage.push({
                VoucherNo: vvoucherno,
                Name: vname,
                Email: vemail,
                NoHandphone: vhandphone,
                TemplateEmail:vtemplateemail,
                TemplateSMS:templatesms,
                ErrorMessage:validasiMessage
             })
             console.log("err "+JSON.stringify(ArrayResultMessage))
             setArrayResultMessage(ArrayResultMessage)
             setRefresh(true)
            return false;
        }else{
            // alert("SUKSES");
            // return false;


            setLoadingSubmit(true)
            // setShowStatistikUpload(true)
            var requestBody = JSON.stringify({
                "VoucherNo": vvoucherno,
                "Name": vname,
                "Email": vemail,
                "NoHandphone": vhandphone,
                "TemplateEmail": vtemplateemail,
                "TemplateSMS": templatesms
            });
            // alert(form.IDWeb)
            console.log(requestBody);
            
            // var enckey = paths.EncKey;
            var enckey = paths.ENCKEY_UPDATE_VOUCHER;
            var url = paths.URL_API+'updateVoucher';
            // var url = 'http://192.168.108.220/updateVoucher';
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                setLoadingSubmit(false)
                // setJumlahSelesaiUpload(JumlahSelesaiUpload+1)
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                // history.push('')
                if(data.ErrorCode=="0"){
                //     alert("Berhasil update data.");
                //     history.push('/voucher')
                    // setCountDataSukses(CountDataSukses+1)
                    console.log("Berhasil update data."+vname)
                    DataBerhasilUpload.push({
                        VoucherNo: vvoucherno,
                        Name: vname,
                        Email: vemail,
                        NoHandphone: vhandphone,
                        TemplateEmail:vtemplateemail,
                        TemplateSMS:templatesms,
                        ErrorMessage:validasiMessage
                     })
                     console.log("data berhasil "+JSON.stringify(DataBerhasilUpload))
                     setDataBerhasilUpload(DataBerhasilUpload)
                     setRefresh(true)
                    return "1";
                }else{
                    // setCountDataGagal(CountDataGagal+1)
                    //     alert(data.ErrorMessage);
                    var texterror = "";
                    if(data.ErrorMessage=="Error Execute data"){
                        texterror = "Error, ID voucher tidak valid. Download ulang data excel lalu diisi dan diupload.";
                    }else if(data.ErrorMessage=="Error - Update data"){
                        texterror = "Error, Data sudah pernah dilengkapi sebelumnya";
                    }else if(data.ErrorMessage=="Error, Data Already Process"){
                        texterror = "Error, Data sudah pernah diproses";
                    }else if(data.ErrorMessage=="; NoHandphone must be numeric or Length must be more than 5 characters"){
                        texterror = "Error, No handphone harus lebih dari 5 digit";
                    }else{
                        texterror = data.ErrorMessage;
                    }
                    ArrayResultMessage.push({
                        VoucherNo: vvoucherno,
                        Name: vname,
                        Email: vemail,
                        NoHandphone: vhandphone,
                        TemplateEmail: vtemplateemail,
                        TemplateSMS: templatesms,
                        ErrorMessage: texterror
                    })
                    setArrayResultMessage(ArrayResultMessage)
                    setRefresh(true)
                    console.log("gagal update data."+vname)
                }
                
            })
            .catch((error) => {
                // setCountDataGagal(CountDataGagal+1)
                ArrayResultMessage.push({
                    VoucherNo: vvoucherno,
                    Name: vname,
                    Email: vemail,
                    NoHandphone: vhandphone,
                    TemplateEmail: vtemplateemail,
                    TemplateSMS: templatesms,
                    ErrorMessage: AlertMessage.failedConnect
                })
                setArrayResultMessage(ArrayResultMessage)
                setRefresh(true)
                console.log("gagal update data."+vname)
               console.log("er "+error.message);
               setLoadingSubmit(false)
            //    alert(AlertMessage.failedConnect);
            });
        }
    }

    const removeAtribut = (data)=>{
        data.forEach(dt => {
            // delete dt.VoucherNo; 
            delete dt.Expired;
            delete dt.ProductName;
            delete dt.SourceCode;
            delete dt.TemplateEmail;
            delete dt.TemplateSMS;
            // delete dt.ErrorMessage;
        });
        return data;
    }
    const addAtribut = (data)=>{
    return data.map(obj=> ({ ...obj, 
            // VoucherNo: 'tes',
            Expired: Expired,
            ProductName: ProductName,
            SourceCode: SourceCode,
            TemplateEmail: TemplateEmail,
            TemplateSMS: TemplateSMS,
        }))
   }

    const exportToExcel = (csvData, fileName) => {
        //   setDataBerhasilUpload([])
        // toggleActive('1')
        // return false;

        // console.log("ListVoucherForCsv : "+ListVoucherForCsv)
        if (FieldName != "") {
            if(FieldName =="Ketik"){
                var FilterName = "%"+FieldNameKetik+"%";
            }else{
                var FilterName = ""+FieldName+"";
            }
        } else {
            var FilterName = "";
        }

        if (FieldEmail != "") {
            if(FieldEmail =="Ketik"){
                var FilterEmail = "%"+FieldEmailKetik+"%";
            }else{
                var FilterEmail = ""+FieldEmail+"";
            }
        } else {
            var FilterEmail = "";
        } 
        
        if (FieldNoHandphone != "") {
            if(FieldNoHandphone =="Ketik"){
                var FilterNoHandphone = "%"+FieldNoHandphoneKetik+"%";
            }else{
                var FilterNoHandphone = ""+FieldNoHandphone+"";
            }
        } else {
            var FilterNoHandphone = "";
        }

        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Name":FilterName, 
            "Email":FilterEmail,
            "NoHandphone":FilterNoHandphone,
            "Page": 1,
            "RowPage": -1,
            "OrderBy": OrderBy,
            "Order": Order,
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_VOUCHER;
        var url = paths.URL_API_EXTERNAL+'viewVoucher';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        // setResultMessage("Loading ...")

        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                // setQty(data.Qty)
                // setDateTime(data.DateTime)
                // setName(data.Name)
                // setTotalRecord(data.Qty)
                // setTotalPages(data.TotalPages)
                // alert(data.Qty)
                if(data.Qty<1) {
                    // setResultMessage("Data tidak ditemukan")
                }else{
                    console.log("res "+JSON.stringify(data.Result[0]))
                    // alert(data.Result[0].ProductName)
                    // setExpired(data.Result[0].Expired)
                    // setProductName(data.Result[0].ProductName)
                    // setSourceCode(data.Result[0].SourceCode)
                    // setTemplateSMS(data.Result[0].TemplateSMS)
                    // setTemplateEmail(data.Result[0].TemplateEmail)
                    // setListVoucher((data.Result))
                    setListVoucherForCsv(data.Result)
                    // setListVoucherForCsv(renameAtribut(removeAtribut(data.Result),"VoucherId"))
                    const ws = xlsx.utils.json_to_sheet(renameAtribut(removeAtribut(data.Result),"VoucherId"));
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    
                    var trxid= generateTRXID(6);
                    
                    xlsx.writeFile(wb,fileName+"_"+trxid+".xlsx")
                    setDownloaded('1');
                }
            }else{
                if(data.ErrorMessage=="Error - ParamKey is not valid"){
                    // setResultMessage("Url anda tidak valid.")
                }else{
                    // setResultMessage("Data tidak ditemukan.")
                }

            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
        //    setResultMessage("")
        });

        
    }

    const getDataProcessData = () =>{
        var requestBody = JSON.stringify({
            "ParamKey": Param,
            "Page": 1,
            "RowPage": -1,
            "OrderBy": "",
            "Order": "DESC",
        });

        console.log(requestBody);
        var enckey = paths.ENCKEY_VIEW_VOUCHER;
        var url = paths.URL_API_EXTERNAL+'viewVoucher';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);

        setLoading(true)
        fetch( url, {
            method: "POST",
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
               
                if(data.Qty<1) {
                    // setResultMessage("Data tidak ditemukan")
                }else{
                    console.log("res "+JSON.stringify(data.Result[0]))
                   data.Result.map((item,index)=>{
                        validateProcessData(item.VoucherNo,item.Name,item.Email,item.NoHandphone,item.TemplateEmail,item.TemplateSMS);
                   })

                   setTimeout(()=>{
                        if(ArrayResultMessageProses.length<1){
            
                            var requestBody = JSON.stringify({
                                "ParamKey": Param
                            });
                    
                    
                            console.log(requestBody);
                            
                            // var enckey = paths.EncKey;
                            var enckey = paths.ENCKEY_PROSES_VOUCHER;
                            var url = paths.URL_API_EXTERNAL+'voucherProcess';
                            var requestBodyEnc = window.btoa(requestBody);
                            var Signature = md5(enckey + requestBodyEnc);
                    
                            console.log(""+Signature);
                            // setResultMessage("Loading ...")
                    
                            // setLoadingProsesData(true)
                            setLoading(true)
                            fetch( url, {
                                method: "POST",
                                // mode: 'no-cors',
                                body: requestBody,
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Signature': Signature
                                },
                            })
                            .then(response => response.json())
                            .then((data) => {
                                // var errmsg = data.errmsg;
                                console.log(JSON.stringify(data));
                                
                    
                                if(data.ErrorCode=="0"){
                                    alert("Data berhasil diproses.")
                                    loadData("reload")
                                    toggleActive('1')
                                }else{
                                    if(data.ErrorMessage=="Error, Data Already Process"){
                                        alert("Error, Data sudah pernah diproses");
                                    }else if(data.ErrorMessage=="Error - Paramkey is invalid or some data is still empty value (name,email,noHandphone,tempalteemail,templatesms) or some parameter is mising (NAME,VOUCHERNO,PARAM,URL,EXPIRED)"){
                                        alert("Error, Pastikan data sudah lengkap.");
                                        // getDataProcessData();
                                    }else{
                                        // getDataProcessData();
                                        // alert(data.ErrorMessage)
                                    }
                                    
                    
                                }
                                // setLoadingProsesData(false)
                                setLoading(false)
                            })
                            .catch((error) => {
                            console.log("er "+error.message);
                            //    setLoadingProsesData(false)
                                setLoading(false)
                            alert(AlertMessage.failedConnect)
                            //    setResultMessage("")
                            });
                        }else{
                            alert("Silahkan lengkapi data penerima voucher terlebih dahulu.")
                        }
                    },1200)
                    
                }
            }else{
                if(data.ErrorMessage=="Error - ParamKey is not valid"){
                    // setResultMessage("Url anda tidak valid.")
                }else{
                    // setResultMessage("Data tidak ditemukan.")
                }

            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
        //    setResultMessage("")
        });
    }

    const validateProcessData = (vvoucherno,vname,vemail,vhandphone,vtemplateemail,templatesms)=>{

        var validasiMessage = "";
        if(vvoucherno == ""){
            validasiMessage = validasiMessage + "Voucher ID tidak boleh kosong. ";
        }
        if(vname == ""){
            validasiMessage = validasiMessage + "Silahkan isi Nama terlebih dahulu. ";
        }
        if(vemail == ""){
            validasiMessage = validasiMessage + "Silahkan isi Email terlebih dahulu. ";
        }else if(!validEmail(vemail)){
            validasiMessage = validasiMessage + "Pastikan email anda valid. ";
        }
        if(vhandphone == ""){
            validasiMessage = validasiMessage + "Silahkan isi No. Handphone terlebih dahulu. ";
        }else if(vhandphone.length < 10 || vhandphone.length > 14){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. ";
        }else if(isNaN(vhandphone)){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }

        if(vtemplateemail == ""){
            validasiMessage = validasiMessage + "Silahkan isi template email terlebih dahulu. Klik tombol Lengkapi Data.";
        }else if (vtemplateemail.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {LINKAKTIVASI} silakan lihat panduan konten. Klik tombol Lengkapi Data.";
        }
        if(templatesms == ""){
            validasiMessage = validasiMessage + "Silahkan isi template sms terlebih dahulu. ";
        }else if (templatesms.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {LINKAKTIVASI} silakan lihat panduan konten. Klik tombol Lengkapi Data.";
        }

        if(validasiMessage!=""){
            ArrayResultMessageProses.push({
                VoucherNo: vvoucherno,
                Name: vname,
                Email: vemail,
                NoHandphone: vhandphone,
                TemplateEmail:vtemplateemail,
                TemplateSMS:templatesms,
                ErrorMessage:validasiMessage
             })
             console.log("errproses "+JSON.stringify(ArrayResultMessageProses))
             setArrayResultMessageProses(ArrayResultMessageProses)
             setRefresh(true)
            return false;
        }else{
            // return true;
        }
    }

    const processDataFinal = ()=>{

        
        if(IsProcess=="1"){
            alert("Data sudah pernah diproses.")
            return false;
        }

        if(ArrayResultMessageProses.length<1){
            getDataProcessData()
        }else{
            alert("Silahkan lengkapi data penerima voucher terlebih dahulu.")
        }

        
       

    }

    const renameAtribut = (csvData,type,text) => {
            if(type=="VoucherId"){
                if(text=="textError"){
                    return csvData.map(function(row) {
                        return { VoucherId : row.VoucherNo,Name:row.Name,Email:row.Email,NoHandphone:row.NoHandphone,ErrorMessage:row.ErrorMessage }
                     })
                }else{
                    return csvData.map(function(row) {
                        return { VoucherId : row.VoucherNo,Name:row.Name,Email:row.Email,NoHandphone:row.NoHandphone }
                     })
                }
            }else{
                return csvData.map(function(row) {
                    return { VoucherNo : row.VoucherId,Name:row.Name,Email:row.Email,NoHandphone:row.NoHandphone }
                 })
            }
    }
    
    const exportToExcelDataGagal = (csvData, fileName) => {
        setDownloaded('1');
        // setArrayResultMessage([])
        console.log(JSON.stringify(csvData))
        setListVoucherForCsv(csvData)
        // setListVoucherForCsv(renameAtribut(removeAtribut(csvData),"VoucherId","textError"))
        const ws = xlsx.utils.json_to_sheet(renameAtribut(removeAtribut(csvData),"VoucherId","textError"));
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        
        var trxid= generateTRXID(6);
        
        xlsx.writeFile(wb,fileName+"_"+trxid+".xlsx")

        
    }

    const handleSort=(OrderBy)=>{
        setOrderBy(OrderBy);
       if(Order.toUpperCase()=="ASC"){
            setOrder("DESC");
       }else{
           setOrder("ASC");
       }
    }
    const handleSortGambar=(OrderBy)=>{
        setOrderByGambar(OrderBy);
       if(OrderGambar.toUpperCase()=="ASC"){
            setOrderGambar("DESC");
       }else{
           setOrderGambar("ASC");
       }
       
    }

    const RevertToOriginal=(TemplateEmail, TemplateSMS)=>{
        setTemplateEmail(TemplateEmail)
        setTemplateSMS(TemplateSMS)
    }

    
    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}
                onClick={()=>{
                    console.log("page number: "+number);
                    setPageActive(number)
                }}>
            {number}
            </Pagination.Item>,
        );
    }

    let activeGambar = PageActiveGambar;
    let itemsGambar = [];
    for (let number = 1; number <= TotalPagesGambar; number++) {
        itemsGambar.push(
            <Pagination.Item key={number} active={number === activeGambar}
                onClick={()=>{
                    console.log("page number: "+number);
                    setPageActiveGambar(number)
                }}>
            {number}
            </Pagination.Item>,
        );
    }

    const contentStyle = { background: '#ffffff',borderRadius:15,padding:20, width:'100%'};
    const contentStyle2 = { background: '#ffffff',borderRadius:15,padding:20};
    const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
    const arrowStyle = { color: '#000' };

    // console.log("error "+JSON.stringify(ArrayResultMessage))
    return (
        <div>

        <Header noNavbar/>
        <div className="main-page">
            <div className="content-wrapper-2">
            <div className="blog-post">

            <p className="title"><FontAwesomeIcon icon={faTags}/> Voucher</p>

            <hr style={{backgroundColor: 'black'}} />

            {TotalRecord < 1 ? 
                 <div style={{width:500}}>
                     <label>{ResultMessage}</label>
                 </div>
                :
                <div style={{width:'100%'}}>
                    {/* <Card> */}
                        {/* <Card.Header><b>Voucher</b></Card.Header>
                        <Card.Body> */}
                            <p>Terima kasih voucher anda sudah tersedia.  
                            <p>Sekarang anda dapat mengirimkan hadiah voucher ini kepada keluarga/kolega/kerabat terdekat anda. </p></p>
                            {/* <div>
                                <label>Nama Pembeli &nbsp;&nbsp;&nbsp;: {Name}</label>
                                <br/>
                                <label>Email &nbsp;&nbsp;&nbsp;&nbsp;: {Email}</label>
                                <br/>
                                <label>No. Handphone &nbsp;: {NoHandphone}</label>
                                <br/>
                                <label>Jumlah Voucher &nbsp;: {Qty}</label>
                            </div> */}
                            <table>
                                <tr>
                                    <td >Nama Pembeli</td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>{Name}</td>
                                </tr>
                                <tr>
                                    <td >Email</td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>{Email.toLowerCase()}</td>
                                </tr>
                                <tr>
                                    <td >No. Handphone</td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>{NoHandphone}</td>
                                </tr>
                                <tr>
                                    <td>Jumlah Voucher</td>
                                    <td>&nbsp;:&nbsp;</td>
                                    <td>{Qty}</td>
                                </tr>
                            </table>
                            
                            <Gap height={20}/>
                            
                            <hr style={{backgroundColor: 'black'}} />
                        {ListVoucher.length >= 10 && IsProcess !="1"?
                            <div>
                                {/* <Gap height={5}/>  */}
                                <p>Untuk mempermudah, jika voucher lebih dari 10 bisa menggunakan fungsi download untuk mendapatkan file Ms. Excel.
                                 <br/>
                                 Silahkan download data voucher anda,
                                lengkapi data penerima voucher dan upload kembali, dengan klik tombol di bawah ini.</p>
                                <p style={{fontWeight:'600'}}>Petunjuk pengisian data excel:
                                    <br/>- Di dalam excel ada kolom VoucherId, Name, Email, NoHandphone.
                                    <br/>- Silahkan isi pada kolom Name, Email dan NoHandphone. 
                                    <br/>- Isi pada kolom VoucherId tidak boleh dirubah atau dihapus.
                                    <br/>- Periksa kembali data yang anda masukkan, pastikan data yang anda input sudah benar.
                                    </p>
                                <div style={{align:'center'}}>
                                    <hr style={{backgroundColor: 'black'}} />
                                        <Button style={{width:120,backgroundColor:'#006064'}} onClick={()=>exportToExcel(ListVoucher,"DataPenerimaVoucher")} >
                                            <FontAwesomeIcon icon={faDownload}/> Download
                                        </Button>
                                    {/* </CSVLink> */}
                                    <div style={{paddingRight:10}}></div>
                                    {/* <Button style={{width:120,backgroundColor:'#006064'}} onClick={()=>next()}><FontAwesomeIcon icon={faUpload}/> Upload</Button> */}
                                </div>
                                
                                {/* {showPreviewData &&
                                <div style={{backgroundColor:'#4c4949',padding:1}}>
                                <DataTable
                                    title="Preview Data"
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    data={ListVoucherForCsv}
                                    // customStyles={{borderWidth:1}}
                                />
                                </div>
                                } */}
                                {/* <Gap height={15}/>  */}
                            
                               

                                
                            </div>
                             : ""
                        }
                        
                           
                                <Gap height={10}/>
                                <Accordion defaultActiveKey={activeId} ActiveKey={activeId}>
                                    {Downloaded === '1' && IsProcess!="1"?
                                        <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toggleActive('0')} 
                                                style={{backgroundColor: '#e9ecef', cursor:'pointer'}}>
                                                    <FontAwesomeIcon icon={activeId === "0" ?faAngleUp:faAngleDown}/> Upload Data Excel
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                <Accordion defaultActiveKey="0">
                                    <p>
                                    SMS dan email akan terkirim sesuai template di bawah ini, silahkan ubah sesuai kebutuhan. 
                                    <br/>Klik "Kembali Ke Template Awal" untuk mengembalikan ke template awal.
                                    </p>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle eventKey="1" style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}>Panduan Mengisi Konten</Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>

                                        <p>Dear, {NAME}</p>
                                        Silahkan aktivasi,
                                        <table>
                                            <tr>
                                                <td>Voucher No</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>{VOUCHERNO}</td>
                                            </tr>
                                            {/* <tr>
                                                <td>Param</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>{PARAM}</td>
                                            </tr> */}
                                            <tr>
                                                <td>Expired</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>{EXPIRED} hari</td>
                                            </tr>
                                            {/* <tr>
                                                <td>URL</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>{URL}</td>
                                            </tr> */}
                                            <tr>
                                                <td>Link Aktivasi</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>{LINKAKTIVASI}</td>
                                            </tr>
                                        </table>
                                        <br/>
                                        {/* dengan klik tombol dibawah ini

                                        <div class='se-component se-image-container __se__float-none' contenteditable='false'>
                                            <a href='https://sinarmas.co.id'>
                                                <img src="http://182.23.21.61/api/v1/images"/>
                                            </a>
                                        </div> */}

                                        <p>Silahkan klik link dibawah ini untuk aktivasi</p>

                                        <a href="http://yourdomain.com/mylink.pl">YourDomain.com</a>

                                        <br/>
                                        <br/>
                                        <p>Terima kasih</p>

                                        <hr/>

                                        <table>
                                            <tr>
                                                <td>{NAME}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan Nama User</td>
                                            </tr>
                                            <tr>
                                                <td>{VOUCHERNO}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan No Voucher</td>
                                            </tr>
                                            {/* <tr>
                                                <td>{PARAM}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan Param Aktivasi</td>
                                            </tr> */}
                                            <tr>
                                                <td>{EXPIRED}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan Expired Voucher (dalam hari)</td>
                                            </tr>
                                            {/* <tr>
                                                <td>{URL}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan URL</td>
                                            </tr> */}
                                            <tr>
                                                <td>{LINKAKTIVASI}</td>
                                                <td>&nbsp;</td>
                                                <td>:</td>
                                                <td>&nbsp;</td>
                                                <td>Akan diisi otomatis dengan Link Aktivasi</td>
                                            </tr>
                                        </table>

                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Gap height={20}/>
                                    <label>Template SMS</label>
                                    <TextArea
                                        value={TemplateSMS}
                                        maxLength={160}
                                        onChange={event=>setTemplateSMS(event.target.value)}
                                    />
                                    Sisa Karakter SMS : {CharLimit - TemplateSMS.length}

                                    <Gap height={20}/>
                                    <label>Template Email</label>
                                    <div className="editordiv">
                                    <div style={{display:"flex"}}>
                                        <div style={{alignContent:"left", paddingRight:10}}>
                                            <Popup
                                                                trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Pilih Gambar </button>}
                                                                {...{ contentStyle, overlayStyle, arrowStyle}}
                                                                modal
                                                                nested
                                                                lockScroll = {true}
                                                                offsetY = {0}
                                                            >
                                                                {close => (
                                                                <div>
                                                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                                                        <div align="left">
                                                                            Pilih Gambar
                                                                        </div>
                                                                        <div align="right">
                                                                            <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div style={{overflow: 'auto', height:550}}>
                                                                    <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                                                        <Tab eventKey="home" title="Library">
                                                                            <Row xs={12} md={12} lg={8}>
                                                                                <Col>
                                                                                    <Input
                                                                                        label="Filter Nama File"
                                                                                        style={{width:300}}
                                                                                        onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Dropdown 
                                                                                        spinner={Loading2} 
                                                                                        style={{width:300}}
                                                                                        label="Kategori" 
                                                                                        onChange={(event)=>{
                                                                                            setCategoryImage(event.target.value)
                                                                                        }}>
                                                                                        <option value="">-- Pilih Category --</option>
                                                                                        {ListCategoryImage.length > 0 && ListCategoryImage.map((item,index)=>{
                                                                                            return <option value={item.IDImgCategory} selected={item.IDImgCategory==CategoryImage}>{item.CategoryDesc}</option>;
                                                                                        })}
                                                                                    </Dropdown>
                                                                                </Col>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <Row xs={12} md={12} lg={8}>
                                                                                <Col>
                                                                                    <Dropdown label = "Sorting"
                                                                                        style={{width:300}}
                                                                                        onChange = {(event) => {setOrderByGambar(event.target.value)}}>
                                                                                            <option value= "Filename" selected={"Filename"==OrderByGambar}>Nama File</option>
                                                                                            <option value= "InputDate" selected={"InputDate"==OrderByGambar}>Input Date</option>
                                                                                    </Dropdown>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Dropdown label = "Sorting Type"
                                                                                        style={{width:300}}
                                                                                        onChange = {(event) => {setOrderGambar(event.target.value)}}>
                                                                                            <option value= "ASC" selected={"ASC"==OrderGambar}>Ascending</option>
                                                                                            <option value= "DESC" selected={"DESC"==OrderGambar}>Descending</option>
                                                                                    </Dropdown>
                                                                                </Col>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <br/>
                                                                            <div>
                                                                                <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                                                            </div>
                                                                        
                                                                            <Gap height={10}/>
                                                                            <label><span style={{color:'red'}}>*</span> Klik pada gambar untuk memilih</label>
                                                                            <Gap height={10}/>

                                                                            {!loading ? (
                                                                            <div> 
                                                                            <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                                                                <Thead>
                                                                                <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                                                    <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                                                    <Th className="tabelHeader" onClick={()=>handleSortGambar("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                                                    <Th className="tabelHeader" onClick={()=>handleSortGambar("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                                                    <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                                                                </Tr>
                                                                                </Thead>
                                                                                <Tbody>
                                                                                { ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                                                            <Td className='tableWidth250' style={{alignContent:'center'}}> 
                                                                                                <Popup
                                                                                                    trigger={<img src={"data:image/png;base64,"+item.DataBase64Small} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}} />}
                                                                                                    {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                                                    modal
                                                                                                    nested
                                                                                                >
                                                                                                    {close => (
                                                                                                    <div>
                                                                                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                                                                                            <div align="left">
                                                                                                                Tampilan Gambar
                                                                                                            </div>
                                                                                                            <div align="right">
                                                                                                                <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <hr/>
                                                                                                        <div style={{overflow: 'auto', height:550}}>
                                                                                                        <img src={paths.UrlImages+item.Filename} style={{width:'100%'}}/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    )}
                                                                                                </Popup>
                                                                                            </Td>
                                                                                            <Td>{item.Filename}</Td>
                                                                                            <Td>{item.InputDate}</Td>
                                                                                            <Td style={{cursor:'pointer'}} align="left">
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        onChange = {(event) => {setLinkImage()}}
                                                                                                        style={{paddingTop:5}}
                                                                                                        />
                                                                                                    <span style={{ marginLeft: 5, paddingTop:-6}}>Tambah Link Microsite</span>
                                                                                                </div>
                                                                                                <Gap height={15}/>
                                                                                                <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                                                    PilihImage(item.Filename)
                                                                                                    close();
                                                                                                }
                                                                                                }>Pilih</Button>
                                                                                            </Td>
                                                                                        </Tr>;
                                                                                }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                                                            
                                                                                </Tbody>
                                                                            </Table>
                                                                            <Gap height={10}/>
                                                                            {TotalRecordGambar > 0 &&
                                                                            <div style={{display:'flex'}}>
                                                                                <span>Page : &nbsp;</span>
                                                                                <Pagination size="sm">{itemsGambar}</Pagination>
                                                                            </div>
                                                                            }
                                                                            <Gap height={10}/>
                                                                            </div>
                                                                            ) : (
                                                                            <div class="divLoader">
                                                                                <ReactBootStrap.Spinner animation="border" />
                                                                            </div>)}
                                                                        </Tab>
                                                                        </ReactBootStrap.Tabs>
                                                                    
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Popup>
                                                        </div>
                                                        <div style={{alignContent:'right'}}>
                                                            <Popup
                                                                trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Tampilkan Email </button>}
                                                                {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                modal
                                                                nested
                                                            >
                                                                {close => (
                                                                <div>
                                                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                                                    <div align="left">
                                                                        Tampilan Email
                                                                        <Gap height={10}/>
                                                                        <div style={{display:'flex'}}>
                                                                            <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>WebsiteVersion()}>Versi Website</button>
                                                                            <Gap width={10} />
                                                                            <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>MobileVersion()}>Versi Mobile</button>
                                                                        </div>
                                                                    </div>
                                                                        <div align="right">
                                                                            <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div style={{overflow: 'auto', height:550, width:WitdhDiv}}>
                                                                        <Markup content= {TemplateEmail.replace("{NAME}", "Anton").replace('{URL}','http://localhost:3000/Asuransi-PA').replace('{PARAM}','MTYyNDU1MTExMzY1NjM1Mw==zgnwsFYKb2KuNuvJzV0NebaLIZ037O').replace('{VOUCHERNO}','1624551113656338').replace('{EXPIRED}','30')} />
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Popup>
                                                            {/* <div style={{paddingRight:20}}></div>
                                                            <Popup
                                                                trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:10,color:'#ffffff'}}> Send Test Email </button>}
                                                                {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                modal
                                                                nested
                                                            >
                                                                {close => (
                                                                <div>
                                                                    <button style={{backgroundColor:'#FF3131',color:'#ffffff',borderRadius:5}} onClick={close}>
                                                                    &times;
                                                                    </button>
                                                                    <hr/>
                                                                    <div>
                                                                        <Input 
                                                                            required
                                                                            label="Nama"
                                                                            onChange={event=>setFieldNama(event.target.value)}
                                                                        />
                                                                        <Input 
                                                                        required
                                                                            label="Alamat Email"
                                                                            onChange={event=>setFieldEmail(event.target.value)}
                                                                        />
                                                                        <Input 
                                                                        required
                                                                            label="Nomor Handphone"
                                                                            type="number"
                                                                            onChange={event=>setFieldSMS(event.target.value)}
                                                                        />
                                                                        <Input 
                                                                            label="Pesan Text"
                                                                            onChange={event=>setFieldText(event.target.value)}
                                                                        />
                                                                    </div>
                                                                    <Gap height={10}/>
                                                                    <div>
                                                                        <Button spinner={loading} title="Send" onClick={()=>sendEmailSMS("email")}/>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Popup> */}
                                                        </div>
                                                    </div>
                                                    <Editor
                                                        // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                                                        // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                        // initialValue="<p>This is the initial content of the editor.</p>"
                                                        onSetContent={()=>{
                                                            console.log("onSetContent")
                                                        }}
                                                        onEditorChange={(newText)=>{
                                                            console.log("onEditor")
                                                            setTemplateEmail(newText)
                                                        }}
                                                        onChange={()=>{
                                                            console.log("onChange")
                                                        }}
                                                        value={TemplateEmail}
                                                        init={{
                                                        height: 500,
                                                        menubar: true,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                    />
                                                    </div>

                                                    <Gap height={20}/>
                                                    <Button 
                                                        style={{width: '100%', maxWidth:200, height:40, fontSize:11}} 
                                                        title="Kembali ke template awal" 
                                                        onClick={()=>RevertToOriginal(TemplateEmailOld, TemplateSMSOld)}
                                                    />
                                                    <Gap height={20}/>
                                                    <b>Pilih File</b>
                                                    <Input
                                                        type="file"
                                                        style={{maxWidth:300}}
                                                        accept=".csv,.xlsx,.xls"
                                                        value={null}
                                                        onClick={(e)=>{
                                                            e.target.value=null;
                                                        }}
                                                        onChange={handleFileUpload}
                                                    />
                                                    <Gap height={15}/>
                                                    {showPreviewData ?
                                                    <Button spinner={LoadingSubmit} style={{width:175,backgroundColor:'#145A32'}} onClick={()=>upload()} >
                                                        <FontAwesomeIcon icon={faUpload}/> Upload Data</Button> : ""}
                                                        
                                                        {LoadingSubmit &&
                                                            <div>
                                                                <Gap height={15}/>
                                                                <ReactBootStrap.Spinner animation="border" />
                                                            </div>
                                                        }
                                                        <Gap height={15}/>
                                                        {showStatistikUpload &&
                                                        <div>
                                                            <table>
                                                                <tr>
                                                                    <td >Jumlah data yang diupload</td>
                                                                    <td>&nbsp;:&nbsp;</td>
                                                                    <td>{TotalDataUpload}</td>
                                                                </tr>
                                                                <tr style={{color:'#13d213'}}>
                                                                    <td >Data yang berhasil</td>
                                                                    <td>&nbsp;:&nbsp;</td>
                                                                    <td>{DataBerhasilUpload.length}</td>
                                                                </tr>
                                                                <tr style={{color:'#f53307'}}>
                                                                    <td >Data yang gagal</td>
                                                                    <td>&nbsp;:&nbsp;</td>
                                                                    <td>{ArrayResultMessage.length}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        }
                                                    
                                                        {ArrayResultMessage.length > 0 &&
                                                            <div>
                                                                <Gap height={15}/> 
                                                                <label style={{fontWeight:500}}>Data yang tidak dapat diproses.</label>
                                                                <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                                                                    <Thead>
                                                                        <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                                            <Th className="tabelHeader" ><LabelTH>ID Voucher </LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Nama </LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Email</LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>No. Handphone</LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Pesan Error</LabelTH></Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                    {ArrayResultMessage.length > 0 && ArrayResultMessage.map((item,index)=>{
                                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                                                                    <Td  className="tableWidth150">{(item.VoucherNo).substring(0, 10)}</Td>
                                                                                    <Td>{item.Name}</Td>
                                                                                    <Td>{item.Email}</Td>
                                                                                    <Td className="tableWidth150">{item.NoHandphone}</Td>
                                                                                    <Td className="tableWidth300">{item.ErrorMessage}</Td>
                                                                                </Tr>;
                                                                    })}
                                                                </Tbody>
                                                                </Table>
                                                                <div style={{align:'center'}}>
                                                                    <hr style={{backgroundColor: 'black'}} />
                                                                        <p>Silahkan lengkapi/perbaiki data yang gagal diproses, lalu upload lagi data yang sudah anda perbaiki.</p>
                                                                        <Button style={{width:175,backgroundColor:'#006064'}} 
                                                                            onClick={()=>exportToExcelDataGagal(ArrayResultMessage,"DataPenerimaVoucher")}
                                                                        >
                                                                            <FontAwesomeIcon icon={faDownload}/> Download Data Gagal
                                                                        </Button>
                                                                    <div style={{paddingRight:10}}></div>
                                                                </div>
                                                            </div>
                                                            }
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                : null
                            }
                                
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => {
                                        toggleActive('1')
                                        // alert("as")
                                    }
                                    } style={{backgroundColor: '#e9ecef', cursor:'pointer'}}>
                                        <FontAwesomeIcon icon={activeId === "1" ?faAngleUp : faAngleDown}/> List Penerima Voucher
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                    {showTabelMain &&
                                        <div>
                                            <Gap height={10}/> 
                                            <p>Berikut data penerima voucher: </p>
                                            {showFilter ?
                                           <div>
                                            <Row xs={12} md={12} lg={8}>
                                                <Col style={{marginTop:10}}>
                                                    <Dropdown label = "Filter Nama"
                                                        style={{width:300}}
                                                        value={FieldName}
                                                        onChange = {(event) => {setFieldName(event.target.value)}}>
                                                            <option value= "" selected={""==FieldName}>--Pilih--</option>
                                                            <option value= "ISNOTNULL" selected={"ISNOTNULL"==FieldName}>Sudah Diisi</option>
                                                            <option value= "ISNULL" selected={"ISNULL"==FieldName}>Belum Diisi</option>
                                                            <option value= "Ketik" selected={"Ketik"==FieldName}>Ketik ...</option>
                                                    </Dropdown>
                                                    {FieldName=="Ketik" && 
                                                    <Input
                                                        label=""
                                                        style={{width:300}}
                                                        value={FieldNameKetik}
                                                        onChange = {(event) => {setFieldNameKetik(event.target.value)}}
                                                    />
                                                    }
                                                </Col>
                                                <Col style={{marginTop:10}}>
                                                    <Dropdown label = "Filter Email"
                                                        style={{width:300}}
                                                        value={FieldEmail}
                                                        onChange = {(event) => {setFieldEmail(event.target.value)}}>
                                                            <option value= "" selected={""==FieldEmail}>--Pilih--</option>
                                                            <option value= "ISNOTNULL" selected={"ISNOTNULL"==FieldEmail}>Sudah Diisi</option>
                                                            <option value= "ISNULL" selected={"ISNULL"==FieldEmail}>Belum Diisi</option>
                                                            <option value= "Ketik" selected={"Ketik"==FieldEmail}>Ketik ...</option>
                                                    </Dropdown>
                                                    {FieldEmail=="Ketik" && 
                                                    <Input
                                                        label=""
                                                        style={{width:300}}
                                                        value={FieldEmailKetik}
                                                        onChange = {(event) => {setFieldEmailKetik(event.target.value)}}
                                                    />
                                                    }
                                                </Col>
                                                <Col style={{marginTop:10}}>
                                                    <Dropdown label = "Filter No Handphone"
                                                        style={{width:300}}
                                                        value={FieldNoHandphone}
                                                        onChange = {(event) => {setFieldNoHandphone(event.target.value)}}>
                                                            <option value= "" selected={""==FieldNoHandphone}>--Pilih--</option>
                                                            <option value= "ISNOTNULL" selected={"ISNOTNULL"==FieldNoHandphone}>Sudah Diisi</option>
                                                            <option value= "ISNULL" selected={"ISNULL"==FieldNoHandphone}>Belum Diisi</option>
                                                            <option value= "Ketik" selected={"Ketik"==FieldNoHandphone}>Ketik ...</option>
                                                    </Dropdown>
                                                    {FieldNoHandphone=="Ketik" && 
                                                    <Input
                                                        label=""
                                                        style={{width:300}}
                                                        value={FieldNoHandphoneKetik}
                                                        onChange = {(event) => {setFieldNoHandphoneKetik(event.target.value)}}
                                                    />
                                                    }
                                                </Col>
                                            </Row>
                                            <Row xs={12} md={12} lg={8}>
                                                <Col style={{marginTop:10}}>
                                                    <Dropdown label = "Sorting"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                            <option value= "Nama" selected={"Nama"==OrderBy}>Nama File</option>
                                                            <option value= "Email" selected={"Email"==OrderBy}>Email</option>
                                                            <option value= "NoHandphone" selected={"NoHandphone"==OrderBy}>No Handphone</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col style={{marginTop:10}}>
                                                    <Dropdown label = "Sorting Type"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrder(event.target.value)}}>
                                                            <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                            <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col style={{marginTop:10}}></Col>
                                            </Row>
                                            <br/>
                                            <div style={{display:'flex'}}>
                                                <Button style={{width:120,backgroundColor:"#7F8C8D",fontSize:13}}
                                                         onClick={()=>{
                                                            setShowFilter(false)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faFilter}/> Hide Filter
                                                    </Button>
                                                    <Gap width={10} />
                                                    <Button onClick={()=>loadData()} style={{width:120,backgroundColor:"#7F8C8D",fontSize:13}}>
                                                        <FontAwesomeIcon icon={faFilter}/> Terapkan
                                                    </Button>
                                                <Gap width={10} />
                                            </div>
                                            </div>
                                            :
                                            <div style={{display:'flex'}}>
                                                <Button style={{width:120,backgroundColor:"#7F8C8D",fontSize:13}}
                                                    onClick={()=>{
                                                        setShowFilter(true)
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faFilter}/> Filter Data
                                                </Button>
                                                <Gap width={10} />
                                            </div>
                                            }
                                             {/* <Gap height={20}/> */}
                                             <hr style={{backgroundColor: 'black'}} />
                                            {!loading ? (
                                                <div>
                                                <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                                                <Thead>
                                                <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("VoucherNo")}><LabelTH>ID Voucher </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("Name")}><LabelTH>Nama </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("Email")}><LabelTH>Email</LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("NoHandphone")}><LabelTH>No. Handphone</LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>null}><LabelTH>Status Proses</LabelTH></Th>
                                                    {IsProcess != "1" && 
                                                    <Th width="30">Action</Th>
                                                    }
                                                </Tr>
                                                </Thead>
                                                <Tbody>
                                                {ListVoucher.length > 0 ? ListVoucher.map((item,index)=>{
                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                                            <Td className="tableWidth150">{item.VoucherNo && (item.VoucherNo).length != 0 && (item.VoucherNo).substring(0, 10)}</Td>
                                                            <Td>{item.Name && (item.Name).length != 0 && (item.Name)}</Td>
                                                            <Td>{item.Email && (item.Email).length != 0 && (item.Email)}</Td>
                                                            <Td className="tableWidth150">{item.NoHandphone && (item.NoHandphone).length != 0 && (item.NoHandphone)}</Td>
                                                            <Td className="tableWidth150">{IsProcess=="1"?"Sudah diproses":"Belum diproses"}</Td>
                                                            {IsProcess != "1" && 
                                                            <Td>
                                                                <div style={{display:'flex'}}>
                                                                <Button style={{backgroundColor:"#ca9d1f",width:120}} 
                                                                        onClick={()=>{
                                                                            history.push('/edit-voucher?param=' + Param +'&voucherid=' + item.VoucherNo)
                                                                            // history.push('/edit-paket',{
                                                                            //     ProductName:item.ProductName,
                                                                            //     SourceCode:item.SourceCode,
                                                                            //     URL_API:item.URL_API,
                                                                            //     ExpiredDay:item.ExpiredDay,
                                                                            //     UserID:location.state.UserID,
                                                                            //     ParamKey:location.state.ParamKey
                                                                            // })
                                                                        }}
                                                                    ><FontAwesomeIcon icon={faEdit}/> Lengkapi Data</Button>
                                                                    <Gap width={10} />
                                                                </div>
                                                            </Td>
                                                            }
                                                        </Tr>;
                                                }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                                
                                            
                                                </Tbody>
                                            </Table>
                                            <Gap height={5}/>
                                            <hr style={{backgroundColor: 'black'}} />
                                            {TotalRecord > 0 &&
                                            <div style={{display:'flex'}}>
                                                <span>Page : &nbsp;</span>
                                                <Pagination size="sm">{items}</Pagination>
                                            </div>
                                            }
                                            <div>
                                                Total Data : {TotalRecord}
                                            </div>
                                            </div>
                                            ) : (
                                            <div class="divLoader">
                                                <ReactBootStrap.Spinner animation="border" />
                                            </div>)}

                                            
                                        </div>
                                    }
                                    
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <div>
                                        {IsProcess != "1" &&
                                            <div style={{textAlign:'left'}}>
                                                {ArrayResultMessageProses.length > 0 &&
                                                            <div>
                                                                <Gap height={15}/> 
                                                                <label style={{fontWeight:500}}>Data yang tidak dapat diproses.</label>
                                                                <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                                                                    <Thead>
                                                                        <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                                            <Th className="tabelHeader" ><LabelTH>ID Voucher </LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Nama </LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Email</LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>No. Handphone</LabelTH></Th>
                                                                            <Th className="tabelHeader" ><LabelTH>Pesan Error</LabelTH></Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                    {ArrayResultMessageProses.length > 0 && ArrayResultMessageProses.map((item,index)=>{
                                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                                                                    <Td  className="tableWidth150">{(item.VoucherNo).substring(0, 10)}</Td>
                                                                                    <Td>{item.Name}</Td>
                                                                                    <Td>{item.Email}</Td>
                                                                                    <Td className="tableWidth150">{item.NoHandphone}</Td>
                                                                                    <Td className="tableWidth300">{item.ErrorMessage}</Td>
                                                                                </Tr>;
                                                                    })}
                                                                </Tbody>
                                                                </Table>
                                                                <div style={{align:'center'}}>
                                                                    <hr style={{backgroundColor: 'black'}} />
                                                                        <p>Silahkan lengkapi/perbaiki data yang gagal diproses, lalu upload lagi data yang sudah anda perbaiki.</p>
                                                                        <Button style={{width:175,backgroundColor:'#006064'}} 
                                                                            onClick={()=>exportToExcelDataGagal(ArrayResultMessageProses,"DataPenerimaVoucher")}
                                                                        >
                                                                            <FontAwesomeIcon icon={faDownload}/> Download Data Gagal
                                                                        </Button>
                                                                    <div style={{paddingRight:10}}></div>
                                                                </div>
                                                            </div>
                                                            }
                                                <Gap height={20}/>
                                                <Button 
                                                spinner={loading}
                                                style={{maxWidth:200}}
                                                    onClick={()=>{
                                                        processDataFinal()
                                                    }}
                                                >Proses Data</Button>
                                            </div>
                                            }
                                    </div>
                            <Gap height={15}/> 

                </div>
                }
                </div>
            </div>
        </div>
    </div>
    )
}

export default Voucher