import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button,Dropdown,Gap,Input, Sort } from '../../components'
import "../../App.css"
import LeftMenu from '../../components/molecules/LeftMenu'
import md5 from 'md5'
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../redux';
import { AlertMessage, paths } from '../../utils'
import { useCookies } from 'react-cookie';
import Pagination from 'react-bootstrap/Pagination'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as ReactBootStrap from 'react-bootstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../components/molecules/LabelTH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFilter, faTrash, faEdit, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { historyConfig } from '../../utils/functions'

const IPWhitelist = () => {
    const history = useHistory(historyConfig);
    const [ListPaket,setListPaket] = useState([])
    const [loading,setLoading] = useState(false)
    const [Filter,setFilter] = useState("")
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [FieldFilter,setFieldFilter] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const {formPaket}=useSelector(state=>state.PaketReducer);
    // location.state.postContent
    const dispatch = useDispatch();
    const [FieldFilterSourceIP,setFieldFilterSourceIP] = useState("")
    const [FieldFilterSourceCode,setFieldFilterSourceCode] = useState("")
    const [FieldFilterID,setFieldFilterID] = useState("")
    const [FieldFilterEncryptKey,setFieldFilterEncryptKey] = useState("")
    const [PageNumber,setPageNumber] = useState(1)
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(5)
    const [ResultMessage,setResultMessage] = useState("")

    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    
    
    useEffect(()=>{
        
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
         cookies.CookieUserID==null || cookies.CookieUserID==""){
            //alert("Session anda telah habis. Silahkan login kembali.");
            
            // alert("tes");
            logout();
            return false;
        }else{
            dispatch(setForm("ParamKey",cookies.CookieParamKey))
            dispatch(setForm("UserID",cookies.CookieUserID))
            dispatch(setForm("PageActive","Whitelist"))


            loadData();
        }

    },[OrderBy,Order,PageActive])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const loadData = ()=>{


        if (FieldFilterSourceIP != "") {
            var FilterSourceIP = "%"+FieldFilterSourceIP+"%";
        } else {
            var FilterSourceIP = "";
        } 

        if (FieldFilterSourceCode != "") {
            var FilterSourceCode = "%"+FieldFilterSourceCode+"%";
        } else {
            var FilterSourceCode = "";
        }

        if (FieldFilterID != "") {
            var FilterID = FieldFilterID;
        } else {
            var FilterID = "";
        }

        if (FieldFilterEncryptKey != "") {
            var FilterEncryptKey = "%"+FieldFilterEncryptKey+"%";
        } else {
            var FilterEncryptKey = "";
        }
        
        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "";
        }


        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method": "SELECT",
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "IDIPList":FilterID,
            "Source_IP":FilterSourceIP,
            "SourceCode":FilterSourceCode,
            "EncryptKeyVal":FilterEncryptKey,
            "IsActive":FilterStatus
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addIPWhiteList';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setListPaket(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout();
                }
            }
            setLoading(true)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(true)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const clickDelete = (Source_IP,IDIPList)=>{
        var r = window.confirm("Apakah anda yakin menghapus IP Whitelist "+Source_IP+"?");
        if (r == false) {
            console.log("canceled")
        } else {
            console.log("oke");
            var requestBody = JSON.stringify({
                "UserID": cookies.CookieUserID,
                "ParamKey": cookies.CookieParamKey,
                "Method":"DELETE",
                "IDIPList":IDIPList
            });

            
    
            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addIPWhiteList';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
           
            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                
    
                if(data.ErrorCode=="0"){
                    alert("Berhasil delete");
                    loadData();
                }else{
                    
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout();
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
                setLoading(true)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(true)
               alert(AlertMessage.failedConnect)
            });
        }
    
    
    }

    const handleSort=(OrderBy)=>{
         setOrderBy(OrderBy);
        if(Order.toUpperCase()=="ASC"){
             setOrder("DESC");
        }else{
            setOrder("ASC");
        }
        // alert(OrderBy)
        // loadData();
    }

    
    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
                <div className="blog-post">
                    <p className="title"><FontAwesomeIcon icon={faNetworkWired}/> List IP Whitelist</p>

                    <hr 
                        style={{
                            backgroundColor: 'black'
                        }} 
                    />

                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Input
                                label="Filter Nama Source IP"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterSourceIP(event.target.value)}}
                            />
                        </Col>
                        <Col>
                            <Input
                                label="Filter Nama Source Code"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterSourceCode(event.target.value)}}
                            />
                        </Col>
                        <Col>
                            <Input
                                label="Filter EncryptKey"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterEncryptKey(event.target.value)}}
                            />
                        </Col>
                    </Row>
                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Dropdown label = "Filter Status Aktif"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterStatus(event.target.value)}}>
                                    <option value="">-- Pilih Status --</option>
                                    <option value= "1">Aktif</option>
                                    <option value= "0">Tidak Aktif</option>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Dropdown label = "Sorting"
                                style={{width:300}}
                                onChange = {(event) => {setOrderBy(event.target.value)}}>
                                    <option value= "Source_IP" >Source IP</option>
                                    <option value= "SourceCode" >Source Code</option>
                                    <option value= "EncryptKeyVal">Encrypt Key</option>
                                    <option value= "InputDate" selected>Tgl Input</option>
                                    <option value= "IsActive">Status Aktif</option>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Dropdown label = "Sorting Type"
                                style={{width:300}}
                                onChange = {(event) => {setOrder(event.target.value)}}>
                                    <option value= "ASC">Ascending</option>
                                    <option value= "DESC" selected>Descending</option>
                            </Dropdown>
                        </Col>
                    </Row>
                    <br/>
                    <div style={{display:'flex'}}>
                    <Button style={{width:120,backgroundColor:"#7F8C8D",fontSize:13}} onClick={()=>loadData()}><FontAwesomeIcon icon={faFilter}/> Filter Data</Button>
                        <Gap width={10}/>
                        <Button style={{width:120,fontSize:13}} onClick={()=>history.push('/input-WhiteList-microsite')}><FontAwesomeIcon icon={faPlusCircle}/> Tambah Baru</Button>
                    </div>
                    
                    <hr 
                        style={{
                            backgroundColor: 'black'
                        }} 
                    />

                    {loading ? (
                        <div>
                        <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                        <Thead>
                        <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                            {/* <Th className="tabelHeader" onClick={()=>handleSort("IDIPList")}>ID IP List</Th> */}
                            <Th className="tabelHeader" onClick={()=>handleSort("Source_IP")}>Source IP</Th>
                            <Th className="tabelHeader" onClick={()=>handleSort("SourceCode")}>Source Code</Th>
                            <Th className="tabelHeader" onClick={()=>handleSort("EncryptKeyVal")}>EncryptKey</Th>
                            <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}>Tgl Input</Th>
                            <Th className="tabelHeader" onClick={()=>handleSort("IsActive")}>Status Aktif</Th>
                            <Th width="30">Action</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        { ListPaket.length > 0 ? ListPaket.length > 0 && ListPaket.map((item,index)=>{
                                return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                     {/* <Td>{item.IDIPList}</Td> */}
                                    <Td>{item.Source_IP}</Td>
                                    <Td>{item.SourceCode}</Td>
                                    <Td>{item.EncryptKeyVal}</Td>
                                    <Td>{item.InputDate}</Td>
                                    <Td>{item.IsActive == "1"?"Aktif":"Tidak Aktif"}</Td>
                                    <Td>
                                        <div style={{display:"flex"}}>
                                        <Button style={{backgroundColor:"#ca9d1f",width:80}} 
                                            onClick={()=>{
                                                history.push('/edit-whitelist-microsite')
                                                // history.push('/edit-paket',{
                                                //     ProductName:item.ProductName,
                                                //     SourceCode:item.SourceCode,
                                                //     URL_API:item.URL_API,
                                                //     ExpiredDay:item.ExpiredDay,
                                                //     UserID:location.state.UserID,
                                                //     ParamKey:location.state.ParamKey
                                                // })
                                                dispatch(setForm("DetailWhitelistMicrosite",item))

                                            }}
                                        ><FontAwesomeIcon icon={faEdit}/> Edit</Button>
                                        <Gap width={10}/>
                                        <Button style={{backgroundColor:"#e05858",width:80}} 
                                            onClick={()=>clickDelete(item.Source_IP,item.IDIPList)}
                                        ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
                                        </div>
                                    </Td>
                                </Tr>;
                           }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                        
                      
                        </Tbody>
                    </Table>
                    <hr style={{backgroundColor: 'black'}} />
                    {TotalRecord > 0 &&
                    <div style={{display:'flex'}}>
                        <span>Page : &nbsp;</span>
                        <Pagination size="sm">{items}</Pagination>
                    </div>
                    }
                    <div>
                        Total Data : {TotalRecord}
                    </div>
                    </div>
                    ) : (
                    <div class="divLoader">
                        <ReactBootStrap.Spinner animation="border" />
                    </div>)}

                </div>
            </div>

        </div>
    )
}

export default IPWhitelist

