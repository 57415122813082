export const paths ={
    URL_API:process.env.REACT_APP_URL_API,
    URL_API_EXTERNAL:process.env.REACT_APP_URL_API_EXTERNAL,
    EncKey:process.env.REACT_APP_ENCKEY,
    ENCKEY_VOUCHER_VALIDATION:process.env.REACT_APP_ENCKEY_VOUCHER_VALIDATION,
    ENCKEY_VOUCHER_ACTIVATION:process.env.REACT_APP_ENCKEY_VOUCHER_ACTIVATION,
    ENCKEY_VIEW_VOUCHER:process.env.REACT_APP_ENCKEY_VIEW_VOUCHER,
    ENCKEY_UPDATE_VOUCHER:process.env.REACT_APP_ENCKEY_UPDATE_VOUCHER,
    ENCKEY_VIEW_TEMPLATE_WEB:process.env.REACT_APP_ENCKEY_VIEW_TEMPLATE_WEB,
    ENCKEY_VIEW_IMAGE:process.env.REACT_APP_ENCKEY_VIEW_IMAGE,
    ENCKEY_PROSES_VOUCHER:process.env.REACT_APP_ENCKEY_PROSES_VOUCHER,
    UrlImages:process.env.REACT_APP_URL_IMAGES
}
