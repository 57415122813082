import React, { useState, useEffect,useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Dropdown, Gap, Input, Link, TextArea, Upload } from '../../../components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LeftMenu from '../../../components/molecules/LeftMenu';
// import { Editor } from "react-draft-wysiwyg";
import { Editor } from '@tinymce/tinymce-react';
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import md5 from 'md5';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import Popup from 'reactjs-popup';
import { Markup } from 'interweave';
import SunEditor,{buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import * as ReactBootStrap from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import gambar1 from '../../../assets/image/guide/1.jpeg';
import gambar2 from '../../../assets/image/guide/2.jpeg';
import { generateTRXID, getBase64, validEmail } from '../../../utils/functions';
import gambar3 from '../../../assets/image/guide/3.jpeg';
import gambar4 from '../../../assets/image/guide/4.jpeg';
import { CardDeck } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../../components/molecules/LabelTH'
import { Tab } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditTemplateSmsEmail = () => {
    const history = useHistory();
    // const location = useLocation();
    const [loading,setLoading] = useState(false)
    const [loading2,setLoading2] = useState(false)
    const [loading3,setLoading3] = useState(false)
    const [loading4,setLoading4] = useState(false)

    const [NamaTemplate,setNamaTemplate] = useState("")
    const [IDEmailSMS,setIDEmailSms] = useState("");
    const [IDWeb,setIDWeb] = useState("");
    const [StatusAktif,setStatusAktif] = useState("")
    const [IsiSMS,setIsiSMS] = useState("")
    const [IsiEmail,setIsiEmail] = useState("")
    const [DefaultContent,setDefaultContent] = useState("")
    const [PeriodeAwal,setPeriodeAwal] = useState(new Date())
    const [PeriodeAkhir,setPeriodeAkhir] = useState(new Date())

    const [NamaPaketOld,setNamaPaketOld] =useState("")
    const [NamaPaket,setNamaPaket] =useState("")
    const [NamaSourceOld,setNamaSourceOld] = useState("")
    const [NamaSource,setNamaSource] = useState("")
    const [Source,setSource] = useState("")

    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const {form}=useSelector(state=>state.PaketReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [TemplateEmailSMS,setTemplateEmailSms] = useState("")

    const [CharLimit,setCharLimit] = useState(160)

    const [FieldEmail,setFieldEmail] = useState("")
    const [FieldSMS,setFieldSMS] = useState("")
    const [FieldNama,setFieldNama] = useState("")
    const [FieldText,setFieldText] = useState("")

    const [ListSource,setListSource] = useState([])
    const [ListPaket,setListPaket] = useState([])
    const [ListTemplate,setListTemplate] = useState([])

    const [ListTemplateMicrosite,SetListTemplateMicrosite] = useState([])
    const [IsiTemplateMicrosite,setIsiTemplateMicrosite] = useState([])
    const [URLTemplateMicrosite,setURLTemplateMicrosite] = useState("")
    
    const [PageNumber,setPageNumber] = useState(1)
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(5)
    const [ResultMessage,setResultMessage] = useState("")
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [SearchGambar,setSearchGambar] = useState("")
    const [FileName,setFileName] = useState("")
    const [ListGambar,setListGambar] = useState([])
    const [FileNameUnggah,setFileNameUnggah] = useState("")
    const [GambarUnggah,setGambarUnggah] = useState(null)
    const [IsLoading2,setIsLoading2] = useState(false)
    const [FieldNamaFile,setFieldNamaFile] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [LinkImage,setLinkImage] = useState("")
    const [UrlLink,setUrlLink] = useState("")
    const [TextDisplay,setTextDisplay] = useState("")
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };
    const dispatch = useDispatch();

    const NAME = "{NAME}"
    const VOUCHERNO = "{VOUCHERNO}"
    const PARAM = "{PARAM}"
    const TEXTMESSAGE = "{TEXTMESSAGE}"
    const URL = "{URL}"

    useEffect(()=>{
        console.log("form ",form);
        console.log("cooparam ",cookies.CookieParamKey);
        console.log("CookieUserID ",cookies.CookieUserID);
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
            cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else if(form.ParamKey==null || form.ParamKey=="" ||
            form.UserID==null || form.UserID==""){
            history.push('/list-template-sms-email');
            return false;
        }else{
            setUserID(form.UserID)
            setParamKey(form.ParamKey)
            setNamaPaket(form.DetailSMSEmail.ProductName)
            setNamaPaketOld(form.DetailSMSEmail.ProductName)
            setNamaSource(form.DetailSMSEmail.SourceCode)
            setNamaSourceOld(form.DetailSMSEmail.SourceCode)
            setSource(form.DetailSMSEmail.Source)
            setNamaTemplate(form.DetailSMSEmail.Title)
            setIDWeb(form.DetailSMSEmail.IDWeb)
            setIDEmailSms(form.DetailSMSEmail.IDEmailSMS)
            setStatusAktif(form.DetailSMSEmail.IsActive)
            setIsiEmail(form.DetailSMSEmail.TemplateEmail)
            setDefaultContent(form.DetailSMSEmail.TemplateEmail)
            setIsiSMS(form.DetailSMSEmail.TemplateSMS)
            setLoading2(true)
            
            onLoadSource()
            onLoadPaket()
            onLoadMicrosite()
            onLoadGambar()

            var requestBody = JSON.stringify({
                // "UserID": form.UserID,
                // "ParamKey": form.ParamKey,
                // "Method": "SELECT",
                // "Page": 1,
                // "RowPage": 15,
                // "OrderBy": "InputDate",
                // "Order": "DESC",
                // "Title": "",
                // "Source": "",
                // "TemplateEmailSMS":"",
                // "IDEmailSMS":"",
                // "IDWeb": "",
                // "IsActive": "1",

                "UserID": cookies.CookieUserID,
                "ParamKey": cookies.CookieParamKey,
                "Category":"SourceCode",
                "Page":1,
                "RowPage":15,
                "OrderBy":"",
                "Order":"DESC",
                "ProductName":"",
                "SourceCode":""
            });
    
            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'sourceCodeProduct';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log("list templ:"+JSON.stringify(data));
                
                if(data.ErrorCode=="0"){
                    setListSource(data.Result);
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }
                }
                setLoading2(false)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading2(false)
               alert(AlertMessage.failedConnect)
            });   
        }
    },[OrderBy,Order,PageActive])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const onLoadSource=(NamaSourceOld)=>{

        setLoading3(true)
        var requestBody = JSON.stringify({
            // "UserID": cookies.CookieUserID,
            // "ParamKey": cookies.CookieParamKey,
            // "Method": "SELECT",
            // "Page": 1,
            // "RowPage": 15,
            // "OrderBy": "InputDate",
            // "Order": "DESC",
            // "ProductName": "",
            // "SourceCode": NamaSource,
            // "Title" : "",
            // "URL": "",
            // "TemplateWeb": "",
            // "IsActive": "1"

            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Category":"ProductName",
            "Page":1,
            "RowPage":15,
            "OrderBy":"",
            "Order":"DESC",
            "ProductName":"",
            "SourceCode":NamaSource
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'sourceCodeProduct';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);

        console.log(""+Signature);
    
        fetch( url, {
            method: "POST",
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            console.log(JSON.stringify(data));
            
            if(data.ErrorCode=="0"){
                setListPaket(data.Result);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading3(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading3(false)
           alert(AlertMessage.failedConnect);
        });
    }

    const onLoadPaket=(NamaPaketOld)=>{

        setLoading4(true)
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method": "SELECT",
            "Page": 1,
            "RowPage": 15,
            "OrderBy": "InputDate",
            "Order": "DESC",
            "ProductName": NamaPaket,
            "SourceCode": NamaSource,
            "Title" : "",
            "URL": "",
            "TemplateWeb": "",
            "IsActive": "1"

            // "UserID": cookies.CookieUserID,
            // "ParamKey": cookies.CookieParamKey,
            // "Method":"SELECT",
            // "Page":1,
            // "RowPage":15,
            // "OrderBy":"",
            // "Order":"DESC",
            // "ProductName":NamaPaket,
            // "SourceCode":NamaSource,
            // "Title":"",
            // "URL": "",
            // "TemplateWeb":"",
            // "IDWeb":"",
            // "IsActive":"1"
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'templateEmailSMS';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);

        console.log(""+Signature);
    
        fetch( url, {
            method: "POST",
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            console.log(JSON.stringify(data));
            
            if(data.ErrorCode=="0"){
                setListTemplate(data.Result);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading4(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading4(false)
           alert(AlertMessage.failedConnect);
        });
    }

    const onLoadGambar = () => {

        if (FieldNamaFile != "") {
            var NamaFile = "%"+FieldNamaFile+"%";
        } else {
            var NamaFile = "";
        } 

        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "1";
        }
        
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method":"SELECT",
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "Filename":NamaFile,
            // "DataBase64":"-",
            // "IDImage":"-",
            "IsActive": FilterStatus
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImages';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListGambar(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const simpanData = () => {
        // alert("simpan data "+JSON.stringify(StatusAktif));

        let validasiMessage = "";
        if(NamaTemplate==""){
            validasiMessage = validasiMessage + "Silahkan isi nama template terlebih dahulu.\n";
        }
        if(NamaSource==""){
            validasiMessage = validasiMessage + "Silahkan isi nama source terlebih dahulu.\n";
        }
        if(NamaPaket==""){
            validasiMessage = validasiMessage + "Silahkan isi nama paket terlebih dahulu.\n";
        }
        if(StatusAktif==""){
            validasiMessage = validasiMessage + "Silahkan isi status aktif terlebih dahulu.\n";
        }
        if(IsiSMS != "") {
            if (IsiSMS.length > 160) {
                validasiMessage = validasiMessage + "SMS tidak boleh melebihi 160 karakter.\n";
            }
        } else {
            validasiMessage = validasiMessage + "Silahkan isi pesan sms terlebih dahulu.\n";
        }

        if(IsiEmail == "") {
            validasiMessage = validasiMessage + "Silahkan isi pesan email terlebih dahulu.\n";
        }

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            setLoading(true)
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"UPDATE",
                "IDEmailSMS": IDEmailSMS,
                "ProductName": NamaPaket,
                "SourceCode": NamaSource,
                "Title": NamaTemplate,
                "TemplateEmail": IsiEmail,
                "TemplateSMS": IsiSMS,
                "IDWeb": IDWeb,
                "IsActive": StatusAktif
            });

            console.log(requestBody);

            var enckey = paths.EncKey;
            var url = paths.URL_API+"templateEmailSMS";
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);

            fetch(url, {
                method: "POST",
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data.");
                    history.push('/list-template-sms-email')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log("Error : " + error.message);
                setLoading(false)
                alert(AlertMessage.failedConnect)
            });
        }
    }

    const sendEmailSMS = (val) => {

        let validasiMessage = "";
        if(FieldNama==""){
            validasiMessage = validasiMessage + "Silahkan isi nama terlebih dahulu.\n";
        }else if(FieldNama.length > 100){
            validasiMessage = validasiMessage + "Nama maksimal 100 karakter.\n";
        }
        if(FieldEmail==""){
            validasiMessage = validasiMessage + "Silahkan isi alamat email terlebih dahulu.\n";
        }else if(!validEmail(FieldEmail)){
            validasiMessage = validasiMessage + "Pastikan email anda valid.\n";
        }else if(FieldEmail.length > 100){
            validasiMessage = validasiMessage + "Email maksimal 100 karakter.\n";
        }
       
        if (FieldSMS == "") {
            validasiMessage = validasiMessage + "Silahkan isi nomor handphone terlebih dahulu.\n";
        }else if(FieldSMS.length < 10 || FieldSMS.length > 13){
            validasiMessage = validasiMessage + "Pastikan nomor handphone yang anda masukkan valid.\n";
        }
        
        var isiEmail = FieldEmail;
        // if (val == "email") { 

        // } else {
            
        // }

        if (validasiMessage != "") {
            alert(validasiMessage);
            return false;
        } else {

            setLoading(true)
            var requestBody = JSON.stringify({
                 // "UserID": UserID,
                // "SourceCode": NamaSource,
                // "ProductName" : NamaPaket,
                // "TRXID" : "2",
                // "email" : FieldEmail,
                // "noHandphone" : FieldSMS

                "UserID": UserID,
                "SourceCode": NamaSource,
                "ProductName" : NamaPaket,
                "TRXID" : generateTRXID(6),
                "Name" : FieldNama,
                "Email" : isiEmail,
                "NoHandphone" : FieldSMS,
                "TextMessage" : FieldText
            });
            console.log(requestBody);

            var enckey = paths.EncKey;
            var url = paths.URL_API+"voucher";
            var requestBodyEnc = btoa(unescape(encodeURIComponent(requestBody)));
            var Signature = md5(enckey + requestBodyEnc);

            console.log(Signature);

            fetch(url, {
                method: "POST",
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Sukses Mengirim");
                    history.push("/list-template-sms-email")
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert("Gagal insert, "+data.ErrorMessage);
                    }
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log("Error : " + error.message);
                setLoading(false)
                alert(AlertMessage.failedConnect)
            });
        }
    }

    const onLoadMicrosite = (namatemplate) => {

        if (namatemplate == "") {
            var template = NamaTemplate;
        } else {
            var template = namatemplate;
        }

        setLoading2(true)
        var requestBody = JSON.stringify({
            // "UserID": cookies.CookieUserID,
            // "ParamKey": cookies.CookieParamKey,
            // "Category":"SourceCode",
	        // "Page":1,
	        // "RowPage":15,
	        // "OrderBy":"",
	        // "Order":"DESC",
	        // "ProductName":ProductName,
	        // "SourceCode":SourceCode

            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method": "SELECT",
            "Page": 1,
            "RowPage": 15,
            "OrderBy": "",
            "Order": "DESC",
            "ProductName": NamaPaket,
            "SourceCode": NamaSource,
            "Title" : template,
            "URL": "",
            "TemplateWeb": "",
            "IsActive": "1"
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'templateWeb';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
    
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
            if(data.ErrorCode=="0"){
                SetListTemplateMicrosite(data.Result)
                // console.log("DATA SUKSES")
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading2(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading2(false)
           alert(AlertMessage.failedConnect);
        });
    }

    const SearchObject=(Object,TxtSearch)=>{
        return Object.filter(function(obj,idxPanel){
            console.log(obj.Title+"=="+TxtSearch);
            if(obj.Title==TxtSearch){
              return true;
            }else{
              return false;
            }
            });
      }

      const unggahGambar = () => {
        //  alert("simpan data "+window.location.host);
        //  return false;

        let validasiMessage = "";
        if(GambarUnggah==null){
            validasiMessage = validasiMessage + "Silahkan upload gambar terlebih dahulu.\n";
        }
        if(FileNameUnggah==""){
            validasiMessage = validasiMessage + "Silahkan isi nama file terlebih dahulu.\n";
        }else if(FileNameUnggah.length > 100){
            validasiMessage = validasiMessage + "Nama file maksimal 100 karakter.\n";
        }
        
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "Filename":FileNameUnggah,
                "DataBase64":GambarUnggah,
                // "IsActive":StatusAktif
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImages';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil unggah gambar.");
                    onLoadGambar();
                    setGambarUnggah(null)
                    setFileNameUnggah("")
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

      const handleSort=(OrderBy)=>{
        setOrderBy(OrderBy);
       if(Order.toUpperCase()=="ASC"){
            setOrder("DESC");
       }else{
           setOrder("ASC");
       }
       // alert(OrderBy)
       // loadData();
    }

    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    const PilihImage=(Filename)=>{
        if(LinkImage == ""){
            setIsiEmail(IsiEmail+'<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
        }else{
            setIsiEmail(IsiEmail+'<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
        }
        
        // alert('Image telah dipilih, silakan tutup popup jika sudah selesai');
    }

    const insertLink=(Link,Display)=>{
        if(Link != ""){
            if(Display==""){
                Display = Link;
            }
            setFileName('<a href="'+Link+'" alt="'+Link+'" class="on">'+Display+'</a>')
        }
        
    }

    const contentStyle = { background: '#ffffff',borderRadius:15,padding:20, width:'100%'};
    const contentStyle2 = { background: '#ffffff',borderRadius:15,padding:20};
    const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
    const arrowStyle = { color: '#000' };

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
                <div className="blog-post">

                <p className="title"><a href="/list-template-sms-email"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit Template SMS dan Email</p>

                    <hr style={{backgroundColor: 'black'}} />

                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Dropdown 
                                spinner={loading2} 
                                required 
                                label="Nama Source" 
                                onChange={(event)=>{
                                    setNamaSource(event.target.value)
                                    setNamaPaket("")
                                    setNamaTemplate("")
                                    onLoadSource(event.target.value)
                                }}>
                                <option value="">-- Pilih Nama Source --</option>
                                {ListSource.length > 0 && ListSource.map((item,index)=>{
                                    return <option value={item.SourceCode} selected={item.SourceCode==NamaSource}>{item.SourceCode}</option>;
                                })}
                            </Dropdown>
                        </Card>
                        <Card style={{border:'none'}}>
                            <Dropdown 
                                spinner={loading3} 
                                required 
                                label="Nama Paket" 
                                value={NamaPaket} 
                                onChange={(event)=>{
                                    setNamaPaket(event.target.value)
                                    setNamaTemplate("")
                                    onLoadPaket(event.target.value)
                                }}>
                                <option value="">-- Pilih Nama Paket --</option>
                                {ListPaket.length > 0 && ListPaket.map((item,index)=>{
                                    return <option value={item.ProductName} selected={item.ProductName==NamaPaket}>{item.ProductName}</option>;
                                })}
                            </Dropdown>
                        </Card>
                        <Card style={{border:'none'}}>
                            <Dropdown 
                                spinner={loading4}
                                required
                                label="Nama Template"
                                value={NamaTemplate}
                                onChange={(event)=>{
                                    setNamaTemplate(event.target.value)
                                    onLoadMicrosite(event.target.value)
                            }}>
                            <option value="">-- Pilih Nama Template --</option>
                            {ListTemplate.length > 0 && ListTemplate.map((item,index)=>{
                                return <option value={item.Title} selected={item.Title==NamaTemplate}>{item.Title}</option>;
                            })}
                            </Dropdown>
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                    {NamaTemplate != "" ? 
                    <div>
                        <Popup>   
                            {() => (
                            <div>
                                <div>
                                { ListTemplateMicrosite.length > 0 && ListTemplateMicrosite.map((items,index)=>{
                                    setURLTemplateMicrosite(items.URL)
                                })}
                                </div>
                            </div>
                            )}
                        </Popup>
                        <Gap height={10}/>
                        <a
                            className="button"
                            target="_blank"
                            href={URLTemplateMicrosite}
                            style={{padding:7,fontSize:13,backgroundColor:'#3498DB',color:'#ffffff',textDecoration:'none',borderRadius:10}}>Tampilkan Template
                        </a>
                        <Gap height={20}/>
                    </div> : ""}

                    <label>Status Aktif</label><span style={{color:'red'}}> *</span>
                    <div>
                        <input type="radio" value="1" name="status_aktif" checked={StatusAktif=="1"}
                            onChange={event=>setStatusAktif(event.target.value)}
                        /> Aktif
                         &nbsp;
                    &nbsp;
                        <input type="radio" value="0" name="status_aktif" checked={StatusAktif=="0"}
                            onChange={event=>setStatusAktif(event.target.value)}
                        /> Tidak Aktif
                    </div>

                    <Gap height={20}/>

                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle eventKey="1">Panduan Mengisi Konten</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>

                            <p>Dear, {NAME}</p>
                            Silahkan aktivasi,
                            <table>
                                <tr>
                                    <td>Voucher No</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>{VOUCHERNO}</td>
                                </tr>
                                <tr>
                                    <td>Param</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>{PARAM}</td>
                                </tr>
                                <tr>
                                    <td>Pesan</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>{TEXTMESSAGE}</td>
                                </tr>
                                <tr>
                                    <td>URL</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>{URL}</td>
                                </tr>
                            </table>
                            <br/>
                            {/* dengan klik tombol dibawah ini
                            
                            <div class='se-component se-image-container __se__float-none' contenteditable='false'>
                                <a href='https://sinarmas.co.id'>
                                    <img src="http://182.23.21.61/api/v1/images"/>
                                </a>
                            </div> */}
                            
                            <p>Silahkan klik link dibawah ini untuk aktivasi</p>
                            
                            <a href="http://yourdomain.com/mylink.pl">YourDomain.com</a>

                            <br/>
                            <br/>
                            <p>Terima kasih</p>

                            <hr/>
                            
                            <table>
                                <tr>
                                    <td>{NAME}</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>Akan diisi otomatis dengan nama user</td>
                                </tr>
                                <tr>
                                    <td>{VOUCHERNO}</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>Akan diisi otomatis dengan no voucher</td>
                                </tr>
                                <tr>
                                    <td>{PARAM}</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>Akan diisi otomatis dengan link aktivasi</td>
                                </tr>
                                <tr>
                                    <td>{TEXTMESSAGE}</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>Akan diisi otomatis dengan pesan template</td>
                                </tr>
                                <tr>
                                    <td>{URL}</td>
                                    <td>&nbsp;</td>
                                    <td>:</td>
                                    <td>&nbsp;</td>
                                    <td>Akan diisi otomatis dengan url</td>
                                </tr>
                            </table>

                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                    <Gap height={20}/>

                    <label>Isi SMS</label><span style={{color:'red'}}> *</span>
                    <Gap height={10}/>
                    <TextArea
                        value={IsiSMS}
                        maxLength={160}
                        onChange={event=>setIsiSMS(event.target.value)}
                        // onChange={this.handleOnChange}
                    />
                    Sisa Karakter SMS : {CharLimit - IsiSMS.length}

                    {/* <Popup
                        trigger={<button style={{backgroundColor:'#00B6DB',height:30,borderRadius:5,color:'#ffffff'}}> Send Test SMS </button>}
                        {...{ contentStyle, overlayStyle, arrowStyle }}
                        modal
                        nested
                    >
                        {close => (
                        <div>
                            <button style={{backgroundColor:'#FF3131',color:'#ffffff',borderRadius:5}} onClick={close}>
                            &times;
                            </button>
                            <hr/>
                            <div>
                                <Input 
                                required
                                    label="Nama"
                                    onChange={event=>setFieldNama(event.target.value)}
                                />
                                <Input 
                                required
                                    label="Nomor Handphone"
                                    type="number"
                                    onChange={event=>setFieldSMS(event.target.value)}
                                />
                                <Input 
                                required
                                    label="Alamat Email"
                                    onChange={event=>setFieldEmail(event.target.value)}
                                />
                                <Input 
                                    label="Pesan Text"
                                    onChange={event=>setFieldText(event.target.value)}
                                />
                            </div>
                            <Gap height={10}/>
                            <div>
                                <Button spinner={loading} title="Send" onClick={()=>sendEmailSMS("sms")}/>
                            </div>
                        </div>
                        )}
                    </Popup> */}

                    <Gap height={20}/>

                    <label>Isi Email</label>
                    <Gap height={10}/>
                    <div className="editordiv">
                        <Popup
                            trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:5,color:'#ffffff'}}> Pilih Gambar </button>}
                            {...{ contentStyle, overlayStyle, arrowStyle}}
                            modal
                            nested
                            lockScroll = {true}
                            offsetY = {0}
                        >
                            {close => (
                            <div>
                                <div align="right">
                                    <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                </div>
                                <hr/>
                                <div style={{overflow: 'auto', height:550}}>
                                <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                    <Tab eventKey="home" title="Library">
                                        <Row xs={12} md={12} lg={8}>
                                            <Col>
                                                <Input
                                                    label="Filter Nama File"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                />
                                            </Col>
                                            <Col>
                                                <Dropdown label = "Filter Status Aktif"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setFieldFilterStatus(event.target.value)}}>
                                                        <option value= "1" selected>Aktif</option>
                                                        <option value= "0">Tidak Aktif</option>
                                                </Dropdown>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        <Row xs={12} md={12} lg={8}>
                                            <Col>
                                                <Dropdown label = "Sorting"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                        <option value= "Filename" selected={"Filename"==OrderBy}>Nama File</option>
                                                        <option value= "InputDate" selected={"InputDate"==OrderBy}>Input Date</option>
                                                </Dropdown>
                                            </Col>
                                            <Col>
                                                <Dropdown label = "Sorting Type"
                                                    style={{width:300}}
                                                    onChange = {(event) => {setOrder(event.target.value)}}>
                                                        <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                        <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                </Dropdown>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <div>
                                            <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                        </div>
                                    
                                    <Gap height={10}/>
                                        <label><span style={{color:'red'}}>*</span> Klik pada gambar untuk memilih</label>
                                        <Gap height={10}/>

                                        {!loading ? (
                                        <div> 
                                        <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                            <Thead>
                                            <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                <Th className="tabelHeader" onClick={()=>handleSort("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                            </Tr>
                                            </Thead>
                                            <Tbody>
                                            { ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                    return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                        <Td> 
                                                            <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                                <img src={"data:image/png;base64,"+item.DataBase64} style={{width:'100%', maxWidth:200}}/>
                                                            </div>
                                                        </Td>
                                                        <Td>{item.Filename}</Td>
                                                        <Td>{item.InputDate}</Td>
                                                        <Td style={{cursor:'pointer'}} align="left">
                                                            <Input
                                                                placeholder = "Link image"
                                                                style={{width:200}}
                                                                onChange = {(event) => {setLinkImage(event.target.value)}}
                                                            />
                                                            <Gap height={15}/>
                                                            <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                PilihImage(item.Filename)
                                                                close();
                                                                }
                                                                }>Pilih</Button>
                                                        </Td>
                                                    </Tr>;
                                            }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                        
                                            </Tbody>
                                        </Table>
                                        <Gap height={10}/>
                                        {TotalRecord > 0 &&
                                        <div style={{display:'flex'}}>
                                            <span>Page : &nbsp;</span>
                                            <Pagination size="sm">{items}</Pagination>
                                        </div>
                                        }
                                        <Gap height={10}/>
                                        </div>
                                        ) : (
                                        <div class="divLoader">
                                            <ReactBootStrap.Spinner animation="border" />
                                        </div>)}
                                    </Tab>
                                    <Tab eventKey="unggah" title="Unggah">
                                        <Container>
                                            <Gap height={10}/>
                                            {GambarUnggah != null &&
                                            <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                <img src={"data:image/png;base64,"+GambarUnggah} style={{width:'100%'}}/>
                                            </div>
                                            }
                                            <Input 
                                                label="Unggah Gambar"
                                                type="file"
                                                accept=".jpeg, .png, .jpg"
                                                onChange={event=>{
                                                    if (event.target.files && event.target.files[0]) {
                                                        let img = event.target.files[0];
                                                        // console.log("img ",img)
                                                        if(img.size <= 200000){ //200kb
                                                            var filename = (img.name).replace(/ /gi, "-");
                                                            // console.log("",(img.name).replace(/ /gi, "-"))
                                                            getBase64(img, (result) => {
                                                                console.log("",result);
                                                                setFileNameUnggah(filename)
                                                                setGambarUnggah(result)
                                                            });
                                                        }else{
                                                            setGambarUnggah(null)
                                                            setFileNameUnggah("")
                                                            alert("Maaf, Ukuran gambar terlalu besar. Maksimal 200 kb");
                                                        }
                                                    }
                                                }}
                                            />
                                            <Gap height={20}/>
                                            <div className="button-action">
                                                <Button spinner={IsLoading2} title="Save" onClick={()=>{
                                                    unggahGambar()
                                                    close();
                                                }}/>
                                            </div>
                                        </Container>
                                    </Tab>
                                    </ReactBootStrap.Tabs>
                                
                                </div>
                            </div>
                            )}
                        </Popup>
                        {/* <Popup
                                trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:5,color:'#ffffff'}}> Insert Link </button>}
                                {...{ contentStyle2, overlayStyle, arrowStyle}}
                                modal
                                nested
                                lockScroll = {true}
                                offsetY = {0}
                            >
                                {close => (
                                <div>
                                    <div 
                                    // style={{display:'flex',justifyContent:'space-between',paddingLeft:25}}
                                        align="right"
                                    >
                                        
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                    </div>
                                    <hr/>
                                    <label style={{fontSize:20,fontWeight:600,paddingLeft:20}}>Insert Link</label>
                                    <div style={{overflow: 'auto', height:300,padding:20}}>
                                        <Input
                                            // style={{maxWidth:300}}
                                            label="URL to link"
                                            value={UrlLink}
                                            onChange={event=>{
                                                setUrlLink(event.target.value)
                                            }}
                                        />
                                        <Gap height={10}/>
                                        <Input
                                            //  style={{maxWidth:300}}
                                            label="Text to display"
                                            value={TextDisplay}
                                            onChange={event=>{
                                                setTextDisplay(event.target.value)
                                            }}
                                        />
                                        <Gap height={20}/>
                                        <div align="right">
                                            <Button style={{maxWidth:150,height:35,alignSelf:'right'}}
                                                onClick={()=>{
                                                    insertLink(UrlLink,TextDisplay)
                                                    close();
                                                }}
                                            >Submit</Button>
                                        </div>
                                    
                                    </div>
                                </div>
                                )}
                            </Popup> */}
                        {/* <Editor
                            editorState={IsiEmail}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName editor"
                            onEditorStateChange={event=>setIsiEmail(event)}
                        /> */}
                        {/* <SunEditor 
                            height={300}
                            setOptions={{
                                height: 200,
                                // buttonList: [['font', 'align'], ['image']]//buttonList.formatting // Or Array of button list, eg. [['font', 'align'], ['image']]
                                // Other option
                                buttonList : [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                    ['image','video', 'fullScreen', 'showBlocks', 'preview', 'print']
                                ],
                            }}
                            setContents={DefaultContent}
                            appendContents = {FileName}

                            // getSunEditorInstance={getSunEditorInstance}
                            onChange={(content)=>{
                                var konten = content.replace(new RegExp(window.location.href+"/{URL}", "gi"), "{URL}")
                                konten = konten.replace(new RegExp("/{URL}", "gi"), "{URL}")
                                console.log(konten)
                                setIsiEmail(konten);
                            }}
                        /> */}
                        <Editor
                            // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                            // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                            onInit={(evt, editor) => editorRef.current = editor}
                            // initialValue="<p>This is the initial content of the editor.</p>"
                            onSetContent={()=>{
                                console.log("onSetContent")
                            }}
                            onEditorChange={(newText)=>{
                                console.log("onEditor")
                                setIsiEmail(newText)
                            }}
                            onChange={()=>{
                                console.log("onChange")
                            }}
                            value={IsiEmail}
                            init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
                    <Gap height={10}/>
                    <div style={{display:'flex',alignContent:'right'}}>
                        <Popup
                            trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:10,color:'#ffffff'}}> Show Email </button>}
                            {...{ contentStyle, overlayStyle, arrowStyle }}
                            modal
                            nested
                        >
                            {close => (
                            <div>
                                <button style={{backgroundColor:'#FF3131',color:'#ffffff',borderRadius:5}} onClick={close}>
                                &times;
                                </button>
                                <hr/>
                                <div>
                                    <Markup content={IsiEmail} />
                                </div>
                            </div>
                            )}
                        </Popup>
                        {/* <div style={{paddingRight:20}}></div>
                        <Popup
                            trigger={<button style={{backgroundColor:'#00B6DB',height:35,borderRadius:10,color:'#ffffff'}}> Send Test Email </button>}
                            {...{ contentStyle, overlayStyle, arrowStyle }}
                            modal
                            nested
                        >
                            {close => (
                            <div>
                                <button style={{backgroundColor:'#FF3131',color:'#ffffff',borderRadius:5}} onClick={close}>
                                &times;
                                </button>
                                <hr/>
                                <div>
                                    <Input 
                                        required
                                        label="Nama"
                                        onChange={event=>setFieldNama(event.target.value)}
                                    />
                                    <Input 
                                    required
                                        label="Alamat Email"
                                        onChange={event=>setFieldEmail(event.target.value)}
                                    />
                                    <Input 
                                    required
                                        label="Nomor Handphone"
                                        type="number"
                                        onChange={event=>setFieldSMS(event.target.value)}
                                    />
                                    <Input 
                                        label="Pesan Text"
                                        onChange={event=>setFieldText(event.target.value)}
                                    />
                                </div>
                                <Gap height={10}/>
                                <div>
                                    <Button spinner={loading} title="Send" onClick={()=>sendEmailSMS("email")}/>
                                </div>
                            </div>
                            )}
                        </Popup> */}
                    </div>

                    <div className="button-action">
                  
                        <Button 
                            spinner={loading} 
                            onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                    </div>
                </div>
            </div>
        </div>
       
    )
}

export default EditTemplateSmsEmail
