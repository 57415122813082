import React from 'react';
import './input.scss';

function Input({label,required, ...rest}) {
    return (
        <div className="input-wrapper">
            <p className="label">{label}{required && <span style={{color:'red'}}> *</span>}</p>
            <input className="input" {...rest}/>
        </div>
    )
}

export default Input;
