import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Gap, Header, Input } from '../../components';
import { AlertMessage, paths } from '../../utils';
import { getParameterByName } from '../../utils/functions';
// import './home.scss'
import { useCookies } from 'react-cookie';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';

const Aktivasi = () => {
    const history = useHistory();
    const [KodeVoucher,setKodeVoucher] = useState("")
    const [Param,setParam] = useState(getParameterByName('param'))
    const [Loading,setLoading] = useState("")
    const [cookies, setCookie] = useCookies(['user']);
    // const [Param,setParam] = useState(cookies.CookieParamKeyPost)

    useEffect(()=>{
        // var tech = getParameterByName('param');
        // alert(tech)
    },[])

    const next =()=>{
        let validasiMessage = "";
        if(KodeVoucher==""){
            validasiMessage = validasiMessage + "Silahkan isi kode voucher terlebih dahulu.\n";
        }else if(KodeVoucher.length > 100){
            validasiMessage = validasiMessage + "Kode voucher maksimal 100 karakter.\n";
        }
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            // history.push('/aktivasi-form')
            var requestBody = JSON.stringify({
                "ParamKey": Param,
                "VoucherNo" : KodeVoucher
            });
    
            console.log(requestBody);
            
            // var enckey = paths.EncKey;
            var enckey = paths.ENCKEY_VOUCHER_VALIDATION;
            var url = paths.URL_API_EXTERNAL+'voucherValidation';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
    
            console.log(""+Signature);
            setLoading(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                setLoading(false)
                // var errmsg = data.errmsg;
                console.log("result : ",JSON.stringify(data));
                
                if(data.ErrorCode=="0"){
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    setCookie('CookieParamKeyVoucher', data.Param, { path: '/',expires : new Date(date) });
                    setCookie('CookieKodeVoucher', KodeVoucher, { path: '/',expires : new Date(date) });
                    // alert("Validasi voucher berhasil.");
                   history.push('/aktivasi-form')
                }else{
                    if(data.ErrorMessage.trim().toLowerCase()=="Error - ParamKey or VoucherNo is not valid".trim().toLowerCase()){
                        alert("Kode Voucher yang anda masukkan tidak valid.");
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setLoading(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect)
            });
        }
    }



    return (
        <div>

            <Header noNavbar/>
        <div className="main-page">
            <div className="content-wrapper-2">
            <div className="blog-post">
                <p className="title">Aktivasi</p>

                <hr 
                    style={{
                        backgroundColor: 'black'
                    }} 
                />
                
                <Input 
                    style={{width:300}}
                    required
                    label="Kode Voucher" 
                    value={KodeVoucher} 
                    onChange={event=>setKodeVoucher(event.target.value)}
                />
                <Input 
                    hidden
                    // required
                    label="" 
                    value={Param} 
                    onChange={event=>setParam(event.target.value)}
                />
                <Gap height={10} />
                <div className="button-action" style={{marginLeft:'0px'}}>
                    <Button style={{height:40}} spinner={Loading} title="Next" onClick={()=>next()}/>
                </div>
                <Gap height={20}/>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Aktivasi;
