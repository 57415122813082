import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Gap, Header, Link } from '../../components';
import './post.scss';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useCookies } from 'react-cookie';
import { AlertMessage, paths } from '../../utils';
import md5 from 'md5';
import { Spinner } from 'react-bootstrap';
import { getParameterByName } from '../../utils/functions';
import {Helmet} from "react-helmet";
import { setForm } from '../../redux';
import { useDispatch } from 'react-redux';

const Post = () => {
    const history = useHistory();
    let { post } = useParams();
    // const url = window.location.href;
    // alert(url);
    const location = useLocation();
    // console.log(location)
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [Content,setContent] = useState("")
    const [Title,setTitle] = useState("")
    const [ParamKey,setParamKey] = useState(getParameterByName('param'))
    const [Loading,setLoading] = useState(false)
    const [PageTitle,setPageTitle] = useState("")
    const [MetaTitle,setMetaTitle] = useState("")
    const [MetaKeyword,setMetaKeyword] = useState("")
    const [MetaDesc,setMetaDesc] = useState("")
    const dispatch = useDispatch();


    useEffect(()=>{
        console.log("cookie",cookies.CookiePostTitle)
        console.log("cookie2 ",cookies.CookiePostContent)
        dispatch(setForm("PageActive","Post"))
        // alert("ij")
        if(location.state==null || location.state==""){
        // if(cookies.CookiePostTitle==null || cookies.CookiePostTitle==""){

            if(ParamKey=="" || ParamKey==null){
                if(cookies.CookieParamKey=="" || cookies.CookieParamKey==null){
                    return false;
                }else{
                    var url = window.location.href;
                    loadData(url);

                }
            }else{
                loadData2();
            }
            
        }
        setPageTitle("Digital Voucher | "+Title)
        setMetaTitle(Title)
        setMetaKeyword(Title)
        setMetaDesc(Title)
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const loadData = (url)=>{

        console.log(url)
        // return;
        var requestBody = JSON.stringify({
            // "UserID": cookies.CookieUserID,
            // "ParamKey": cookies.CookieParamKey,
            // "Method": "SELECT",
            // "Page": 1,
            // "RowPage": 1,
            // "OrderBy": "InputDate",
            // "Order": "DESC",
            // "ProductName": "",
            // "SourceCode": "",
            // "Title" : "",
            // "URL": url,
            // "TemplateWeb": "",
            // "IsActive": "1"

        	"UserID": cookies.CookieUserID,
        	"ParamKey": cookies.CookieParamKey,
        	"Method":"SELECT",
        	"Page":1,
        	"RowPage":1,
        	"OrderBy":"InputDate",
        	"Order":"DESC",
        	"ProductName":cookies.CookieProductName,
        	"SourceCode":cookies.CookieSourceCode,
        	"ExpiredDay": 0,
        	"Title":"",
        	// "URL": url,
        	"URL": "",
        	"TemplateWeb":"",
        	"TemplateEmail":"",
        	"TemplateSMS":"",
        	"IsActive":"1"
        });

        console.log(requestBody);
        
        // var enckey = paths.EncKey;
        var enckey = paths.ENCKEY_VIEW_TEMPLATE_WEB;
        var url = paths.URL_API+'product';
        // var url = paths.URL_API+'viewTemplateWeb';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            setLoading(false)

            // var errmsg = data.errmsg;
            console.log("list :"+JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setContent(data.Result[0].TemplateWeb);
                setTitle(data.Result[0].Title);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }else{
                    alert(data.ErrorMessage);
                }
            }
        })
        .catch((error) => {
            setLoading(false)
            alert(AlertMessage.failedConnect)
        });
    }

    const loadData2 = ()=>{
        var requestBody = JSON.stringify({
            "ParamKey": ParamKey
        });
	

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API_EXTERNAL+'viewTemplateWeb';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            setLoading(false)
            console.log("list2 :"+JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setContent(data.TemplateWeb);
                setTitle(data.Title);
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }else{
                    alert(data.ErrorMessage);
                }
            }

            
        })
        .catch((error) => {
            setLoading(false)
            alert(AlertMessage.failedConnect)
        });
    }

    const handleAktuvasi = ()=>{
        history.push("/aktivasi/?param="+ParamKey)
        // window.href="/aktivasi/?param="+ParamKey;
    }

    var ReactDOMServer = require('react-dom/server');
    var HtmlToReactParser = require('html-to-react').Parser;

    if(location.state==null || location.state==""){
    // if(cookies.CookiePostTitle==null || cookies.CookiePostTitle==""){
        var htmlInput = Content;
        var title =Title ;
    }else{

        var htmlInput = location.state.postContent;
        var title = location.state.title;
        // var htmlInput = cookies.CookiePostContent;
        // var title = cookies.CookiePostTitle;
    }

    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    // var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
    return (
        <div className="main-app-wrapper" style={{flexDirection:'column'}}>
            <Header noNavbar/>
            <div className="content-wrapper">
                <div className="detail-blog-wrapper" style={{paddingRight:40,paddingLeft:40}}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="description" content={MetaDesc}/>
                        <meta name="keywords" content={MetaKeyword}/>
                        <meta property="og:url" content={window.location.href}></meta>
                        <meta property="og:title" content={MetaTitle}></meta>
                        <meta property="og:site_name" content="Digital Voucher"></meta>
                        <meta property="og:type" content="article"></meta>
                        
                        <title>{PageTitle}</title>
                    </Helmet>
                    {Loading && 
                    <div class="divLoader">
                        <Spinner animation="border" />
                    </div>
                    }
                    <p className="blog-title">{title}</p>
                    {/* <p className="blog-author">Author - Date Post</p> */}
                    <p className="blog-body">{reactElement}</p>
                    <Gap height={20}/>
                    {ParamKey!="" && ParamKey!=null
                    &&
                    // <form action={"/aktivasi/?param="+ParamKey} onSubmit={()=>{
                    //     const date = new Date();
                    //     date.setDate(date.getDate() + 1);
                    //     setCookie('CookieParamKeyPost', ParamKey, { path: '/',expires : new Date(date) });
                    //     // alert("CookieParamKeyPost");
                    // }}>
                        // <Button style={{maxWidth:200,height:40}} title="Aktivasi" type="submit" 
                        //     onCLick={()=>{
                        //         history.push("/aktivasi/?param="+ParamKey)
                        //     }}
                        // ></Button>
                    //  </form>
                    
                    
                        <a href={"/aktivasi/?param="+ParamKey}>
                            <Button style={{maxWidth:200,height:40}}>
                                Aktivasi
                            </Button>
                            </a>
                   
                    }
                </div>
            </div>
        </div>

       
    )
}

export default Post
