import React from 'react';
import { Routes } from '../config';
import { Provider } from 'react-redux';
import { store } from '../redux';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    
    <Provider store={store}>
      <Routes/>
    </Provider>
  );
}

export default App;
