import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Dropdown, Gap, Input, Link, Sort, TextArea, Upload } from '../../components'
import LeftMenu from '../../components/molecules/LeftMenu';
import md5 from 'md5';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../redux';
import { paths } from '../../utils'
import { useCookies } from 'react-cookie';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Markup } from 'interweave';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { IconSort } from '../../assets';
import LabelTH from '../../components/molecules/LabelTH';
import * as ReactBootStrap from 'react-bootstrap'
// import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFilter, faTrash, faEdit, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

const TemplateSmsEmail = () => {
    const history = useHistory();
    const [ListPaket,setListPaket] = useState([])
    const [loading,setLoading] = useState(false)
    // const location = useLocation();
    const dispatch = useDispatch();
    const {form}=useSelector(state=>state.LoginReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);

    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [FieldFilterTemplate,setFieldFilterTemplate] = useState("")
    const [FieldFilterNamaPaket,setFieldFilterNamaPaket] = useState("")
    const [FieldFilterNamaSource,setFieldFilterNamaSource] = useState("")

    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(15)
    const [ResultMessage,setResultMessage] = useState("")
    // setFieldFilterSource

    useEffect(()=>{
        console.log("form ",form);
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
        cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            //  alert("tes"+location.state+"-");
            return false;
        }else{
            dispatch(setForm("ParamKey",cookies.CookieParamKey))
            dispatch(setForm("UserID",cookies.CookieUserID))
            dispatch(setForm("PageActive","SMSEMAIL"))

            loadData();
        }
        console.log("lsit paket"+ListPaket.length)
    },[OrderBy,Order,PageActive])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    const loadData = ()=> {

        if (FieldFilterTemplate != "") {
            var FilterTemplate = "%"+FieldFilterTemplate+"%";
        }  else {
            var FilterTemplate = "";
        }

        if (FieldFilterNamaSource != "") {
            var FilterNamaSource = "%"+FieldFilterNamaSource+"%";
        } else {
            var FilterNamaSource = "";
        }

        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "1";
        }

        if (FieldFilterNamaPaket != "") {
            var FilterNamaPaket = "%"+FieldFilterNamaPaket+"%";
        } else {
            var FilterNamaPaket = "";
        }

        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method": "SELECT",
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "ProductName": FilterNamaPaket,
            "SourceCode": FilterNamaSource,
            "Title": FilterTemplate,
            "TemplateEmail": "",
            "TemplateSMS": "",
            "IDWeb":"",
            "IsActive": FilterStatus,
            
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'templateEmailSMS';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));

            if(data.ErrorCode=="0"){
                setListPaket(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading(true)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(true)
           setResultMessage("")
        });
    }

    const clickDelete = (ProductName,IDEmailSMS) => {

        var r = window.confirm("Apakah anda yakin menghapus paket "+ProductName+"?");
        if (r == false) {
            console.log("canceled")
        } else {
            console.log("oke");
            var requestBody = JSON.stringify({
                "UserID": cookies.CookieUserID,
                "ParamKey": cookies.CookieParamKey,
                "Method":"DELETE",
                "IDEmailSMS": IDEmailSMS
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'templateEmailSMS';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
           
            console.log(""+Signature);
        
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                
    
                if(data.ErrorCode=="0"){
                    alert("Berhasil delete");
                    loadData();

                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                
                }
                setLoading(true)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(true)
            });
        }
    }

    const handleSort=async(OrderBy)=>{
         setOrderBy(OrderBy);
        if(Order.toUpperCase()=="ASC"){
             setOrder("DESC");
        }else{
             setOrder("ASC");
        }
        // alert(OrderBy)
        // loadData();
    }

    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
            <div className="blog-post">
                {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                <p className="title"><FontAwesomeIcon icon={faEnvelopeOpenText}/> List Template SMS dan Email</p>

                <hr style={{backgroundColor: 'black'}} />

                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Input
                                label="Filter Nama Template"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterTemplate(event.target.value)}}
                            />
                        </Col>
                        <Col>
                            <Input
                                label="Filter Nama Paket"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterNamaPaket(event.target.value)}}
                            />
                        </Col>
                        <Col>
                            <Input
                                label="Filter Nama Source"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterNamaSource(event.target.value)}}
                            />
                        </Col>
                    </Row>
                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Dropdown label = "Filter Status Aktif"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFilterStatus(event.target.value)}}>
                                    <option value= "1" selected>Aktif</option>
                                    <option value= "0">Tidak Aktif</option>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Dropdown label = "Sorting"
                                style={{width:300}}
                                onChange = {(event) => {setOrderBy(event.target.value)}}>
                                    <option value= "Title" selected={"Title"==OrderBy}>Nama Template</option>
                                    <option value= "ProductName" selected={"ProductName"==OrderBy}>Nama Paket</option>
                                    <option value= "SourceCode" selected={"SourceCode"==OrderBy}>Nama Source</option>
                                    {/* <option value= "UserID">User</option> */}
                                    <option value= "InputDate" selected={"InputDate"==OrderBy}>Tgl Input</option>
                                    <option value= "IsActive" selected={"IsActive"==OrderBy}>Status Aktif</option>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Dropdown label = "Sorting Type"
                                style={{width:300}}
                                onChange = {(event) => {setOrder(event.target.value)}}>
                                    <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                    <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                            </Dropdown>
                        </Col>
                    </Row>
                    <br/>
                    <div style={{display:'flex'}}>
                        <Button
                            style={{backgroundColor:"#7F8C8D",width:120,fontSize:13}} 
                            onClick={()=>loadData()}
                        ><FontAwesomeIcon icon={faFilter}/> Filter Data</Button>
                        <Gap width={10}/>
                        <Button style={{width:120,fontSize:13}} onClick={()=>history.push('/input-template-sms-email')}><FontAwesomeIcon icon={faPlusCircle}/> Tambah Baru</Button>
                    </div>
                    
                    <hr style={{backgroundColor: 'black'}} />

                    {loading ? (
                    <div>
                
                    <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                        <Thead>
                            <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                <Th className="tabelHeader"  onClick={()=>handleSort("Title")}><LabelTH>Nama Template</LabelTH></Th>
                                <Th className="tabelHeader" onClick={()=>handleSort("SourceCode")}><LabelTH>Nama Source </LabelTH></Th>
                                <Th className="tabelHeader" onClick={()=>handleSort("ProductName")}><LabelTH>Nama Paket </LabelTH> </Th>
                                {/* <Th className="tabelHeader" onClick={()=>handleSort("UserID")}><LabelTH>User </LabelTH></Th> */}
                                <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tanggal Input </LabelTH></Th>
                                <Th className="tabelHeader" onClick={()=>handleSort("IsActive")}><LabelTH>Status Aktif </LabelTH></Th>
                                <Th width="30">Action</Th>
                            </Tr>
                        </Thead>
                    <Tbody>
                        { ListPaket.length > 0 ? ListPaket.map((item,index) => {
                            return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                <Td>{item.Title}</Td>
                                <Td>{item.SourceCode}</Td>                           
                                <Td>{item.ProductName}</Td>
                                {/* <Td>{cookies.CookieUserID}</Td> */}
                                <Td>{item.InputDate}</Td>
                                <Td>{item.IsActive == "1"? "Aktif" : "Tidak Aktif"}</Td>
                                <Td>
                                    <div style={{display:'flex'}}>
                                        <Button style={{backgroundColor:"#ca9d1f",fontSize:10,width:80}} 
                                        onClick={()=>{
                                            history.push('/edit-template-sms-email')
                                            // history.push('/edit-paket',{
                                            //     ProductName:item.ProductName,
                                            //     UserID:form.UserID,
                                            //     ParamKey:form.ParamKey
                                            // })
                                            dispatch(setForm("DetailSMSEmail",item))
                                        }
                                        }
                                        ><FontAwesomeIcon icon={faEdit}/> Edit</Button>

                                        <Gap width={10}/>

                                        <Button 
                                            style={{backgroundColor:"#e05858",fontSize:10,width:80}} onClick={()=>clickDelete(item.ProductName,item.IDEmailSMS)}
                                        ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
                                    </div>
                                </Td>
                                </Tr>;
                        }) : <Tr><Td colSpan="8" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                    </Tbody>
                </Table>

                <hr style={{backgroundColor: 'black'}} />
                
                {TotalRecord > 0 &&
                    <div style={{display:'flex'}}>
                        <span>Page : &nbsp;</span>
                        <Pagination size="sm">{items}</Pagination>
                    </div>
                }
                <div>
                    Total Data : {TotalRecord}
                </div>
                </div>) : 
                    (<div class="divLoader">
                        <ReactBootStrap.Spinner animation="border" />
                    </div>)}
            </div>
            </div>
        </div>
        
    )
}

export default TemplateSmsEmail
