import React, { useEffect, useState } from 'react'
import { useHistory, useLocation,useRouteMatch  } from 'react-router-dom'
import { Button,Dropdown,Gap,Input, Sort } from '../../components'
import "../../App.css"
import LeftMenu from '../../components/molecules/LeftMenu'
import md5 from 'md5'
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../redux';
import { AlertMessage, paths } from '../../utils'
import { useCookies } from 'react-cookie';
import Pagination from 'react-bootstrap/Pagination'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as ReactBootStrap from 'react-bootstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../components/molecules/LabelTH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFilter, faTrash, faEdit, faImages } from '@fortawesome/free-solid-svg-icons'

const KategoriGambar = () => {
    const history = useHistory();
    const [ListKategori,setListKategori] = useState([])
    const [loading,setLoading] = useState(false)
    const [Filter,setFilter] = useState("")
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [FieldFilter,setFieldFilter] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const {formPaket}=useSelector(state=>state.PaketReducer);
    // location.state.postContent
    const dispatch = useDispatch();
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [FieldKategori,setFieldKategori] = useState("")
    const [FieldFlagEksternal,setFieldFlagEksternal] = useState("0")
    const [FieldIDKategori,setFieldIDKategori] = useState("")
    const [FieldFilterPaket,setFieldFilterPaket] = useState("")
    const [PageNumber,setPageNumber] = useState(1)
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(15)
    const [ResultMessage,setResultMessage] = useState("")
    
    
    useEffect(()=>{
        console.log("form ",form);
        
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
         cookies.CookieUserID==null || cookies.CookieUserID==""){
            //alert("Session anda telah habis. Silahkan login kembali.");
            
            // alert("tes");
        //    history.push('/login');
            logout();
            return false;
        }else{
            dispatch(setForm("ParamKey",cookies.CookieParamKey))
            dispatch(setForm("UserID",cookies.CookieUserID))
            dispatch(setForm("PageActive","KategoriGambar"))

            loadData();
        }

    },[OrderBy,Order,PageActive])
    
    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
    }

    const loadData = ()=>{

        if (FieldKategori != "") {
            var FilterKategori = "%"+FieldKategori+"%";
        } else {
            var FilterKategori = "";
        } 
        // if (FieldFlagEksternal != "") {
        //     var FilterFlagExternal = ""+FieldFlagEksternal+"";
        // } else {
        //     var FilterFlagExternal = "";
        // } 
     

        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method":"SELECT",
            "Page": PageActive,
            "RowPage": RowPage,
            "OrderBy": OrderBy,
            "Order": Order,
            "CategoryDesc":FilterKategori,
            // "FlagExternal":FilterFlagExternal
        });


        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImageCategory';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

        console.log(""+Signature);
        setResultMessage("Loading ...")
        setLoading(true)
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            

            if(data.ErrorCode=="0"){
                setListKategori(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }else{
                if(data.ErrorMessage=="Param Key Exipred"){
                    alert("Session anda telah habis. Silahkan login kembali.");
                    // history.push('/login');
                    logout()
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const clickDelete = (Name,Id)=>{
        var r = window.confirm("Apakah anda yakin menghapus gambar "+Name+"?");
        if (r == false) {
            console.log("canceled")
        } else {
            console.log("oke");
            var requestBody = JSON.stringify({
                "UserID": cookies.CookieUserID,
                "ParamKey": cookies.CookieParamKey,
                "Method":"DELETE",
                "IDImgCategory":Id
            });
    
            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImageCategory';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
           
            console.log(""+Signature);
            setLoading(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                console.log(JSON.stringify(data));
                
    
                if(data.ErrorCode=="0"){
                    alert("Berhasil delete");
                    loadData();
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
                setLoading(false)
            })
            .catch((error) => {
               console.log("er "+error.message);
               setLoading(false)
               alert(AlertMessage.failedConnect)
            });
        }
    
    
    }

    const handleSort=(OrderBy)=>{
         setOrderBy(OrderBy);
        if(Order.toUpperCase()=="ASC"){
             setOrder("DESC");
        }else{
            setOrder("ASC");
        }
        // alert(OrderBy)
        // loadData();
    }
    
    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }


    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
                <div className="blog-post">
                    <p className="title"><FontAwesomeIcon icon={faImages}/> List Kategori Gambar</p>

                    <hr 
                        style={{
                            backgroundColor: 'black'
                        }} 
                    />

                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Input
                                label="Nama Kategori"
                                style={{width:300}}
                                onChange = {(event) => {setFieldKategori(event.target.value)}}
                            />
                        </Col>
                        <Col>
                            {/* <Dropdown label = "Flag Eksternal"
                                style={{width:300}}
                                onChange = {(event) => {setFieldFlagEksternal(event.target.value)}}>
                                    <option value= "1" selected={"1"==FieldFlagEksternal}>Ya</option>
                                    <option value= "0" selected={"0"==FieldFlagEksternal}>Tidak</option>
                            </Dropdown> */}
                        </Col>
                    </Row>
                    <Row xs={12} md={12} lg={8}>
                        <Col>
                            <Dropdown label = "Sorting"
                                style={{width:300}}
                                onChange = {(event) => {setOrderBy(event.target.value)}}>
                                    <option value= "CategoryDesc" selected={"CategoryDesc"==OrderBy}>Nama Kategori</option>
                                    {/* <option value= "FlagExternal" selected={"FlagExternal"==OrderBy}>Flag Eksternal</option> */}
                                    <option value= "InputDate" selected={"InputDate"==OrderBy}>Tgl Input</option>
                                    <option value= "UserID" selected={"UserID"==OrderBy}>User</option>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Dropdown label = "Sorting Type"
                                style={{width:300}}
                                onChange = {(event) => {setOrder(event.target.value)}}>
                                    <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                    <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                            </Dropdown>
                        </Col>
                        <Col></Col>
                    </Row>
                    <br/>
                    <div style={{display:'flex'}}>
                        <Button style={{width:120,backgroundColor:"#7F8C8D",fontSize:13}} onClick={()=>loadData()}><FontAwesomeIcon icon={faFilter}/> Filter Data</Button>
                        <Gap width={10} />
                        <Button style={{width:120,fontSize:13}} onClick={()=>history.push('/input-kategori')}><FontAwesomeIcon icon={faPlusCircle}/> Tambah Baru</Button>
                    </div>

                    <hr style={{backgroundColor: 'black'}} />

                    {!loading ? (
                        <div>
                        <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:13}}>
                        <Thead>
                        <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                            <Th className="tabelHeader" onClick={()=>handleSort("CategoryDesc")}><LabelTH>Nama Kategori </LabelTH></Th>
                            {/* <Th className="tabelHeader" onClick={()=>handleSort("FlagExternal")}><LabelTH>Flag External</LabelTH></Th> */}
                            <Th className="tabelHeader" onClick={()=>handleSort("UserID")}><LabelTH>User </LabelTH></Th>
                            <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                            <Th width="30">Action</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        { ListKategori.length > 0 ? ListKategori.map((item,index)=>{
                                return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}}>
                                    <Td>{item.CategoryDesc}</Td>
                                    {/* <Td>{item.FlagExternal}</Td> */}
                                    <Td>{item.UserID}</Td>
                                    <Td>{item.InputDate}</Td>
                                    <Td>
                                        <div style={{display:'flex'}}>
                                        <Button style={{backgroundColor:"#ca9d1f",width:80}} 
                                                onClick={()=>{
                                                    history.push('/edit-kategori-gambar')
                                                    // history.push('/edit-paket',{
                                                    //     ProductName:item.ProductName,
                                                    //     SourceCode:item.SourceCode,
                                                    //     URL_API:item.URL_API,
                                                    //     ExpiredDay:item.ExpiredDay,
                                                    //     UserID:location.state.UserID,
                                                    //     ParamKey:location.state.ParamKey
                                                    // })
                                                    dispatch(setForm("DetailKategori",item))

                                                }}
                                            ><FontAwesomeIcon icon={faEdit}/> Edit</Button>
                                            <Gap width={10} />
                                            <Button style={{backgroundColor:"#e05858",width:80}} 
                                                onClick={()=>clickDelete(item.CategoryDesc,item.IDImgCategory)}
                                            ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
                                        </div>
                                    </Td>
                                </Tr>;
                           }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                        
                      
                        </Tbody>
                    </Table>
                    <Gap height={5}/>
                    {TotalRecord > 0 &&
                    <div style={{display:'flex'}}>
                        <span>Page : &nbsp;</span>
                        <Pagination size="sm">{items}</Pagination>
                    </div>
                    }
                    <div>
                        Total Data : {TotalRecord}
                    </div>
                    </div>
                    ) : (
                    <div class="divLoader">
                        <ReactBootStrap.Spinner animation="border" />
                    </div>)}

                </div>
            </div>

        </div>
    )
}

export default KategoriGambar

