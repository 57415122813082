import md5 from 'md5';
import React, { Component, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Gap, Input,Dropdown} from '../../../components'
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditUserLogin = () => {
    const history = useHistory();
    const [UserLogin,setUserLogin] = useState("")
    const [UserLoginOld,setUserLoginOld] = useState("")
    const [NamaSourceOld,setNamaSourceOld] = useState("")
    const [NamaSource,setNamaSource] = useState("")
    const [Password,setPassword] = useState("")
    const [OldPassword,setOldPassword] = useState("")
    const [FlagBU,setFlagBU] = useState("")
    const [NamaPaket,setNamaPaket] =useState("")
    const [UrlApiBu,setUrlApiBu] = useState("")
    const [UrlApiBu2,setUrlApiBu2] = useState("")
    const [LamaExpired,setLamaExpired] = useState(0)
    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    const [StatusAktif,setStatusAktif] = useState("")
    const [ListSource,setListSource] = useState("")
    const [IsLoading,setIsLoading] = useState(false)
    const [IsLoading2,setIsLoading2] = useState(false)
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
            cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else{
            setUserID(cookies.CookieUserID)
            setParamKey(cookies.CookieParamKey)
            setUserLogin(form.Detail.Login)
            setUserLoginOld(form.Detail.Login)
            setNamaSourceOld(form.Detail.SourceCode)
            setNamaSource(form.Detail.SourceCode)
            setStatusAktif(form.Detail.IsActive)
            setFlagBU(form.Detail.FlagBU)
        }
    },[])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='/login';
      }

    const simpanData = () => {
        //  alert("simpan data "+window.location.host);
        //  return false;

        let validasiMessage = "";
        if(NamaSource==""){
            validasiMessage = validasiMessage + "Silahkan isi nama source terlebih dahulu.\n";
        }else if(NamaSource.length > 100){
            validasiMessage = validasiMessage + "Nama source lebih dari 100 karakter.\n";
        }
        if(Password!="" && Password.length != 6){
            validasiMessage = validasiMessage + "Password harus 6 digit.\n";
        }
        if(StatusAktif==""){
            validasiMessage = validasiMessage + "Silahkan pilih status aktif terlebih dahulu.\n";
        }
        if(FlagBU==""){
            validasiMessage = validasiMessage + "Silahkan pilih flag BU terlebih dahulu.\n";
        }

        var pass = "-";
        if(Password!=""){
            pass = md5(Password);
        }
        var oldpass = "-";
        if(OldPassword!=""){
            oldpass = md5(OldPassword);
        }
        
        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{

            // {
            // 	"UserID": "1",
            // 	"ParamKey": "MTYxOTc3MjIwMzc2MTIyNA==wub9s5WfzNVQA0ZpBM8NpYvKs6bHJh",
            // 	"Method":"UPDATE",
            //  "IDLogin":"",
            //  "OldPassword":"-", // value - => data tidak di update
            // 	"Password":"-",
            // 	"SourceCode":"-",
            //  "FlagBU":"-",
            // 	"IsActive":"-"
            // }
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"UPDATE",
                "IDLogin":form.Detail.IDLogin,
                //"Login":UserLogin, // value - => data tidak di update
                "Password":pass,
                "OldPassword":oldpass,
                "SourceCode":NamaSource,
                "FlagBU":FlagBU,
                "IsActive":StatusAktif
            });

            

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addLogin';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil update data");
                    history.push('/list-user-login')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else if(data.ErrorMessage.trim().toLowerCase()=="error - update data"){
                        alert("Pastikan anda memasukan password lama yang benar.")
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
            <div className="blog-post">
                {/* <Link title="Kembali" onClick={()=>history.push('/')}/> */}
                <p className="title"><a href="/list-user-login"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Edit User</p>

                <hr style={{backgroundColor: 'black'}} />
                
                <CardDeck>
                    <Card style={{border:'none'}}>
                        <label>Nama User</label>
                        <label>{UserLogin} </label>
                    </Card>
                    <Card style={{border:'none'}}>
                        <Input 
                            required
                            label="Nama Source" 
                            value={NamaSource} 
                            onChange={event=>setNamaSource(event.target.value)}
                        />
                    </Card>
                </CardDeck>
                <Gap height={20}/>
                <CardDeck>
                    <Card style={{border:'none'}}>
                        <Input 
                            // required
                            label="Password Baru" 
                            type="password"
                            value={Password} 
                            onChange={event=>setPassword(event.target.value)}
                        />
                    </Card>
                    <Card style={{border:'none'}}>
                        <Input 
                            // required
                            label="Password Lama" 
                            type="password"
                            value={OldPassword} 
                            onChange={event=>setOldPassword(event.target.value)}
                        />
                    </Card>
                </CardDeck>
                 <Gap height={20}/>
                <label>Flag BU</label><span style={{color:'red'}}> *</span>
                <div>
                    <input type="radio" value="1" name="flag_bu" 
                    checked={FlagBU=="1"}
                        onChange={event=>setFlagBU(event.target.value)}
                    /> Ya
                    &nbsp;
                    &nbsp;
                    <input type="radio" value="0" name="flag_bu" 
                        checked={FlagBU=="0"}
                        onChange={event=>setFlagBU(event.target.value)}
                    /> Tidak
                </div>
                 <Gap height={20}/>
                <label>Status Aktif</label><span style={{color:'red'}}> *</span>
                <div>
                    <input type="radio" value="1" name="status_aktif" 
                    checked={StatusAktif=="1"}
                        onChange={event=>setStatusAktif(event.target.value)}
                    /> Aktif
                    &nbsp;
                    &nbsp;
                    <input type="radio" value="0" name="status_aktif" 
                        checked={StatusAktif=="0"}
                        onChange={event=>setStatusAktif(event.target.value)}
                    /> Tidak Aktif
                </div>
                <Gap height={20}/>
                <div className="button-action">
                    <Button spinner={IsLoading2} onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                </div>
            </div>
            </div>
        </div>
        
    )
}


export default EditUserLogin
