import md5 from 'md5';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Gap, Header, Input, TextArea } from '../../../components';
import { AlertMessage, paths } from '../../../utils';
import { validEmail } from '../../../utils/functions';
// import './home.scss'
import { useCookies } from 'react-cookie';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import * as ReactBootStrap from 'react-bootstrap'
const DataDiri = () => {
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['user']);
    const [Param,setParam] = useState(cookies.CookieParamKeyVoucher)
    const [KodeVoucher,setKodeVoucher] = useState(cookies.CookieKodeVoucher)
    const [Nama,setNama] = useState("")
    const [JenisKelamin,setJenisKelamin] = useState("")
    const [NoKTP,setNoKTP] = useState("")
    const [Alamat,setAlamat] = useState("")
    const [Email,setEmail] = useState("")
    const [NoHP,setNoHP] = useState("")
    const [Loading,setLoading] = useState("")
    const [BlockLoading,setBlockLoading] = useState("")
   
    const simpanData =()=>{
        let nikBegin = (NoKTP).substring(0, 1);
        let nikEnd = NoKTP.substring(12, 16);

        let validasiMessage = "";
        if(Nama==""){
            validasiMessage = validasiMessage + "Silahkan isi nama terlebih dahulu.\n";
        }else if(Nama.length > 100){
            validasiMessage = validasiMessage + "Nama maksimal 100 karakter.\n";
        }
        if(JenisKelamin==""){
            validasiMessage = validasiMessage + "Silahkan isi jenis kelamin terlebih dahulu.\n";
        }
        if(NoKTP==""){
            validasiMessage = validasiMessage + "Silahkan isi nomor KTP terlebih dahulu.\n";
        }else if(NoKTP.length != 16){
            validasiMessage = validasiMessage + "NIK / No KTP harus 16 digit.\n";
        }else if(!/^[0-9]+$/.test(NoKTP)) {
            validasiMessage = validasiMessage + "NIK / No KTP harus angka.\n";
        }else if (nikBegin == "0"){
            validasiMessage = validasiMessage + "Digit awal NIK / No KTP tidak boleh 0.\n";
        }else if (nikEnd == "0000"){
            validasiMessage = validasiMessage + "Digit akhir NIK / No KTP tidak boleh 0000.\n";
        }
        if(Alamat==""){
            validasiMessage = validasiMessage + "Silahkan alamat terlebih dahulu.\n";
        }else if(Alamat.length > 100){
            validasiMessage = validasiMessage + "Alamat maksimal 100 karakter.\n";
        }
        if(Email==""){
            validasiMessage = validasiMessage + "Silahkan isi alamat email terlebih dahulu.\n";
        }else if(!validEmail(Email)){
            validasiMessage = validasiMessage + "Pastikan email anda valid.\n";
        }else if(Email.length > 100){
            validasiMessage = validasiMessage + "Email maksimal 100 karakter.\n";
        }
        if(NoHP==""){
            validasiMessage = validasiMessage + "Silahkan No HP terlebih dahulu.\n";
        }else if(NoHP.length < 10 || NoHP.length>13){
            validasiMessage = validasiMessage + "Pastikan nomor HP yg anda masukkan valid.\n";
        }else if(isNaN(NoHP)){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. Tidak mengandung huruf. ";
        }else if(NoHP.indexOf("+") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }else if(NoHP.indexOf("E") != -1){
            validasiMessage = validasiMessage + "Pastikan nomor hanphone yang anda masukkan valid. No handphone harus berupa angka. ";
        }
        

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
            var requestBody = JSON.stringify({
                "ParamKey": Param,
                "VoucherNo" : KodeVoucher,
                "Name" : Nama,
                "Gender" : JenisKelamin,
                "Identity" : "KTP",
                "IDNo" : NoKTP,
                "Address" : Alamat,
                "Email" : Email,
                "NoHandphone" : NoHP
            });
    
            console.log(requestBody);
            
            // var enckey = paths.EncKey;
            var enckey = paths.ENCKEY_VOUCHER_ACTIVATION;
            var url = paths.URL_API_EXTERNAL+'voucherActivation';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
    
            console.log(""+Signature);
            setLoading(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                setLoading(false)
                // var errmsg = data.errmsg;
                console.log("result : ",JSON.stringify(data));
                const date = new Date();
                    date.setDate(date.getDate() + 1);
                if(data.ErrorCode=="0"){
                    setCookie('CookieResult', 'Terima kasih. Aktivasi Voucher No.'+KodeVoucher+' telah berhasil.', { path: '/',expires : new Date(date) });
                    setCookie('CookieLinkPolis', data.ReturnData, { path: '/',expires : new Date(date) });
                    history.push('/aktivasi-selesai')
                }else{
                    if(data.ErrorMessage.trim().toLowerCase()=="error execute data"){
                        alert("Maaf aktivasi voucher No."+KodeVoucher+" gagal, Kode Voucher sudah pernah diaktifkan.")
                        
                    }else if(data.ErrorMessage.trim().toLowerCase()==("Error - Send data to BU").toLowerCase()){
                        alert(AlertMessage.failedConnect)
                    }else{
                        // setCookie('CookieResult', data.ErrorMessage, { path: '/',expires : new Date(date) });
                        alert(data.ErrorMessage);
                    }
                    
                }
            })
            .catch((error) => {
                setLoading(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect)
            });
        }
    }

    return (
        <div>
            <Header noNavbar/>
            
        <div className="main-page">
            <div className="content-wrapper-2">
            <div className="blog-post">
                <p className="title">Data Diri</p>

                <hr style={{backgroundColor: 'black'}} />
                
                {Loading ?
                <div className="divLoader">
                    <ReactBootStrap.Spinner animation="border" />
                </div>
                :
                <div>
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                required
                                label="Nama" 
                                value={Nama} 
                                onChange={event=>setNama(event.target.value)}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input
                                required
                                label="NIK / KTP" 
                                value={NoKTP} 
                                // maxLength={16}
                                type="number" 
                                onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                                onChange={event=>setNoKTP(event.target.value)}
                            />
                        </Card>
                        <Card style={{border:'none',width:400}}>
                        <label>Jenis Kelamin <span style={{color:'red'}}> *</span></label>
                        <div>
                            <input type="radio" value="L" name="jenis_kelamin" 
                                onChange={event=>setJenisKelamin(event.target.value)}
                            /> Pria
                            &nbsp;
                            &nbsp;
                            <input type="radio" value="P" name="jenis_kelamin" 
                                onChange={event=>setJenisKelamin(event.target.value)}
                            /> Wanita
                        </div>
                        </Card>
                    </CardDeck>

                    <Gap height={20}/>

                    <label>Alamat</label><span style={{color:'red'}}> *</span>
                    <TextArea
                        value={Alamat}
                        maxLength={255}
                        rows="3"
                        height="30px"
                        onChange={event=>setAlamat(event.target.value)}
                    />
                    
                    <CardDeck>
                        <Card style={{border:'none'}}>
                            <Input 
                                required
                                label="Email" 
                                value={Email} 
                                type="email"
                                onChange={event=>setEmail(event.target.value)}
                            />
                        </Card>
                        <Card style={{border:'none'}}>
                            <Input 
                                required
                                label="No HP" 
                                value={NoHP} 
                                type="number"
                                onChange={event=>setNoHP(event.target.value)}
                            />
                        </Card>
                    </CardDeck>
                    
                    <Gap height={20}/>
                </div>
                
                }
                <div className="button-action" style={{marginLeft:'0px'}}>
                    <Button style={{height:40}} spinner={Loading} title="Next" onClick={()=>simpanData()}/>
                </div>
                
            </div>
            </div>
        </div>
        </div>
    )
}

export default DataDiri;
