import md5 from 'md5';
import React, { Component, useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Gap, Input,Dropdown, TextArea} from '../../../components'
import LeftMenu from '../../../components/molecules/LeftMenu';
import { useSelector,useDispatch } from 'react-redux';
import { setForm } from '../../../redux';
import { AlertMessage, paths } from '../../../utils';
import { useCookies } from 'react-cookie';
import { Spinner } from 'react-bootstrap';
import { validURL, getBase64, historyConfig } from '../../../utils/functions';
import Card from 'react-bootstrap/Card';
import { CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LabelTH from '../../../components/molecules/LabelTH'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Popup from 'reactjs-popup';
import * as ReactBootStrap from 'react-bootstrap'
import { Tab } from 'bootstrap';
import { Markup } from 'interweave';
import { Editor } from '@tinymce/tinymce-react';
import Accordion from 'react-bootstrap/Accordion';
import Resizer from "react-image-file-resizer";

const InputPaket = () => {
    const history = useHistory();

    const [ListCategory,setListCategory] = useState([])

    const [loading,setLoading] = useState(false)
    const [loading2,setLoading2] = useState(false)
    const [loading3,setLoading3] = useState(false)
    const [loading4,setLoading4] = useState(false)

    const [NamaSource,setNamaSource] = useState("")
    const [NamaPaket,setNamaPaket] =useState("")
    // const [UrlApiBu,setUrlApiBu] = useState("")
    // const [UrlApiBu2,setUrlApiBu2] = useState("")
    const [Title,setTitle] = useState("")
    const [URL,setURL] = useState("")
    const [NamaTemplate,setNamaTemplate] = useState("")
    const [SlugInfoProduk,setSlugInfoProduk] = useState("")
    const [LamaExpired,setLamaExpired] = useState(0)
    const [UserID,setUserID] = useState("")
    const [ParamKey,setParamKey] = useState("")
    // const [StatusAktif,setStatusAktif] = useState("")
    const [IsLoading,setIsLoading] = useState(false)
    const [IsLoading2,setIsLoading2] = useState(false)
    // const location = useLocation();
    const {form}=useSelector(state=>state.LoginReducer);
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const dispatch = useDispatch();

    const [FieldNamaFile,setFieldNamaFile] = useState("")
    const [FieldFilterStatus,setFieldFilterStatus] = useState("")
    const [OrderBy,setOrderBy] = useState("InputDate")
    const [Order,setOrder] = useState("DESC")
    const [PageActive,setPageActive] = useState(1)
    const [TotalRecord,setTotalRecord] = useState(1)
    const [TotalPages,setTotalPages] = useState(1)
    const [RowPage,setRowPage] = useState(5)
    const [ResultMessage,setResultMessage] = useState("")
    const [ListGambar,setListGambar] = useState([])
    const [FileNameUnggah,setFileNameUnggah] = useState("")
    const [GambarUnggah,setGambarUnggah] = useState(null)
    const [Thumbnail,setThumbnail] = useState(null)

    const [LinkImageEmail,setLinkImageEmail] = useState("")
    const [LinkImageWeb,setLinkImageWeb] = useState("")

    const [IsiEmailContent,setIsiEmailContent] = useState("")
    const [IsiWebContent,setIsiWebContent] = useState("")
    const [IsiSMS,setIsiSMS] = useState("")
    const [CharLimit,setCharLimit] = useState(160)

    const [IDCategory,setIDCategory] = useState("")

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };

    const [WitdhDiv,setWitdhDiv] = useState(1300)

    const NAME = "{NAME}"
    const VOUCHERNO = "{VOUCHERNO}"
    const PARAM = "{PARAM}"
    const url = "{URL}"
    const EXPIRED = "{EXPIRED}"
    const LINKAKTIVASI = "{LINKAKTIVASI}"

    const [IsiEmailContentReplace, setIsiEmailContentReplace] = useState("")

    const WidthThumbnail = ""
    const HeightThumbnail = ""

    useEffect(()=>{
        if(cookies.CookieParamKey==null || cookies.CookieParamKey=="" ||
            cookies.CookieUserID==null || cookies.CookieUserID==""){
            alert("Session anda telah habis. Silahkan login kembali.");
            // history.push('/login');
            logout()
            return false;
        }else{
            setUserID(cookies.CookieUserID)
            setParamKey(cookies.CookieParamKey)
            // getListSourceCode()
            onLoadImageCategory();
            onLoadGambar();
            replaceEmail();
        }
    },[OrderBy,Order,PageActive])

    const logout = ()=>{
        removeCookie('CookieUserID', { path: '/' });
        removeCookie('CookieParamKey', { path: '/'});
        dispatch(setForm("ParamKey",''))
        dispatch(setForm("UserID",''))
        // history.push('/login')
        window.location.href='login';
      }

    // const getListSourceCode =()=>{
    //     var requestBody = JSON.stringify({
    //         "UserID": cookies.CookieUserID,
    //         "ParamKey": cookies.CookieParamKey,
    //         "Category":"SourceCode",
    //         "Page":1,
    //         "RowPage":15,
    //         "OrderBy":"",
    //         "Order":"DESC",
    //         "ProductName":"",
    //         "SourceCode":""
    //     });

        

    //     console.log(requestBody);
        
    //     var enckey = paths.EncKey;
    //     var url = paths.URL_API+'sourceCodeProduct';
    //     var requestBodyEnc = window.btoa(requestBody);
    //     var Signature = md5(enckey + requestBodyEnc);
    //     // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

    //     console.log(""+Signature);
    //     setIsLoading(true)
    //     fetch( url, {
    //         method: "POST",
    //         // mode: 'no-cors',
    //         body: requestBody,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Signature': Signature
    //         },
    //     })
    //     .then(response => response.json())
    //     .then((data) => {
    //         setIsLoading(false)
    //         // var errmsg = data.errmsg;
    //         console.log(JSON.stringify("res sourceCodeProduct "+data));
    //         if(data.ErrorCode=="0"){
    //             setListSource(data.Result)
    //         }else{
    //             alert(data.ErrorMessage);
    //             if(data.ErrorMessage=="Param Key Exipred"){
    //                 alert("Session anda telah habis. Silahkan login kembali.");
    //                 history.push('/login');
    //             }
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //        console.log("er "+error.message);
    //        alert(AlertMessage.failedConnect);
    //     });
    // }

    const replaceEmail = () => {

        var nama = IsiEmailContent.indexOf("{NAME}");
        console.log(nama)
        
        if ( nama == 1) {
            var EmailContentReplace = nama.replace("{NAME}", "Anton");
        };
        

        setIsiEmailContentReplace(EmailContentReplace)
    }

    const simpanData = () => {

        let validasiMessage = "";
        if(NamaSource==""){
            validasiMessage = validasiMessage + "Silahkan isi Nama Source terlebih dahulu.\n";
        }else if(NamaSource.length > 100){
            validasiMessage = validasiMessage + "Nama Source maksimal 100 karakter.\n";
        }
        if(NamaPaket==""){
            validasiMessage = validasiMessage + "Silahkan isi Nama Paket terlebih dahulu.\n";
        }else if(NamaPaket.length > 100){
            validasiMessage = validasiMessage + "Nama Paket maksimal 100 karakter.\n";
        }

        if(LamaExpired=="" || LamaExpired==0){
            validasiMessage = validasiMessage + "Silahkan isi Lama Expired terlebih dahulu.\n";
        }else if(isNaN(LamaExpired)){
            validasiMessage = validasiMessage + "Isi Lama Expired dengan angka.\n";
        }

        if (Title == "") {
            validasiMessage = validasiMessage + "Silahkan isi Title terlebih dahulu.\n";
        }

        var url = window.location.protocol+"//"+window.location.host+"/"+historyConfig.basename+"/"+SlugInfoProduk;

        if (IsiSMS == "") {
            validasiMessage = validasiMessage + "Silahkan isi SMS terlebih dahulu.\n";
        }

        // if (IsiSMS.indexOf("{URL}") == -1)
        // {
        //     validasiMessage = validasiMessage + "Template SMS belum ada {URL} silakan lihat panduan konten\n";
        // }
        if (IsiSMS.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {NAME} silakan lihat panduan konten\n";
        }
        // if (IsiSMS.indexOf("{PARAM}") == -1)
        // {
        //     validasiMessage = validasiMessage + "Template SMS belum ada {PARAM} silakan lihat panduan konten\n";
        // }
        if (IsiSMS.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }

        if (IsiSMS.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template SMS belum ada {LINKAKTIVASI} silakan lihat panduan konten\n";
        }

        if(IsiEmailContent == "") {
            validasiMessage = validasiMessage + "Silahkan isi Email terlebih dahulu.\n";
        }

        // if (IsiEmailContent.indexOf("{URL}") == -1)
        // {
        //     validasiMessage = validasiMessage + "Template Email belum ada {URL} silakan lihat panduan konten\n";
        // }
        if (IsiEmailContent.indexOf("{NAME}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {NAME} silakan lihat panduan konten\n";
        }
        // if (IsiEmailContent.indexOf("{PARAM}") == -1)
        // {
        //     validasiMessage = validasiMessage + "Template Email belum ada {PARAM} silakan lihat panduan konten\n";
        // }
        if (IsiEmailContent.indexOf("{VOUCHERNO}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {VOUCHERNO} silakan lihat panduan konten\n";
        }
        if (IsiEmailContent.indexOf("{LINKAKTIVASI}") == -1)
        {
            validasiMessage = validasiMessage + "Template Email belum ada {LINKAKTIVASI} silakan lihat panduan konten\n";
        }

        if (IsiWebContent == "") {
            validasiMessage = validasiMessage + "Silahkan isi Template Web terlebih dahulu.\n";
        }
        
        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{

            // alert("SUSKES");
            // return;
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "ProductName": NamaPaket,
                "SourceCode": NamaSource,
                "Title": Title,
                "URL": url,
                "TemplateWeb": IsiWebContent,
                "TemplateEmail": IsiEmailContent,
                "TemplateSMS": IsiSMS,
                "ExpiredDay": 30
                
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'product';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil insert data");
                    history.push('')
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const onLoadGambar = () => {

        if (FieldNamaFile != "") {
            var NamaFile = "%"+FieldNamaFile+"%";
        } else {
            var NamaFile = "";
        } 

        if (FieldFilterStatus != "") {
            var FilterStatus = FieldFilterStatus;
        } else {
            var FilterStatus = "1";
        }

        if (IDCategory != "") {
            var FilterKategori = IDCategory;
        } else {
            var FilterKategori = "";
        }
        
        var requestBody = JSON.stringify({
            // "UserID": cookies.CookieUserID,
            // "ParamKey": cookies.CookieParamKey,
            // "Method":"SELECT",
            // "Page": PageActive,
            // "RowPage": RowPage,
            // "OrderBy": OrderBy,
            // "Order": Order,
            // "Filename":NamaFile,
            // "DataBase64":"-",
            // "IDImage":"-",
            // "IsActive": FilterStatus

            "UserID": cookies.CookieUserID,
            "ParamKey": cookies.CookieParamKey,
            "Method":"SELECT",
            "Page":1,
            "RowPage":15,
            "OrderBy":"",
            "Order":"DESC",
            "Filename": NamaFile,
            "DataBase64":"",
            "IDImage":"",
            "IDImgCategory": FilterKategori,
            "IsActive": FilterStatus
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImage';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListGambar(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const onLoadImageCategory = () => {
        
        var requestBody = JSON.stringify({
            "UserID": cookies.CookieUserID,
        	"ParamKey": cookies.CookieParamKey,
        	"Method":"SELECT",
        	"Page":1,
        	"RowPage":15,
        	"OrderBy":"",
        	"Order":"DESC",
        	"CategoryDesc":"",
        	"FlagExternal":""
        });

        console.log(requestBody);
        
        var enckey = paths.EncKey;
        var url = paths.URL_API+'addImageCategory';
        var requestBodyEnc = window.btoa(requestBody);
        var Signature = md5(enckey + requestBodyEnc);
        // var Signature = "1b25f7c4052fa99e00f2f75beabec690";
    
        console.log(""+Signature);
        setResultMessage("Loading ...")
        fetch( url, {
            method: "POST",
            // mode: 'no-cors',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'Signature': Signature
            },
        })
        .then(response => response.json())
        .then((data) => {
            // var errmsg = data.errmsg;
            console.log(JSON.stringify(data));
            
    
            if(data.ErrorCode=="0"){
                setListCategory(data.Result);
                setTotalRecord(data.TotalRecords)
                setTotalPages(data.TotalPages)
                if(data.TotalRecords<1){
                    setResultMessage("Data tidak ditemukan")
                }
            }
            setLoading(false)
        })
        .catch((error) => {
           console.log("er "+error.message);
           setLoading(false)
           alert(AlertMessage.failedConnect)
           setResultMessage("")
        });
    }

    const unggahGambar = () => {
        //  alert("simpan data "+window.location.host);
        //  return false;

        let validasiMessage = "";
        if(GambarUnggah==null){
            validasiMessage = validasiMessage + "Silahkan upload gambar terlebih dahulu.\n";
        }
        if(FileNameUnggah==""){
            validasiMessage = validasiMessage + "Silahkan isi nama file terlebih dahulu.\n";
        }else if(FileNameUnggah.length > 100){
            validasiMessage = validasiMessage + "Nama file maksimal 100 karakter.\n";
        }

        if (IDCategory == "") {
            validasiMessage = validasiMessage + "Silahkan pilih Kategori terlebih dahulu.\n";
        }

        if (Thumbnail != null) {
            var thumbnail = Thumbnail.replace("data:image/png;base64,", "");
        } else {
            var thumbnail = "-";
        }

        if(validasiMessage!=""){
            alert(validasiMessage);
            return false;
        }else{
           
            var requestBody = JSON.stringify({
                "UserID": UserID,
                "ParamKey": ParamKey,
                "Method":"INSERT",
                "Filename":FileNameUnggah,
                "DataBase64":GambarUnggah,
                "DataBase64Small": thumbnail,
                "IDImgCategory": IDCategory
            });

            console.log(requestBody);
            
            var enckey = paths.EncKey;
            var url = paths.URL_API+'addImage';
            var requestBodyEnc = window.btoa(requestBody);
            var Signature = md5(enckey + requestBodyEnc);
            // var Signature = "1b25f7c4052fa99e00f2f75beabec690";

            console.log(""+Signature);
            setIsLoading2(true)
            fetch( url, {
                method: "POST",
                // mode: 'no-cors',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': Signature
                },
            })
            .then(response => response.json())
            .then((data) => {
                // var errmsg = data.errmsg;
                setIsLoading2(false)
                console.log(JSON.stringify(data));
                if(data.ErrorCode=="0"){
                    alert("Berhasil unggah gambar.");
                    onLoadGambar();
                    setGambarUnggah(null)
                    setFileNameUnggah("")
                }else{
                    if(data.ErrorMessage=="Param Key Exipred"){
                        alert("Session anda telah habis. Silahkan login kembali.");
                        // history.push('/login');
                        logout()
                    }else{
                        alert(data.ErrorMessage);
                    }
                }
            })
            .catch((error) => {
                setIsLoading2(false)
               console.log("er "+error.message);
               alert(AlertMessage.failedConnect);
            });
        }
    }

    const handleSlug = (value) =>{
        var slug = value.replace(/ /gi, "-");
        setSlugInfoProduk(slug.toLowerCase())
    }

    const handleSort=(OrderBy)=>{
        setOrderBy(OrderBy);
       if(Order.toUpperCase()=="ASC"){
            setOrder("DESC");
       }else{
           setOrder("ASC");
       }
    }

    let active = PageActive;
    let items = [];
    for (let number = 1; number <= TotalPages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}
            onClick={()=>{
                console.log("page number: "+number);
                setPageActive(number)
            }}>
        {number}
        </Pagination.Item>,
    );
    }

    const MobileVersion=()=>{
        setWitdhDiv(350);
    }

    const WebsiteVersion=()=>{
        setWitdhDiv(1300);
    }

    const MobileVersionTemplateWeb=()=>{
        setWitdhDiv(350);
    }

    const WebsiteVersionTemplateWeb=()=>{
        setWitdhDiv(1300);
    }

    const PilihImage=(Filename, val)=>{
        if (val == "email") {
            if(LinkImageEmail == ""){
                setIsiEmailContent(IsiEmailContent+'<img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
                // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            }else{
                setIsiEmailContent(IsiEmailContent+'<a href={LINKAKTIVASI}><img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
                // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            }
        } else if (val == "web") {
            if(LinkImageWeb == ""){
                setIsiWebContent(IsiWebContent+'<img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
                // setFileName('<img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240" data-index="2">')
            }else{
                setIsiWebContent(IsiWebContent+'<a href={LINKAKTIVASI}><img src="'+paths.UrlImages+Filename+'" style="width:100%;max-width:700px;min-width:300px" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
                // setFileName('<a href='+LinkImage+'><img src="'+paths.UrlImages+Filename+'" alt="" data-rotate="" data-proportion="true" data-align="none" data-file-name="'+Filename+'" data-file-size="0" data-origin="," origin-size="700,240"  data-index="2"></a>')
            }
        }
        
        // alert('Image telah dipilih, silakan tutup popup jika sudah selesai');
    }

    const [ModalTemlateEmail, setModalTemlateEmail] = useState(false);
    const closeModalTemlateEmail = () => setModalTemlateEmail(false);
    const [ModalTemlateWeb, setModalTemlateWeb] = useState(false);
    const closeModalTemlateWeb = () => setModalTemlateWeb(false);

    const contentStyle = { background: '#ffffff',borderRadius:15,padding:20, width:'100%'};
    const contentStyle2 = { background: '#ffffff',borderRadius:15,padding:20};
    const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
    const arrowStyle = { color: '#000' };

    function onFileResize(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            // console.log("img ",img)
            if(img.size <= 200000){ //200kb
                var filename = (img.name).replace(/ /gi, "-");
                // console.log("",(img.name).replace(/ /gi, "-"))
                getBase64(img, (result) => {
                    console.log("",result);
                    setFileNameUnggah(filename)
                    setGambarUnggah(result)
                });

                Resizer.imageFileResizer(
                    img, // the file from input
                    150, // width
                    150, // height
                    "PNG", // compress format WEBP, JPEG, PNG
                    70, // quality
                    0, // rotation
                    (uri) => {
                        // console.log("Thumb : "+uri);
                        setThumbnail(uri)
                        // var Height = Thumbnail.clientHeight;
                        // var width = Thumbnail.clientWidth;
                        console.log("LEBAR : " + WidthThumbnail);
                        console.log("TINGGI : " + HeightThumbnail);

                        // You upload logic goes here
                    },
                    "base64" // blob or base64 default base64
                );
            }else{
                setGambarUnggah(null)
                setFileNameUnggah("")
                alert("Maaf, Ukuran gambar terlalu besar. Maksimal 200 kb");
            }
        }
    }

    return (
        <div className="main-page">
           
            <div className="content-wrapper-2">
            <div className="blog-post">
                {/* <Link title="Kembali" onClick={()=>history.push('')}/> */}
                <p className="title"><a href="/"><FontAwesomeIcon icon={faArrowAltCircleLeft}/></a> Buat Produk Baru</p>

                <hr style={{backgroundColor: 'black'}} />
                
                <CardDeck>
                    <Card style={{border:'none'}}>
                        <Input 
                            required
                            label="Nama Source" 
                            value={NamaSource} 
                            onChange={event=>setNamaSource(event.target.value)}
                        />
                    </Card>
                    <Card style={{border:'none'}}>
                        <Input 
                            required
                            label="Nama Paket"
                            value={NamaPaket} 
                            onChange={event=>setNamaPaket(event.target.value)}
                        />
                    </Card>
                </CardDeck>
                <Gap height={20}/>
                <CardDeck>
                    <Card style={{border:'none'}}>
                        <Input 
                            required
                            label="Title"
                            value={Title} 
                            onChange={event=>{
                                setTitle(event.target.value)
                                handleSlug(event.target.value)
                            }}
                        />
                    </Card>
                    <Card style={{border:'none'}}>
                        <Input 
                            required
                            label="Lama Expired (hari)" 
                            type="number"
                            value={LamaExpired} 
                            onChange={event=>setLamaExpired(parseInt(event.target.value))}
                        />
                    </Card>
                </CardDeck>
                <CardDeck>
                    <Card style={{border:'none',paddingTop:30}}>
                        {SlugInfoProduk!="" &&  
                            <div>
                                <Gap height={5}/>
                                <label>URL: &nbsp;</label>
                                <label style={{color:'#a5a3a3',textDecoration:'underline',cursor: 'no-drop'}}>
                                {window.location.protocol+"//"+window.location.host+"/"+SlugInfoProduk}
                                </label>
                                <Gap height={5}/>
                            </div>
                        }
                    </Card>
                </CardDeck>

                <Gap height={20}/>

                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle eventKey="1" style={{backgroundColor:'#1B4F72',height:35,borderRadius:5,color:'#ffffff'}}>Panduan Mengisi Konten</Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>

                        <p>Dear, {NAME}</p>
                        Silahkan aktivasi,
                        <table>
                            <tr>
                                <td>Voucher No</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>{VOUCHERNO}</td>
                            </tr>
                            {/* <tr>
                                <td>Param</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>{PARAM}</td>
                            </tr> */}
                            <tr>
                                <td>Expired</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>{EXPIRED}</td>
                            </tr>
                            {/* <tr>
                                <td>URL</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>{url}</td>
                            </tr> */}
                            <tr>
                                <td>Link Aktivasi</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>{LINKAKTIVASI}</td>
                            </tr>
                        </table>
                        <br/>
                        {/* dengan klik tombol dibawah ini
                        
                        <div class='se-component se-image-container __se__float-none' contenteditable='false'>
                            <a href='https://sinarmas.co.id'>
                                <img src="http://182.23.21.61/api/v1/images"/>
                            </a>
                        </div> */}
                        
                        <p>Silahkan klik link dibawah ini untuk aktivasi</p>
                        
                        <a href="http://yourdomain.com/mylink.pl">YourDomain.com</a>

                        <br/>
                        <br/>
                        <p>Terima kasih</p>

                        <hr/>
                        
                        <table>
                            <tr>
                                <td>{NAME}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dengan Nama User</td>
                            </tr>
                            <tr>
                                <td>{VOUCHERNO}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dengan No Voucher</td>
                            </tr>
                            {/* <tr>
                                <td>{PARAM}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dengan Param Aktivasi</td>
                            </tr> */}
                            <tr>
                                <td>{EXPIRED}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dalam Expired Voucher (dalam hari)</td>
                            </tr>
                            {/* <tr>
                                <td>{url}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dengan URL</td>
                            </tr> */}
                            <tr>
                                <td>{LINKAKTIVASI}</td>
                                <td>&nbsp;</td>
                                <td>:</td>
                                <td>&nbsp;</td>
                                <td>Akan diisi otomatis dengan Link Aktivasi</td>
                            </tr>
                        </table>

                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Gap height={20}/>

                <label>Isi SMS</label>
                <Gap height={10}/>
                <TextArea
                    value={IsiSMS}
                    maxLength={160}
                    onChange={event=>setIsiSMS(event.target.value)}
                    // onChange={this.handleOnChange}
                />
                Sisa Karakter SMS : {CharLimit - IsiSMS.length}
                
                <Gap height={20}/>

                <label>Isi Email</label>
                    <Gap height={10}/>
                    <div className="editordiv">
                        <div style={{display:'flex'}}>
                                <button onClick={() => setModalTemlateEmail(o => !o)} style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Pilih Gambar </button>
                            <Popup
                                open={ModalTemlateEmail} closeOnDocumentClick onClose={closeModalTemlateEmail}
                                // trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Pilih Gambar </button>}
                                {...{ contentStyle, overlayStyle, arrowStyle}}
                                modal
                                nested
                                lockScroll = {true}
                                offsetY = {0}
                            >
                                {/* {close => ( */}
                                <div>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <b>Pilih Gambar Email</b>
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={closeModalTemlateEmail}>&times;</button>
                                    </div>
                                    <hr/>
                                    <div style={{overflow: 'auto', height:550}}>
                                    <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                        <Tab eventKey="home" title="Library">
                                            <Row xs={12} md={12} lg={8}>
                                                <Col>
                                                    <Input
                                                        label="Filter Nama File"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Dropdown 
                                                        spinner={loading2}
                                                        label="Kategori"
                                                        style={{width:300}}
                                                        onChange={(event)=>{
                                                            setIDCategory(event.target.value)
                                                        }}>
                                                        <option value="">-- Pilih Kategori --</option>
                                                        {ListCategory.length > 0 && ListCategory.map((item,index)=>{
                                                            return <option value={item.IDImgCategory} selected={item.CategoryDesc==IDCategory}>{item.CategoryDesc}</option>;
                                                        })}
                                                    </Dropdown>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <Row xs={12} md={12} lg={8}>
                                                <Col>
                                                    <Dropdown label = "Sorting"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                            <option value= "Filename" selected={"Filename"==OrderBy}>Nama File</option>
                                                            <option value= "InputDate" selected={"InputDate"==OrderBy}>Input Date</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col>
                                                    <Dropdown label = "Sorting Type"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrder(event.target.value)}}>
                                                            <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                            <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <br/>
                                            <div>
                                                <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                            </div>

                                            <Gap height={10}/>

                                            {!loading ? (
                                            <div> 
                                            <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                                <Thead>
                                                <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                    <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                    <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                                </Tr>
                                                </Thead>
                                                <Tbody>
                                                {ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                            <Td> 
                                                            <Popup
                                                                trigger={<img src={"data:image/png;base64,"+item.DataBase64Small} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}} />}
                                                                {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                modal
                                                                nested
                                                            >
                                                            {close => (
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                        <b>Tampilan Gambar</b>
                                                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                    </div>
                                                                    <hr/>
                                                                    <div style={{overflow: 'auto', height:550}}>
                                                                        <img src={paths.UrlImages+item.Filename} style={{width:'100%'}}/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            </Popup>
                                                            </Td>
                                                            <Td>{item.Filename}</Td>
                                                            <Td>{item.InputDate}</Td>
                                                            <Td style={{cursor:'pointer'}} align="left">
                                                                <div className="topping">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="topping"
                                                                        name="topping"
                                                                        value="Paneer"
                                                                        onChange = {(event) => {setLinkImageEmail()}}
                                                                        style={{paddingTop:5}}
                                                                        />
                                                                    <span style={{ marginLeft: 5, paddingTop:-6}}>Tambah Link Microsite</span>
                                                                </div>
                                                                <Gap height={15}/>
                                                                <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                    PilihImage(item.Filename, "email")
                                                                    // close();
                                                                    closeModalTemlateEmail();
                                                                }
                                                                }>Pilih</Button>
                                                            </Td>
                                                        </Tr>;
                                                }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                            
                                                </Tbody>
                                            </Table>
                                            <Gap height={10}/>
                                            {TotalRecord > 0 &&
                                            <div style={{display:'flex'}}>
                                                <span>Page : &nbsp;</span>
                                                <Pagination size="sm">{items}</Pagination>
                                            </div>
                                            }
                                            <Gap height={10}/>
                                            </div>
                                            ) : (
                                            <div class="divLoader">
                                                <ReactBootStrap.Spinner animation="border" />
                                            </div>)}
                                        </Tab>
                                        <Tab eventKey="unggah" title="Unggah">
                                            <Container>
                                                <Gap height={10}/>
                                                {Thumbnail != null &&
                                                    <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                        <label>Thumbnail :</label>
                                                        <Gap height={10}/>
                                                        <img src={Thumbnail} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}}/>
                                                    </div>
                                                    }

                                                {GambarUnggah != null &&
                                                    <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                        <label>Image :</label>
                                                        <Gap height={10}/>
                                                        <img src={"data:image/png;base64,"+GambarUnggah} style={{width:'100%'}}/>
                                                    </div>
                                                    }

                                                <Input 
                                                    label="Unggah Gambar"
                                                    type="file"
                                                    accept=".jpeg, .png, .jpg"
                                                    onChange={(e)=>onFileResize(e)}
                                                />
                                                <Gap height={20}/>
                                                <Dropdown 
                                                    spinner={loading2} 
                                                    required 
                                                    label="Kategori" 
                                                    onChange={(event)=>{
                                                        setIDCategory(event.target.value)
                                                    }}>
                                                    <option value="">-- Pilih Kategori --</option>
                                                    {ListCategory.length > 0 && ListCategory.map((item,index)=>{
                                                        return <option value={item.IDImgCategory} selected={item.CategoryDesc==IDCategory}>{item.CategoryDesc}</option>;
                                                    })}
                                                </Dropdown>
                                                <Gap height={20}/>
                                                <div className="button-action">
                                                    <Button spinner={IsLoading2} title="Save" onClick={()=>{
                                                        unggahGambar()
                                                        closeModalTemlateEmail();
                                                    }}/>
                                                </div>
                                            </Container>
                                        </Tab>
                                        </ReactBootStrap.Tabs>
                                    
                                    </div>
                                </div>
                                {/* // )} */}
                            </Popup>
                            <Gap width={10}/>
                            <Popup
                                trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Tampilkan Email </button>}
                                {...{ contentStyle, overlayStyle, arrowStyle }}
                                modal
                                nested
                            >
                                {close => (
                                <div>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <b>Tampilan Email</b>
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>
                                        &times;
                                        </button>
                                    </div>
                                    <Gap height={10}/>
                                    <div style={{display:'flex'}}>
                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>WebsiteVersion()}>Versi Website</button>
                                        <Gap width={10} />
                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>MobileVersion()}>Versi Mobile</button>
                                    </div>
                                    <hr/>
                                    <div style={{overflow: 'auto', height:550, width:WitdhDiv}}>
                                        <Markup content= {IsiEmailContent.replace("{NAME}", "Anton").replace('{URL}','http://localhost:3000/Asuransi-PA').replace('{PARAM}',"MTYyNDU1MTExMzY1NjM1Mw==zgnwsFYKb2KuNuvJzV0NebaLIZ037O").replace('{VOUCHERNO}',"1624551113656338").replace('{EXPIRED}','30')} />
                                    </div>
                                </div>
                                )}
                            </Popup>
                        </div>
                        <Editor
                            // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                            // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                            onInit={(evt, editor) => editorRef.current = editor}
                            // initialValue="<p>This is the initial content of the editor.</p>"
                            onSetContent={()=>{
                                console.log("onSetContent")
                            }}
                            onEditorChange={(newText)=>{
                                console.log("onEditor")
                                setIsiEmailContent(newText)
                            }}
                            onChange={()=>{
                                console.log("onChange")
                            }}
                            value={IsiEmailContent}
                            init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>

                    <Gap height={30}/>

                    <label>Template Web</label>
                    <Gap height={10}/>
                    <div className="editordiv">
                        <div style={{display:'flex'}}>
                           <button onClick={() => setModalTemlateWeb(o => !o)} style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Pilih Gambar </button>
                            <Popup
                                open={ModalTemlateWeb} closeOnDocumentClick onClose={closeModalTemlateWeb}
                                {...{ contentStyle, overlayStyle, arrowStyle}}
                                modal
                                nested
                                lockScroll = {true}
                                offsetY = {0}
                            >
                                {/* {close => ( */}
                                <div>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <b>Pilih Gambar Template</b>
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={closeModalTemlateWeb}>&times;</button>
                                    </div>
                                    <hr/>
                                    <div style={{overflow: 'auto', height:550}}>
                                    <ReactBootStrap.Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                        <Tab eventKey="home" title="Library">
                                            <Row xs={12} md={12} lg={8}>
                                                <Col>
                                                    <Input
                                                        label="Filter Nama File"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setFieldNamaFile(event.target.value)}}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Dropdown 
                                                        spinner={loading2}
                                                        label="Kategori" 
                                                        style={{width:300}}
                                                        onChange={(event)=>{
                                                            setIDCategory(event.target.value)
                                                        }}>
                                                        <option value="">-- Pilih Kategori --</option>
                                                        {ListCategory.length > 0 && ListCategory.map((item,index)=>{
                                                            return <option value={item.IDImgCategory} selected={item.CategoryDesc==IDCategory}>{item.CategoryDesc}</option>;
                                                        })}
                                                    </Dropdown>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <Row xs={12} md={12} lg={8}>
                                                <Col>
                                                    <Dropdown label = "Sorting"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrderBy(event.target.value)}}>
                                                            <option value= "Filename" selected={"Filename"==OrderBy}>Nama File</option>
                                                            <option value= "InputDate" selected={"InputDate"==OrderBy}>Input Date</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col>
                                                    <Dropdown label = "Sorting Type"
                                                        style={{width:300}}
                                                        onChange = {(event) => {setOrder(event.target.value)}}>
                                                            <option value= "ASC" selected={"ASC"==Order}>Ascending</option>
                                                            <option value= "DESC" selected={"DESC"==Order}>Descending</option>
                                                    </Dropdown>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <br/>
                                            <div>
                                                <Button style={{width: '100%', maxWidth:100, height:40, fontSize:11}} title="Filter" onClick={()=>onLoadGambar()}/>
                                            </div>

                                            <Gap height={20}/>

                                            {!loading ? (
                                            <div> 
                                            <Table striped bordered hover responsive cellspacing="0" border="1" cellpadding="7" style={{borderColor:"#CCCCCC",fontSize:11}}>
                                                <Thead>
                                                <Tr style={{color:"#3d3c3c",backgroundColor:"#CCCCCC"}}>
                                                    <Th className="tabelHeader" ><LabelTH>Gambar </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("Filename")}><LabelTH>Nama File </LabelTH></Th>
                                                    <Th className="tabelHeader" onClick={()=>handleSort("InputDate")}><LabelTH>Tgl Input </LabelTH></Th>
                                                    <Th className="tabelHeader"><LabelTH>Action</LabelTH></Th>
                                                </Tr>
                                                </Thead>
                                                <Tbody>
                                                {ListGambar.length > 0 ? ListGambar.map((item,index)=>{
                                                        return <Tr style={{backgroundColor:index%2==0?'#d1e4da':'white'}} >
                                                            <Td> 
                                                                <Popup
                                                                    trigger={<img src={"data:image/png;base64,"+item.DataBase64Small} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}} />}
                                                                    {...{ contentStyle, overlayStyle, arrowStyle }}
                                                                    modal
                                                                    nested
                                                                >
                                                                {close => (
                                                                    <div>
                                                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                            <b>Tampilan Gambar</b>
                                                                            <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>&times;</button>
                                                                        </div>
                                                                        <hr/>
                                                                        <div style={{overflow: 'auto', height:550}}>
                                                                            <img src={paths.UrlImages+item.Filename} style={{width:'100%'}}/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                </Popup>
                                                            </Td>
                                                            <Td>{item.Filename}</Td>
                                                            <Td>{item.InputDate}</Td>
                                                            <Td style={{cursor:'pointer'}} align="left">
                                                                <div className="topping">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="topping"
                                                                        name="topping"
                                                                        value="Paneer"
                                                                        onChange = {(event) => {setLinkImageWeb()}}
                                                                        style={{paddingTop:5}}
                                                                        />
                                                                    <span style={{ marginLeft: 5, paddingTop:-6}}>Tambah Link Microsite</span>
                                                                </div>
                                                                <Gap height={15}/>
                                                                <Button style={{width: '100%', maxWidth:60, height:40, fontSize:11}} onClick={()=>{

                                                                    PilihImage(item.Filename, "web")
                                                                    closeModalTemlateWeb();
                                                                }
                                                                }>Pilih</Button>
                                                            </Td>
                                                        </Tr>;
                                                }) : <Tr><Td colSpan="9" align="center" style={{color:'red'}}>{ResultMessage}</Td></Tr>}
                                            
                                                </Tbody>
                                            </Table>
                                            <Gap height={10}/>
                                            {TotalRecord > 0 &&
                                            <div style={{display:'flex'}}>
                                                <span>Page : &nbsp;</span>
                                                <Pagination size="sm">{items}</Pagination>
                                            </div>
                                            }
                                            <Gap height={10}/>
                                            </div>
                                            ) : (
                                            <div class="divLoader">
                                                <ReactBootStrap.Spinner animation="border" />
                                            </div>)}
                                        </Tab>
                                        <Tab eventKey="unggah" title="Unggah">
                                            <Container>
                                                <Gap height={10}/>
                                                {Thumbnail != null &&
                                                    <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                        <label>Thumbnail :</label>
                                                        <Gap height={10}/>
                                                        <img src={Thumbnail} style={{width:'100%', maxWidth:200, padding:10, cursor:'pointer'}}/>
                                                    </div>
                                                    }

                                                {GambarUnggah != null &&
                                                <div style={{border:1,padding:10,backgroundColor:'#bdb8b62e'}}>
                                                    <label>Image :</label>
                                                    <Gap height={10}/>
                                                    <img src={"data:image/png;base64,"+GambarUnggah} style={{width:'100%'}}/>
                                                </div>
                                                }

                                                <Input 
                                                    label="Unggah Gambar"
                                                    type="file"
                                                    accept=".jpeg, .png, .jpg"
                                                    onChange={(e)=>{onFileResize(e)}}
                                                />
                                                <Gap height={20}/>
                                                <Dropdown 
                                                    spinner={loading2} 
                                                    required 
                                                    label="Kategori" 
                                                    onChange={(event)=>{
                                                        setIDCategory(event.target.value)
                                                    }}>
                                                    <option value="">-- Pilih Kategori --</option>
                                                    {ListCategory.length > 0 && ListCategory.map((item,index)=>{
                                                        return <option value={item.IDImgCategory} selected={item.CategoryDesc==IDCategory}>{item.CategoryDesc}</option>;
                                                    })}
                                                </Dropdown>
                                                <Gap height={20}/>
                                                <div className="button-action">
                                                    <Button spinner={IsLoading2} title="Save" onClick={()=>{
                                                        unggahGambar()
                                                        closeModalTemlateWeb();
                                                    }}/>
                                                </div>
                                            </Container>
                                        </Tab>
                                        </ReactBootStrap.Tabs>
                                    </div>
                                </div>
                                {/* )} */}
                            </Popup>
                            <Gap width={10}/>
                            <Popup
                                trigger={<button style={{backgroundColor:'#1B4F72',borderRadius:10,color:'#ffffff'}}> Tampilkan Template Web </button>}
                                {...{ contentStyle, overlayStyle, arrowStyle }}
                                modal
                                nested
                            >
                                {close => (
                                <div>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <b>Tampilan Template Web</b>
                                        <button style={{backgroundColor:'#999999',color:'#ffffff',borderRadius:5}} onClick={close}>
                                        &times;
                                        </button>
                                    </div>
                                    <Gap height={10}/>
                                    <div style={{display:'flex'}}>
                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>WebsiteVersionTemplateWeb()}>Versi Website</button>
                                        <Gap width={10} />
                                        <button style={{backgroundColor:'#1B4F72',borderRadius:5,color:'#ffffff'}} onClick={()=>MobileVersionTemplateWeb()}>Versi Mobile</button>
                                    </div>
                                    <hr/>
                                    <div style={{overflow: 'auto', height:550, width:WitdhDiv}}>
                                        <Markup content= {IsiWebContent.replace("{NAME}", "Anton").replace('{URL}','http://localhost:3000/Asuransi-PA').replace('{PARAM}',"MTYyNDU1MTExMzY1NjM1Mw==zgnwsFYKb2KuNuvJzV0NebaLIZ037O").replace('{VOUCHERNO}',"1624551113656338").replace('{EXPIRED}','30')} />
                                    </div>
                                </div>
                                )}
                            </Popup>
                        </div>
                        <Editor
                            // apiKey='oupuz8s7shycygeztz0u4yfw15vfm79qxe69wi8npc8ugn0m'
                            // tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
                            onInit={(evt, editor) => editorRef.current = editor}
                            // initialValue="<p>This is the initial content of the editor.</p>"
                            onSetContent={()=>{
                                console.log("onSetContent")
                            }}
                            onEditorChange={(newText)=>{
                                console.log("onEditor")
                                setIsiWebContent(newText)
                            }}
                            onChange={()=>{
                                console.log("onChange")
                            }}
                            value={IsiWebContent}
                            init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>

                <Gap height={20}/>
                <div className="button-action">
                    <Button 
                        spinner={IsLoading2} 
                        onClick={()=>simpanData()}><FontAwesomeIcon icon={faCheckCircle}/> SAVE</Button>
                </div>
            </div>
            </div>
        </div>
        
    )
}


export default InputPaket