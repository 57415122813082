import React from 'react'
import { Sort } from '../../atoms'

function LabelTH({children,onClick}) {
    return (
        <span 
        // style={{display:'flex'}}
        >
             {children}
        {/* <Sort/> */}
        </span>
    )
}

export default LabelTH
